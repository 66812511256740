import { Injectable, EventEmitter } from '@angular/core';
import { from, of } from 'rxjs';

declare const mqtt: any;

@Injectable({
  providedIn: 'root'
})
export class RtdataService {

  token = "byZIefdEgML1jqDTXz6JqdDql5E2llzkIoSMrUhvuQmD6NkSdhrtkVHuQ9mZlJ7T";

  connection: any = null;

  observables: Map<string, EventEmitter<any>> = new Map<string,EventEmitter<any>>();

  constructor() { 
   this.connection = mqtt.connect('wss://mqtt.flespi.io', {username: this.token});
   this.connection.on('message', (topic, message) => {
    const vs = topic.split('/')
    const v = vs[vs.length-1];
    this.observables.get(v).emit(JSON.parse(message.toString()));
    
   });
  }

  subscribeVehicle(vehicle: string){
    this.connection.subscribeAsync('flespi/message/gw/devices/'+vehicle);
    this.observables.set(vehicle, new EventEmitter<any>());
    return this.observables.get(vehicle);
  }

  
}
