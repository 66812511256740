
<mat-toolbar *ngIf="status.time">
    Status - <span>Last Update: {{status.time}}</span>
</mat-toolbar>
<table *ngIf="status.services">
    <tr><th>Componente</th><th>Versione</th><th>Istanze</th><th>Stato generale</th></tr>
    <tr *ngFor="let component of status.services">
        <th>{{component.name}}</th>
        <th>{{component.raw.UpdatedAt|date}}</th>
        <td>{{component.replicas}}/{{component.replicas}}</td>
        <td *ngIf="component.replicas >= 1 ">✅</td>
        <td *ngIf="component.replicas < 1">⚠️</td>
    </tr>
</table>