import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { EdwayService } from 'src/app/edway.service';
import { AddRoleComponent } from '../add-role/add-role.component';
import { AddWalletComponent } from '../add-wallet/add-wallet.component';
import { BlacklistDialogComponent } from '../blacklist-dialog/blacklist-dialog.component';
import * as echarts from 'echarts';
import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { AddPackageComponent } from '../add-package/add-package.component';
import { ImageboxComponent } from 'src/app/imagebox/imagebox.component';
import { TranslateService } from '@ngx-translate/core';

declare const L: any;
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  uid: string;
  map: any;
  user: any = {};
  user_status: any = {};

  graph_per_day: any;
  graph_per_hour: any;
  graph_per_type: any;

  option = {
    title: {
      text: '',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 1048, name: 'Search Engine' },
          { value: 735, name: 'Direct' },
          { value: 580, name: 'Email' },
          { value: 484, name: 'Union Ads' },
          { value: 300, name: 'Video Ads' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  constructor(
    private ar: ActivatedRoute,
    private e: EdwayService,
    private modal: MatDialog,
    private _snackBar: MatSnackBar,
    private tr: TranslateService,
    public a: AuthService,
    @Inject(DOCUMENT) document: Document
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    this.ar.params.subscribe((data) => {
      this.uid = data.id;
      this.load();
    });
  }

  graphoption(mode, data) {
    const opt = JSON.parse(JSON.stringify(this.option));
    data = data.map((x) => {
      x['start'] = new Date(x['start']);
      x['end'] = new Date(x['end']);
      x['dow'] = x['start'].getDay();
      x['hod'] = x['start'].getHours();
      return x;
    });

    if (mode === 'graph_per_day') {
      data = [
        {
          name: 'mon',
          value: data.reduce((a, c) => {
            if (c['dow'] == 1) a += 1;
            return a;
          }, 0),
        },
        {
          name: 'tue',
          value: data.reduce((a, c) => {
            if (c['dow'] == 2) a += 1;
            return a;
          }, 0),
        },
        {
          name: 'wed',
          value: data.reduce((a, c) => {
            if (c['dow'] == 3) a += 1;
            return a;
          }, 0),
        },
        {
          name: 'thu',
          value: data.reduce((a, c) => {
            if (c['dow'] == 4) a += 1;
            return a;
          }, 0),
        },
        {
          name: 'fri',
          value: data.reduce((a, c) => {
            if (c['dow'] == 5) a += 1;
            return a;
          }, 0),
        },
        {
          name: 'sat',
          value: data.reduce((a, c) => {
            if (c['dow'] == 6) a += 1;
            return a;
          }, 0),
        },
        {
          name: 'sun',
          value: data.reduce((a, c) => {
            if (c['dow'] == 0) a += 1;
            return a;
          }, 0),
        },
      ];
      opt.title.text = 'Noleggi/giorno';
      opt.series[0].data = data;
    } else if (mode === 'graph_per_hour') {
      data = [
        {
          name: '0-1',
          value: data.reduce((a, c) => {
            if (c['hod'] == 0) a += 1;
            return a;
          }, 0),
        },
        {
          name: '1-2',
          value: data.reduce((a, c) => {
            if (c['hod'] == 1) a += 1;
            return a;
          }, 0),
        },
        {
          name: '2-3',
          value: data.reduce((a, c) => {
            if (c['hod'] == 2) a += 1;
            return a;
          }, 0),
        },
        {
          name: '3-4',
          value: data.reduce((a, c) => {
            if (c['hod'] == 3) a += 1;
            return a;
          }, 0),
        },
        {
          name: '4-5',
          value: data.reduce((a, c) => {
            if (c['hod'] == 4) a += 1;
            return a;
          }, 0),
        },
        {
          name: '5-6',
          value: data.reduce((a, c) => {
            if (c['hod'] == 5) a += 1;
            return a;
          }, 0),
        },
        {
          name: '6-7',
          value: data.reduce((a, c) => {
            if (c['hod'] == 6) a += 1;
            return a;
          }, 0),
        },
        {
          name: '7-8',
          value: data.reduce((a, c) => {
            if (c['hod'] == 7) a += 1;
            return a;
          }, 0),
        },
        {
          name: '8-9',
          value: data.reduce((a, c) => {
            if (c['hod'] == 8) a += 1;
            return a;
          }, 0),
        },
        {
          name: '9-10',
          value: data.reduce((a, c) => {
            if (c['hod'] == 9) a += 1;
            return a;
          }, 0),
        },
        {
          name: '10-11',
          value: data.reduce((a, c) => {
            if (c['hod'] == 10) a += 1;
            return a;
          }, 0),
        },
        {
          name: '11-12',
          value: data.reduce((a, c) => {
            if (c['hod'] == 11) a += 1;
            return a;
          }, 0),
        },
        {
          name: '12-13',
          value: data.reduce((a, c) => {
            if (c['hod'] == 12) a += 1;
            return a;
          }, 0),
        },
        {
          name: '13-14',
          value: data.reduce((a, c) => {
            if (c['hod'] == 13) a += 1;
            return a;
          }, 0),
        },
        {
          name: '14-15',
          value: data.reduce((a, c) => {
            if (c['hod'] == 14) a += 1;
            return a;
          }, 0),
        },
        {
          name: '15-16',
          value: data.reduce((a, c) => {
            if (c['hod'] == 15) a += 1;
            return a;
          }, 0),
        },
        {
          name: '16-17',
          value: data.reduce((a, c) => {
            if (c['hod'] == 16) a += 1;
            return a;
          }, 0),
        },
        {
          name: '17-18',
          value: data.reduce((a, c) => {
            if (c['hod'] == 17) a += 1;
            return a;
          }, 0),
        },
        {
          name: '18-19',
          value: data.reduce((a, c) => {
            if (c['hod'] == 18) a += 1;
            return a;
          }, 0),
        },
        {
          name: '19-20',
          value: data.reduce((a, c) => {
            if (c['hod'] == 19) a += 1;
            return a;
          }, 0),
        },
        {
          name: '20-21',
          value: data.reduce((a, c) => {
            if (c['hod'] == 20) a += 1;
            return a;
          }, 0),
        },
        {
          name: '21-22',
          value: data.reduce((a, c) => {
            if (c['hod'] == 21) a += 1;
            return a;
          }, 0),
        },
        {
          name: '22-23',
          value: data.reduce((a, c) => {
            if (c['hod'] == 22) a += 1;
            return a;
          }, 0),
        },
        {
          name: '23-00',
          value: data.reduce((a, c) => {
            if (c['hod'] == 23) a += 1;
            return a;
          }, 0),
        },
      ];
      opt.title.text = 'Noleggi/ora';
      opt.xAxis = {
        type: 'category',
        data: data.map((x) => x.name),
      };
      opt.yAxis = { type: 'value' };
      data = {
        data: data.map((x) => x.value),
        type: 'bar',
      };

      opt.series = [data];
    }

    console.log(opt);

    return opt;
  }

  load() {
    this.e.rentalsFor(this.uid).subscribe((rdata) => {
      console.log('rentals_for', rdata);
      this.graph_per_day = echarts.init(
        document.getElementById('graph_per_day')
      );
      this.graph_per_day.setOption(this.graphoption('graph_per_day', rdata));
      this.graph_per_hour = echarts.init(
        document.getElementById('graph_per_hour')
      );
      this.graph_per_hour.setOption(this.graphoption('graph_per_hour', rdata));
    });
    this.e.getUsers(this.uid).subscribe((udata) => {
      this.user = udata;
      console.log(this.user);
      if (this.user.last_position && this.user.last_position[0]) {
        this.map = L.map('map').setView(
          [
            parseFloat(this.user.last_position[0]),
            parseFloat(this.user.last_position[1]),
          ],
          13
        );
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '© OpenStreetMap',
        }).addTo(this.map);
        var marker = L.marker([
          parseFloat(this.user.last_position[0]),
          parseFloat(this.user.last_position[1]),
        ]).addTo(this.map);
      }
    });

    this.e.getUserStatus(this.uid).subscribe((status_data) => {
      this.user_status = status_data;
    });
  }

  accept(u: any, doc: string) {
    this.e.acceptDoc(u.username, doc).subscribe((data) => {
      this._snackBar.open(`${doc} accettato`, '');
      this.e.getUsers(this.user.username).subscribe((udata) => {
        this.user = udata;
      });
    });
  }

  refuse(u: any, doc: string) {
    this.e.refuseDoc(u.username, doc).subscribe((data) => {
      this._snackBar.open(`${doc} rifiutato`, '');
      this.e.getUsers(this.user.username).subscribe((udata) => {
        this.user = udata;
      });
    });
  }

  addToWallet() {
    const wd = this.modal.open(AddWalletComponent, {
      width: '400px',
      data: { op: 'Add', user: this.user },
    });
    wd.afterClosed().subscribe(data=>{
      if (data) {
        console.log(data);
        this.e.putWallet(data).subscribe(wdata=>{
          this._snackBar.open('added', 'ok', {duration:500})
          this.load();
        })
      }
    });
  }

  addPackage(){
    const wd = this.modal.open(AddPackageComponent, {
      width: '400px',
      data: { op: 'Add', user: this.user },
    });
    wd.afterClosed().subscribe(data=>{
      if (data){
        console.log(data);
        this.e.assignPackage(data).subscribe(wdata=>{
          this._snackBar.open('Package assigned', 'ok', {duration:500})
          this.load();
        })
      }
    });
  }

  blacklist() {
    let ret = this.modal.open(BlacklistDialogComponent, {
      width: '400px',
      data: { user: this.user },
    });
    ret.afterClosed().subscribe((data) => {
      console.log(data);
      this.e
        .addToBlacklist(this.user.username, this.user.phone, data.reason)
        .subscribe((data) => {
          this.user.blocked=true;
          this._snackBar.open('User Blocked',null,{ duration: 500});
        });
    });
  }

  unblacklist(){
    this.e
    .removeFromBlacklist(this.user.username, this.user.phone)
    .subscribe((data) => {
      this._snackBar.open('User Unblocked', null,{ duration: 500});
      this.user.blocked = false;
    });
  }

  addOrg() {
    let ret = this.modal.open(AddRoleComponent, {
      width: '400px',
      data: { user: this.user },
    });
    ret.afterClosed().subscribe((data) => {
      console.log(data);
      this.e.addRole(this.user, data.reason).subscribe((data) => {
        this.load();
        this._snackBar.open('aggiunto ruolo', null, {duration:500});
      });
    });
  }

  delOrg(org) {}

  reset_pw() {
    if (confirm("Password reset per l'utente?")) {
      this.e.resetPassword(this.user).subscribe((data) => {
        this._snackBar.open('Password reset inviato', null, {duration:500});
      });
    }
  }

  open(url){
    this.modal.open(ImageboxComponent, {data:{img:url}});
  }

  setAtt(att, value, silent=false){
    this.e.setAttribute(this.user.username, att, value).subscribe(data=>{
      if (!silent){
        this._snackBar.open(this.tr.instant('user.attribute_set'), null, {duration:500});
        this.load();
      }
    });
  }
  revalidate(att){
    if(att === 'phone'){
      this.setAtt('phone_valid', false, true);
      this.e.validatePhone(this.user.username).subscribe(data=>{
        this._snackBar.open(this.tr.instant('user.otp_sent'), null, {duration:500});
        this.load();
      })
    }
  }

  editPhone(){
    if(confirm(this.tr.instant('user.confirm_edit_phone'))){
      const np = prompt(this.tr.instant('user.new_phone'));
      if(np){
        if(np == this.user.phone)
          this._snackBar.open(this.tr.instant('user.phone_unchanged'), null, {duration:500});
        else {
          this.setAtt('phone', np, true);
          this.setAtt('phone_valid', false, true);
          this.load();
        }
      }
    }
    
  }

  edit(field){
    if(confirm(this.tr.instant('user.confirm_edit_field'))){
      const np = prompt(this.tr.instant('user.new_value'));
      if(np){
        this.setAtt(field, np, true);
        this.load();
      }
    }
  }
}
