import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../shared.module';
import { NgJsonEditorModule } from '@maaxgr/ang-jsoneditor';
import { DetailComponent } from './detail/detail.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    NgJsonEditorModule,
  ]
})
export class TranslationsModule { }
