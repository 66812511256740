<mat-toolbar>
  <span translate>vehicles.detail.vehicle_info.title</span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="addInfo()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<table mat-table [dataSource]="vehicle_metas" class="table">
  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef translate>
      vehicles.detail.vehicle_info.info
    </th>
    <td mat-cell *matCellDef="let element">{{ element.label | translate }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef translate>
      vehicles.detail.vehicle_info.type
    </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.type === 'str'"
        ><i class="fa fa-fw fa-tag"></i
      ></span>
      <span *ngIf="element.type === 'document'"
        ><i class="fa fa-fw fa-file"></i
      ></span>
    </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef translate>
      vehicles.detail.vehicle_info.value
    </th>
    <td mat-cell *matCellDef="let element">
      <a
        target="_blank"
        *ngIf="element.type === 'document'"
        href="{{ element.latest_descriptor.attachment_url }}"
        >{{ element.latest_descriptor.value }}</a
      >
      <span
        *ngIf="element.type === 'str'"
        [ngStyle]="{
          'text-decoration': element.latest_descriptor.deleted_at
            ? 'line-through'
            : 'none'
        }"
        >{{ element.latest_descriptor.value }}</span
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="url">
    <th mat-header-cell *matHeaderCellDef>Url</th>
    <td mat-cell *matCellDef="let element">{{ element.url }}</td>
  </ng-container>

  <ng-container matColumnDef="valid">
    <th mat-header-cell *matHeaderCellDef translate>
      vehicles.detail.vehicle_info.valid
    </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.has_expiration === true">{{
        element.latest_descriptor.expiry_date | date
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef translate>
      vehicles.detail.vehicle_info.actions
    </th>
    <td mat-cell *matCellDef="let element">
      <button
        (click)="deleteInfo(element.latest_descriptor.id)"
        *ngIf="!element.latest_descriptor.deleted_at"
        mat-icon-button
      >
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
