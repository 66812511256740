import { Component, OnInit } from '@angular/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  languages: string[] = ["en", "it", "es", "gr", "ru", "de", "fr", "pt-br", "jp", "du", "cn"];

  constructor( ) { }

  ngOnInit(): void {

  }

}
