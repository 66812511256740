import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  form_data = {
    subject: this.data.subject,
    content: this.data.content,
  };

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<DetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  save() {
    const post_data = {
      ...this.form_data,
      created: this.data.created,
      template: '',
      content_html: this.form_data.content,
      meta: {},
    };

    if (!this.form_data.subject || !this.form_data.content) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.updateNews(this.data.id, post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
