<div style="padding:10px;">
    <h2 translate>buttons.global</h2>
<mat-grid-list *ngIf="d" cols="5" gutterSize="20">
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['vehicles']">
        <h1>{{d.global?.vehicles}}</h1>
        <mat-grid-tile-footer translate>dashboard.vehicles</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['fleets']">
        <h1>{{d.global?.fleets}}</h1>
        <mat-grid-tile-footer translate>dashboard.fleets</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['users']">
        <h1>{{d.global?.users}}</h1>
        <mat-grid-tile-footer translate>dashboard.users</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['rentals']">
        <h1>{{d.global?.rentals}}</h1>
        <mat-grid-tile-footer translate>dashboard.rentals</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['payments']">
        <h1>{{d.global?.payments}}</h1>
        <mat-grid-tile-footer translate>dashboard.transactions</mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>
<h2 translate>buttons.active</h2>
<mat-grid-list cols="5" gutterSize="20">
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['vehicles']">
        <h1>{{d.today?.vehicles}}</h1>
        <mat-grid-tile-footer translate>dashboard.vehicles</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['fleets']">
        <h1>{{d.today?.fleets}}</h1>
        <mat-grid-tile-footer translate>dashboard.fleets</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['users']">
        <h1>{{d.today?.users}}</h1>
        <mat-grid-tile-footer translate>dashboard.users</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['rentals']">
        <h1>{{d.today?.rentals}}</h1>
        <mat-grid-tile-footer translate>dashboard.rentals</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['payments']">
        <h1>{{d.today?.payments}}</h1>
        <mat-grid-tile-footer translate>dashboard.transactions</mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>
<h2 translate>buttons.today</h2>
<mat-grid-list cols="5" gutterSize="20">
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['vehicles']">
        <h1>{{d.today?.vehicles}}</h1>
        <mat-grid-tile-footer translate>dashboard.vehicles</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['fleets']">
        <h1>{{d.today?.fleets}}</h1>
        <mat-grid-tile-footer translate>dashboard.fleets</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['users']">
        <h1>{{d.today?.users}}</h1>
        <mat-grid-tile-footer translate>dashboard.users</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['rentals']">
        <h1>{{d.today?.rentals}}</h1>
        <mat-grid-tile-footer translate>dashboard.rentals</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['payments']">
        <h1>{{d.today?.payments}}</h1>
        <mat-grid-tile-footer translate>dashboard.transactions</mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>
<!--<h2>Current</h2>
<mat-grid-list cols="5" gutterSize="20">
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['vehicles']">
        <h1>{{d.current.vehicles}}</h1>
        <mat-grid-tile-footer>Veicoli</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['fleets']">
        <h1>{{d.current.fleets}}</h1>
        <mat-grid-tile-footer>Segnalazioni</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['users']">
        <h1>{{d.current.users}}</h1>
        <mat-grid-tile-footer>Utenti</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['rentals']">
        <h1>{{d.current.rentals}}</h1>
        <mat-grid-tile-footer>Noleggi</mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile class="mat-elevation-z4" [routerLink]="['payments']">
        <h1>{{d.current.payments}}</h1>
        <mat-grid-tile-footer>Pagamenti</mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>-->
</div>