import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EdwayService } from 'src/app/edway.service';
import { IanAddComponent } from '../ian-add/ian-add.component';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { AmendComponent } from 'src/app/payments/amend/amend.component';
import { IanEditComponent } from '../ian-edit/ian-edit.component';

@Component({
  selector: 'app-ian-list',
  templateUrl: './ian-list.component.html',
  styleUrls: ['./ian-list.component.scss'],
})
export class IanListComponent implements OnInit {
  @Input() user;

  public defaultColDef: ColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    minWidth: 20,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [
    { field: 'title', headerName: this.tr.instant('ian.title') },
    { field: 'content', headerName: this.tr.instant('ian.content') },
    { field: 'created', headerName: this.tr.instant('ian.created') },
    {
      field: 'seen',
      headerName: this.tr.instant('ian.seen'),
      valueFormatter: boolFormatter,
    },
    { field: 'deleted', headerName: this.tr.instant('ian.deleted') },

    {
      field: 'actions',
      headerName: this.tr.instant('ops.actions'),
      width: 50,
      cellRenderer: AmendComponent,
      cellRendererParams: {
        buttons: [
          {
            icon: 'edit',
            condition: (params: any) => {
              return true;
            },
            clicked: (params: any) => {
              this.d.open(IanEditComponent, { data: params });
            },
            tooltip: 'Edit',
          },
          {
            icon: 'delete',
            condition: (params: any) => {
              return true;
            },
            clicked: (params: any) => {
              this.deleteHandler(params);
              return;
            },
            tooltip: 'Delete',
          },
        ],
      },
    },
  ];

  list: any = {};
  show = false;

  constructor(
    private e: EdwayService,
    private r: Router,
    private d: MatDialog,
    private tr: TranslateService
  ) {}

  ngOnInit(): void {
    this.toPage();
  }

  toPage(page: number = 1) {
    this.e.getIan(this.user).subscribe((data) => {
      this.list = data;
      this.show = true;
    });
  }

  addIan() {
    this.d.open(IanAddComponent, {
      data: { mode: 'direct', target: this.user },
    });
  }

  deleteHandler(item) {
    const { id, title } = item;
    if (
      confirm(this.tr.instant('ops.confirm_deletion', { itm: `"${title}"` }))
    ) {
      this.e.deleteIan(id).subscribe((data) => {
        this.toPage();
      });
    }
  }
}

function boolFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return '✅';
  } else {
    return '';
  }
}
