import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
})
export class DeliveryComponent implements OnInit {
  form_data = {
    target_type: '',
    target_fleet: '',
    target_user: '',
    target: '',
    distribution_modes: [],
    deadline: '',
  };

  fleets: unknown;
  distribution_modes: unknown;

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<DeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.fleets = this.e.getFleets();
    this.distribution_modes = this.e.getDistributionModes();
  }

  save() {
    const post_data = {
      ...this.form_data,
      news_id: this.data.news.id,
    };

    if (post_data.deadline !== '') {
      post_data.deadline = new Date(post_data.deadline).toISOString();
    }

    if (post_data.target_type === 'user') {
      post_data.target = post_data.target_user;
    }
    if (post_data.target_type === 'fleet') {
      post_data.target = post_data.target_fleet;
    }

    if (!this.form_data.target_type || !this.form_data.distribution_modes) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.addDistribution(post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
