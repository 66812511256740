import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-access-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss']
})
export class AddDialogComponent implements OnInit {
  public name: string;
  public value: string;
  constructor(
    public dialogRef: MatDialogRef<AddDialogComponent>
  ) { }

  ngOnInit(): void {

  }

  onNoClick(): void{
    this.dialogRef.close();
  }

  submit(): void{
    this.dialogRef.close({name: this.name, value: this.value});
  }

}
