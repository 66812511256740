import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PackageEditComponent } from '../package-edit/package-edit.component';
import { WalletEditComponent } from '../wallet-edit/wallet-edit.component';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit {
  refreshPackageList: boolean = false;

  constructor(private d: MatDialog) {}

  ngOnInit(): void {}

  openWallet(mode) {
    this.d.open(WalletEditComponent, { data: { mode: mode } });
  }

  openPacket(mode) {
    let edit = this.d.open(PackageEditComponent, { data: { mode: mode } });

    edit.afterClosed().subscribe((data) => {
      if (data) {
        console.log(data);
        this.refreshPackageList = true;
      }
    });
  }
}
