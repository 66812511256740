import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-platform-status',
  templateUrl: './platform-status.component.html',
  styleUrls: ['./platform-status.component.scss']
})
export class PlatformStatusComponent implements OnInit {

status = {
  "time": null,
  "services":[]  
}

  constructor(
    private ht: HttpClient
  ) { }

  ngOnInit(): void {
    this.ht.get('https://files.hoponmobility.com/edway/00000000-0000-0000-0000-000000000000/status.json').subscribe((data:{time, services})=>{
      this.status=data;
      this.status.time = new Date(this.status.time).toISOString();
      
    })
  }

}
