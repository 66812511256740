<mat-toolbar>
  <h1>Itineraries</h1>
  <span class="spacer"></span>
  <button mat-icon-button (click)="toggleUsers()">
    <mat-icon
      [ngStyle]="{
        show_users: { color: 'green' },
        '!show_users': { color: 'red' }
      }"
      >person</mat-icon
    >
  </button>
</mat-toolbar>

<ng-container *ngFor="let i of itineraries_list">
  <div
    class="col list-item"
    *ngIf="(show_users && i.user !== '') || (!show_users && i.user === '')"
  >
    <span [value]="i.id">
      {{ i.name }}
      <span class="list-item-tag tag-{{ t.family }}" *ngFor="let t of i.tags">
        {{ t.name }}
      </span>
    </span>

    <div class="row">
      <button (click)="go(i)" mat-icon-button>
        <mat-icon>visibility</mat-icon>
      </button>
      <button (click)="edit(i)" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <button (click)="navigate(i)" mat-icon-button>
        <mat-icon>assistant_dicrection</mat-icon>
      </button>
      <span class="spacer"></span>
      <button (click)="delete(i)" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
