import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-package-edit',
  templateUrl: './package-edit.component.html',
  styleUrls: ['./package-edit.component.scss'],
})
export class PackageEditComponent implements OnInit {
  data: any = {};

  fleets: Observable<any>;
  models: Observable<any>;

  constructor(
    private readonly edwayService: EdwayService,
    public dialogRef: MatDialogRef<PackageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) {}

  ngOnInit(): void {
    if (this.idata) {
      this.data = this.idata;
    }
    this.fleets = this.edwayService.getFleets();
    this.models = this.edwayService.getModels();
  }

  save(): void {
    console.log('Saving Package...', this.data);

    if (
      !this.data.name ||
      !this.data.duration ||
      !this.data.duration_unit ||
      !this.data.timeframe ||
      !this.data.timeframe_unit ||
      !this.data.price
    ) {
      alert('Please fill all the required fields');
      return;
    }

    this.edwayService
      .saveProduct(this.data)
      .subscribe((data: { id: string }) => {
        if (data.id) {
          this.dialogRef.close(data);
        }
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
