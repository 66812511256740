import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { EdwayService } from '../../edway.service';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { AddComponent } from '../add/add.component';
import { AddRoleComponent } from 'src/app/users/add-role/add-role.component';
import { AmendComponent } from 'src/app/payments/amend/amend.component';
import { DeliveryComponent } from '../delivery/delivery.component';
import { DetailComponent } from '../detail/detail.component';
import { TranslateService } from '@ngx-translate/core';
import { DistributionsListComponent } from '../distributions-list/distributions-list.component';
import { DownloadService } from 'src/app/download.service';
import { DatePipe } from '@angular/common';
import { DateUtilService } from 'src/app/services/date-util.service';

@Component({
  selector: 'edway-news-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Input() user;

  public defaultColDef: ColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    minWidth: 20,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];
  pageEvent: PageEvent;

  roles;

  list: any = {};

  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private d: MatDialog,
    private tr: TranslateService,
    private downloadService: DownloadService,
    private dateUtil: DateUtilService
  ) {}
  // MatPaginator Output

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.columnDefs = [
      { field: 'subject', headerName: this.tr.instant('notifications.col.subject.label') }, 
      { field: 'created', headerName: this.tr.instant('notifications.col.created.label'), valueFormatter: (v) => this.dateUtil.dateTableFormatter(v, 'dd/MM/yyyy HH:mm') }
    ]
    if (this.user) {
      this.columnDefs.push({ field: 'delivered' , headerName: this.tr.instant('notifications.col.delivered.label') });
      this.columnDefs.push({ field: 'mode' , headerName: this.tr.instant('notifications.col.mode.label') });
    } else {
      this.columnDefs.push({
        field: 'target',
        headerName: this.tr.instant('notifications.col.target.label'),
        width: 50,
        cellRenderer: AmendComponent,
        cellRendererParams: {
          buttons: [
            {
              icon: 'send',
              condition: (params: any) => {
                return true;
              },
              clicked: (params: any) => {
                this.d.open(DeliveryComponent, { data: { news: params } });
              },
              tooltip: 'Set delivery',
            },
          ],
        },
      });

      this.columnDefs.push({
        field: 'distributions',
        cellRenderer: DistributionsListComponent,
        headerName: this.tr.instant('notifications.col.distributions.label')
      });

      this.columnDefs.push({
        field: 'actions',
        headerName: this.tr.instant('ops.actions'),
        width: 50,
        cellRenderer: AmendComponent,
        cellRendererParams: {
          buttons: [
            {
              icon: 'edit',
              condition: (params: any) => {
                return true;
              },
              clicked: (params: any) => {
                this.d.open(DetailComponent, { data: params });
              },
              tooltip: 'Edit',
            },
            {
              icon: 'delete',
              condition: (params: any) => {
                return true;
              },
              clicked: (params: any) => {
                this.deleteHandler(params);
                return;
              },
              tooltip: 'Delete',
            },
          ],
        },
      });
    }

    this.toPage();
  }


  onRowClicked(ev) {
    // console.log(ev);
    // this.r.navigate(['news', ev.data.id]);
  }

  toPage(page: number = 1) {
    this.e.getNews().subscribe((data) => {
      this.list = data;
      this.show = true;
    });
  }

  addNews() {
    this.d.open(AddComponent, { data: { mode: 'direct', target: this.user } });
  }

  deleteHandler(item) {
    const { id, subject } = item;
    if (
      confirm(this.tr.instant('ops.confirm_deletion', { itm: `"${subject}"` }))
    ) {
      this.e.deleteNews(id).subscribe((data) => {
        window.location.reload();
      });
    }
  }

  download(url) {
    this.downloadService
      .download(url, {
        responseType: 'blob',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .subscribe((blob: any) => {
        saveAs(blob, 'rentals.xlsx');
      });
  }
}
