import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from 'src/app/settings.service';
import { Coupon, CouponModel, CreateCoupon } from '../../models';
import { EdwayService } from 'src/app/edway.service';

@Component({
    selector: 'app-access-dialog',
    templateUrl: './activate-dialog.component.html',
    styleUrls: ['./activate-dialog.component.scss']
})
export class ActivateDialogComponent implements OnInit {
    selected: CouponModel;
    output: CreateCoupon;
    prefix = "";
    constructor(
        public dialogRef: MatDialogRef<ActivateDialogComponent>,
        private settings: SettingsService,
        private e: EdwayService,
        @Inject(MAT_DIALOG_DATA) public data: { models: CouponModel[] }
    ) {
        this.prefix = settings.get('name')?.toLowerCase().replace(' ', '')||'edway';
        this.output = new CreateCoupon();
        this.output.code = this.newCode();
     }

    newCode():string {
        return this.prefix + Math.floor(Math.random() * 10000000);
    }

    addCode(){
        this.output.code +="|"+this.newCode();
    }

    ngOnInit(): void {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    submit(): void {
        if(this.output.expirationDate)
            this.output.expirationDate = (<Date>this.output.expirationDate).toISOString();
        this.output.couponModelId = this.selected.id;
        this.dialogRef.close(this.output);
    }

    getValues(param) {
        return this.e.getKVs(param);
    }
}
