<div class="dialog">
  <h1 mat-dialog-title>
    {{ "ops.add" | translate }} {{ "dashboard.ian" | translate }}
  </h1>
  <mat-dialog-content>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ "ian.title" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="form_data.title" />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ "ian.content" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="form_data.content" rows="10"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ "ops.cancel" | translate }}</button>
    <button mat-button (click)="save()">OK</button>
  </mat-dialog-actions>
</div>
