import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add-minutes',
  templateUrl: './add-minutes.component.html',
  styleUrls: ['./add-minutes.component.scss'],
})
export class AddMinutesComponent implements OnInit {
  pricing;
  data = {
    starts_at: null,
    usage_per_minute: null,
    pause_per_minute: null,
  };
  mode;

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddMinutesComponent>,
    @Inject(MAT_DIALOG_DATA) public conf: any,
    private s: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.mode = this.conf.mode ? this.conf.mode : 'add';
    this.pricing = this.conf.pricing;

    if (this.mode === 'edit') {
      this.data = {
        starts_at: this.conf.starts_at,
        usage_per_minute: this.conf.usage_per_minute,
        pause_per_minute: this.conf.pause_per_minute,
      };
    }
  }

  addRange() {
    this.e.addPricingRange(this.pricing, this.data).subscribe((data) => {
      this.s.open('Price range added', '', { duration: 500 });

      // delete old record
      if (this.mode === 'edit') {
        this.e
          .deleteMinuteUsageCost(this.pricing, this.conf.id)
          .subscribe((data) => {
            console.log('deleted');
          });
      }

      this.dialogRef.close(true);
    });
  }
}
