import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-ian-edit',
  templateUrl: './ian-edit.component.html',
  styleUrls: ['./ian-edit.component.scss'],
})
export class IanEditComponent implements OnInit {
  form_data = {
    title: this.data.title,
    content: this.data.content,
  };

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<IanEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  save() {
    const post_data = {
      ...this.data,
      ...this.form_data,
    };

    if (!this.form_data.title || !this.form_data.content) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.updateIan(this.data.id, post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
