<div class="dialog">
  <h1 mat-dialog-title translate>
    Setup {{ "notifications.delivery" | translate }}
  </h1>
  <mat-dialog-content>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label translate>Target</mat-label>
      <mat-select [(ngModel)]="form_data.target_type">
        <mat-option value="all" selected>{{
          "notifications.all" | translate
        }}</mat-option>
        <mat-option value="fleet">{{
          "notifications.fleet" | translate
        }}</mat-option>
        <mat-option value="user">{{
          "notifications.user" | translate
        }}</mat-option>
        <!-- <mat-option value="search">Search</mat-option> -->
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label translate>{{
        "notifications.target_user" | translate
      }}</mat-label>

      <input
        matInput
        type="text"
        [(ngModel)]="form_data.target_user"
        [disabled]="form_data.target_type != 'user'"
      />
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label translate>{{
        "notifications.target_fleet" | translate
      }}</mat-label>
      <mat-select
        [(ngModel)]="form_data.target_fleet"
        [disabled]="form_data.target_type != 'fleet'"
      >
        <mat-option *ngFor="let f of fleets | async" [value]="f.id">{{
          f.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label translate>{{
        "notifications.distribution_mode" | translate
      }}</mat-label>
      <mat-select multiple [(ngModel)]="form_data.distribution_modes">
        <mat-option
          *ngFor="let d of distribution_modes | async"
          [value]="d.id"
          >{{ d.description }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label translate>{{
        "notifications.delivery_by" | translate
      }}</mat-label>
      <input matInput type="date" [(ngModel)]="form_data.deadline" />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close translate>
      {{ "op.cancel" | translate }}
    </button>
    <button mat-button (click)="save()">OK</button>
  </mat-dialog-actions>
</div>
