import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EdwayService } from '../../edway.service';

@Component({
  selector: 'app-wallet-edit',
  templateUrl: './wallet-edit.component.html',
  styleUrls: ['./wallet-edit.component.scss'],
})
export class WalletEditComponent implements OnInit {
  data: any = {};

  constructor(
    private readonly edwayService: EdwayService,
    public dialogRef: MatDialogRef<WalletEditComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) {}

  ngOnInit(): void {
    this.idata = {
      times: -1,
    };

    if (this.idata) {
      this.data = this.idata;
    }
  }

  save(): void {
    console.log('Saving Wallet...', this.data);

    if (
      !this.data.name ||
      !this.data.price ||
      !this.data.value ||
      !this.data.times
    ) {
      alert('Please fill all the required fields');
      return;
    }

    this.edwayService
      .saveWallet(this.data)
      .subscribe((data: { id: string }) => {
        if (data.id) {
          this.dialogRef.close();
          location.reload();
        }
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
