import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
})
export class PackageComponent implements OnInit {
  package = {
    name: 'Pacchetto di Esempio',
    price: 100.0,
    itineraries: [],
    experiences: [],
  };

  constructor() {}

  ngOnInit(): void {}

  save() {
    alert();
  }
}
