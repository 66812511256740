<div class="dialog">
  <h1 mat-dialog-title>Add Info</h1>
  <mat-dialog-content>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{
        "vehicle_info.labels.vehicle_info_type" | translate
      }}</mat-label>
      <mat-select [(ngModel)]="form_data.vmeta_id">
        <mat-option *ngFor="let vmeta of vmetas" [value]="vmeta.name">
          {{ vmeta.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ "vehicle_info.labels.value" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="form_data.value" />
    </mat-form-field>
    <!-- <mat-form-field class="full-width" appearance="fill">
      <mat-label>attachment_url</mat-label>
      <input matInput type="text" [(ngModel)]="form_data.attachment_url" />
    </mat-form-field> -->
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ "vehicle_info.labels.expiry_date" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="form_data.expiry_date"
        [matDatepicker]="picker"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()">OK</button>
  </mat-dialog-actions>
</div>
