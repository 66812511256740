<button mat-icon-button matTooltip="{{'ops.filter'|translate}}" [matBadge]="'!'" [matBadgeHidden]="!filterSet" [matMenuTriggerFor]="filterMenu"><mat-icon>search</mat-icon></button>


<mat-menu #filterMenu>
  <button mat-button style="width:100%;" [disabled]="!filterSet" (click)="clear()">Reset</button>
  <br>
  <mat-button-toggle-group
    [(ngModel)]="filter.type"
    (ngModelChange)="update($event)"
    name="filter"
    aria-label="Ingredients"
    vertical="true"
    multiple
  >
    <mat-button-toggle class="small" value="ebike">
      {{ "vehicletype.ebike" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="moped">
      {{ "vehicletype.moped" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="scooter">
      {{ "vehicletype.scooter" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="car">
      {{ "vehicletype.car" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="charging_station">
      {{ "vehicletype.charging_station" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-divider></mat-divider>
  <mat-slider min="0" max="100" [(ngModel)]="filter.maxBattery"
  (ngModelChange)="update($event)" thumbLabel="true" step="10"> 🔋
  </mat-slider>
</mat-menu>