import { Component, Inject, OnInit } from '@angular/core';
import { CouponsService } from '../../coupons.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-apply-dialog',
  templateUrl: './apply-dialog.component.html',
  styleUrls: ['./apply-dialog.component.scss']
})
export class ApplyDialogComponent implements OnInit {
  user: string;
  result = '';
  constructor(
    public dialogRef: MatDialogRef<ApplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { service: CouponsService, couponCode: string }
  ) {
  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.data.service.applyCoupon(this.data.couponCode, this.user).subscribe(
      () => {
        this.dialogRef.close();
      },
      () => {
        this.result = 'Errore';
      }
    );
  }

}
