<h1 mat-dialog-title>{{'fleets.fleet_settings.add.label'|translate}}</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{'fleets.fleet_settings.key.header'|translate}}</mat-label>
      <mat-select matInput [(ngModel)]="data.key">
        <mat-option value="stripe.preauthorization.amount">{{'fleets.fleet_settings.key.preauth_amount'|translate}}</mat-option>
        <mat-option value="stripe.preauthorization.currency">{{'fleets.fleet_settings.key.preauth_currency'|translate}}</mat-option>
        <mat-option value="trigger">{{'fleets.fleet_settings.key.trigger'|translate}}</mat-option>
        <mat-option value="cron">{{'fleets.fleet_settings.key.cron'|translate}}</mat-option>
        <mat-option value="fields">{{'fleets.fleet_settings.key.fields'|translate}}</mat-option>
        <mat-option value="template">{{'fleets.fleet_settings.key.template'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['stripe.preauthorization.currency'].indexOf(data.key) >= 0">
        <mat-label>{{'fleets.fleet_settings.label.max_speed'|translate}}</mat-label>
        <mat-select [(ngModel)]="data.value">
            <mat-option value="eur">{{'fleets.fleet_settings.currency.eur'|translate}}</mat-option>
            <mat-option value="usd">{{'fleets.fleet_settings.currency.usd'|translate}}</mat-option>
            <mat-option value="chf">{{'fleets.fleet_settings.currency.chf'|translate}}</mat-option>
          </mat-select>    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['stripe.preauthorization.amount'].indexOf(data.key) >= 0">
        <mat-label>{{'fleets.fleet_settings.label.shut_down'|translate}}</mat-label>
        <input matInput type="number" min="1" max="10000" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['fields'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.label'|translate}}</mat-label>
      <input matInput type="text" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['template'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.description'|translate}}</mat-label>
      <textarea matInput type="text" [(ngModel)]="data.value"></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['trigger'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.trigger'|translate}}</mat-label>
      <mat-select [(ngModel)]="data.value">
        <mat-option value="cron">{{'fleets.fleet_settings.trigger.cron'|translate}}</mat-option>
        <mat-option value="add">{{'fleets.fleet_settings.trigger.add'|translate}}</mat-option>
        <mat-option value="remove">{{'fleets.fleet_settings.trigger.remove'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['cron'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.trigger'|translate}}</mat-label>
      
      <input matInput type="text" placeholder="* 0 0 0 0" [(ngModel)]="data.value">
    </mat-form-field>
    
    <br *ngIf="['icon'].indexOf(data.key) >= 0">
    
    <!--<mat-form-field class="example-full-width" appearance="fill" *ngIf="['icon'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.description'|translate}}</mat-label>
      <edway-file-upload [(ngModel)]="data.value"></edway-file-upload>
    </mat-form-field>-->
    
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['icon'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.description'|translate}}</mat-label>
      <textarea matInput type="text" [(ngModel)]="data.value"></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['can_park'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.can_park'|translate}}</mat-label>
      <mat-select matInput type="text" [(ngModel)]="data.value">
        <mat-option value="True">{{'common.bool.yes'|translate}}</mat-option>
        <mat-option value="False">{{'common.bool.no'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['visible'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.visible'|translate}}</mat-label>
      <mat-select matInput type="text" [(ngModel)]="data.value">
        <mat-option value="True">{{'common.bool.yes'|translate}}</mat-option>
        <mat-option value="False">{{'common.bool.no'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['activation'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.fleet_settings.label.activation'|translate}}</mat-label>
      <mat-select matInput type="text" [(ngModel)]="data.value">
        <mat-option value="True">{{'common.bool.yes'|translate}}</mat-option>
        <mat-option value="False">{{'common.bool.no'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="save()">{{'ops.ok'|translate}}</button>
</div>

