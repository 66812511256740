<mat-toolbar style="">
  <h1><span class="imei" *ngIf="static">{{ static.imei }}</span><!-- - <span class="internal" *ngIf="current.features">{{ current.features[0].properties.name }}</span>--></h1>
  <button class="hide-mobile" mat-icon-button matTooltip="{{'vehicles.ops.search'|translate}}" (click)="search()"><mat-icon>map</mat-icon></button>
  <button class="hide-mobile" mat-icon-button matTooltip="{{'vehicles.ops.navigate'|translate}}" (click)="navigate()"><mat-icon>timeline</mat-icon></button>
  <button class="hide-mobile" mat-icon-button matTooltip="{{'vehicles.ops.navigateG'|translate}}" (click)="navigateG()"><mat-icon>near_me</mat-icon></button>
  <button class="hide-desktop" [matMenuTriggerFor]="navigateToMenu" mat-icon-button matTooltip="{{'vehicles.ops.navigateG'|translate}}"><mat-icon>expand_more</mat-icon></button>

  <mat-menu #navigateToMenu="matMenu">
    <button mat-menu-item class="hide-mobile" matTooltip="{{'vehicles.ops.search'|translate}}" (click)="search()"><mat-icon>map</mat-icon>{{'vehicles.ops.search'|translate}}</button>
    <button mat-menu-item class="hide-mobile" matTooltip="{{'vehicles.ops.navigate'|translate}}" (click)="navigate()"><mat-icon>timeline</mat-icon>{{'vehicles.ops.navigate'|translate}}</button>
    <button mat-menu-item class="hide-mobile" matTooltip="{{'vehicles.ops.navigateG'|translate}}" (click)="navigateG()"><mat-icon>near_me</mat-icon>{{'vehicles.ops.navigateG'|translate}}</button>
  </mat-menu>
  
  <!--<button mat-icon-button (click)="debug(static.imei)"><mat-icon>monitor_heart</mat-icon></button>-->
  <span class="spacer"></span>
  <button mat-icon-button *ngIf="mode !== 'discovery'" matTooltip="{{'vehicles.ops.goDevice'|translate}}" [routerLink]="['/discovery', static.imei]"><mat-icon>memory</mat-icon></button>
  <button mat-icon-button *ngIf="mode === 'discovery'" matTooltip="{{'vehicles.ops.goVehicle'|translate}}" [routerLink]="['/vehicles', static.imei]"><mat-icon>electric_scooter</mat-icon></button>
  <button mat-icon-button [disabled]="!hasBLE" matTooltip="{{'vehicles.ops.connectBluetooth'|translate}}" (click)="connectBLE()"><mat-icon>bluetooth</mat-icon></button>
  <!--<button mat-icon-button (click)="delete()"><mat-icon>delete</mat-icon></button>-->
  <!--<button mat-icon-button (click)="edit()"><mat-icon>edit</mat-icon></button>-->
</mat-toolbar>
<div class="row hide-mobile">
  <mat-toolbar style="width: 50%;">
    <span translate>vehicles.detail.status</span> 
    <button mat-icon-button (click)="createRental()" matTooltip="{{'rentals.new.button'|translate}}"><mat-icon>car_rental</mat-icon></button>
    <button mat-icon-button *ngIf="updating"><mat-icon>refresh</mat-icon></button>
    <span class="spacer"></span>
    <span class="status" *ngIf="current?.features" ><i class="fa-solid fa-fw fa-battery"></i>{{ static?.battery }}%</span>
    <!--<span class="mini-spacer"></span>-->
    <span class="status" *ngIf="static?.battery > 15" ><i class="fa-solid fa-fw fa-plug"></i></span>
    <span class="status" *ngIf="static?.charging" ><i class="fa-solid fa-fw fa-plug-circle-bolt" style="color: green"></i></span>
    <span class="status" *ngIf="static?.charging && static?.battery <= 15"><i class="fa-solid fa-fw fa-plug-circle-exclamation" style="color: red" ></i></span>
    <!--<span class="spacer"></span>-->
    <span class="status" *ngIf="mode !== 'discovery' && current?.features[0].properties.running"><i class="fa-solid fa-fw fa-square-up-right"></i></span>
    <span class="status" *ngIf="mode !== 'discovery' && static?.locked"><i class="fa-solid fa-fw fa-square-parking"></i></span>
    <!--<span class="spacer"></span><span class="spacer"></span>-->
  </mat-toolbar>
  <vehicles-commands *ngIf="static"
    style="width: 50%"
    [imei]="static.imei"
    [protocol]="static.model.protocol"
  ></vehicles-commands>
</div>

<div class="row hide-desktop">
  <mat-toolbar  style="width: 100%;">
    <span translate>vehicles.detail.status</span> 
    <button mat-icon-button (click)="createRental()" matTooltip="{{'rentals.new.button'|translate}}"><mat-icon>car_rental</mat-icon></button>
    <span class="spacer"></span>
    <span class="status" *ngIf="current?.features" ><i class="fa-solid fa-fw fa-battery"></i>{{ static?.battery }}%</span>
    <!--<span class="mini-spacer"></span>-->
    <span class="status" *ngIf="static?.battery > 15" ><i class="fa-solid fa-fw fa-plug"></i></span>
    <span class="status" *ngIf="static?.charging" ><i class="fa-solid fa-fw fa-plug-circle-bolt" style="color: green"></i></span>
    <span class="status" *ngIf="static?.charging && static?.battery <= 15"><i class="fa-solid fa-fw fa-plug-circle-exclamation" style="color: red" ></i></span>
    <!--<span class="spacer"></span>-->
    <span class="status" *ngIf="current?.features[0].properties.running"><i class="fa-solid fa-fw fa-square-up-right"></i></span>
    <span class="status" *ngIf="static?.locked"><i class="fa-solid fa-fw fa-square-parking"></i></span>
    <!--<span class="spacer"></span><span class="spacer"></span>-->
  </mat-toolbar>
  </div>
  <div class="row hide-desktop">
  <vehicles-commands *ngIf="static"
    style="width: 100%"
    [imei]="static.imei"
    [protocol]="static.model.protocol"
  ></vehicles-commands>
</div>
<div class="row">
  <div>
    <mat-tab-group>
      <mat-tab label="{{'vehicle.tabs.general'|translate}}">
        <mat-toolbar>
          <h2 translate>vehicles.detail.codes</h2>
          <span class="spacer"></span>
          <button
            mat-icon-button
            matTooltip="{{ 'ops.add' | translate }}"
            (click)="addCode()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>

        <div class="column">
          <div class="row" *ngFor="let code of static.codes">
            <button
              mat-button
              matTooltip="{{ 'ops.delete' | translate }}"
              (click)="deleteCode(code)"
            >
              <i class="fa-solid fa-trash"></i>
            </button>
            <span class="cell header">{{ 'codes.'+code.context | translate }}</span>
            <span class="cell hide-mobile"  innerHTML="{{code.code}}"></span>
            <button mat-button class="cell hide-desktop" (click)="do_alert(code)"><mat-icon>preview</mat-icon></button>
            <span class="spacer"></span>
            <button
              mat-button
              matTooltip="{{ 'ops.edit' | translate }}"
              (click)="editCode(code)"
            >
              <i class="fa-solid fa-edit"></i>
            </button>
            <button
              mat-button
              matTooltip="{{ 'ops.qrcode' | translate }}"
              (click)="qr(code.code)"
            >
              <i class="fa-solid fa-qrcode"></i>
            </button>
          </div>
        </div>

        <mat-toolbar *ngIf="mode !== 'discovery'">
          <h2 translate>vehicles.detail.fleets</h2>
          <!--<button mat-icon-button (click)="toggleFleet('maintenance')"><mat-icon>settings</mat-icon></button>-->
          <button mat-icon-button (click)="toggleFleet('storage')">
            <i class="fa-solid fa-box"></i>
          </button>
          <span class="spacer"></span>
          <button mat-icon-button (click)="addFleet()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>
        <div class="column" *ngIf="mode !== 'discovery'">
          <div class="row" *ngFor="let fleet of fleets">
            <button matTooltip="{{'ops.delete'|translate}}" mat-button (click)="delFleet(fleet.id)">
              <i class="fa-solid fa-trash"></i>
            </button>
            <!--<button mat-button (click)="primaryFleet(fleet.id)">
              <i class="fa fa-regular fa-fw fa-star"></i>
            </button>-->
            <span class="cell">{{ fleet.name }}</span>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'vehicle.tabs.diagnostics'|translate}}">
        <edway-vehicle-diagnostics [data]="rtdata"></edway-vehicle-diagnostics>
      </mat-tab>
  </mat-tab-group>
  </div>
  <div class="hide-mobile">
    <mat-tab-group>
      <mat-tab label="{{ 'vehicles.detail.vehicle_info.title' | translate }}" *ngIf="mode !== 'discovery'">
        <edway-vehicle-info [vehicle_uuid]="static.ident"></edway-vehicle-info>
      </mat-tab>
      <mat-tab label="{{ 'vehicles.detail.model_info' | translate }}">
        <edway-models-info [model]="static.model"></edway-models-info>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div class="hide-desktop">
    <mat-tab-group>
      <mat-tab label="{{ 'vehicles.detail.vehicle_info.title' | translate }}">
        <edway-vehicle-info [vehicle_uuid]="static.ident"></edway-vehicle-info>
      </mat-tab>
      <mat-tab label="{{ 'vehicles.detail.model_info' | translate }}">
        <edway-models-info [model]="static.model"></edway-models-info>
      </mat-tab>
    </mat-tab-group>
</div>
<mat-tab-group *ngIf="static">
  <mat-tab label="{{ 'vehicles.detail.tabs.rentals' | translate }}" *ngIf="mode !== 'discovery'">
    <ng-template matTabContent>
      <edway-rentals-list [vehicle]="static.imei"></edway-rentals-list>
    </ng-template>
  </mat-tab>
  <!--
  <mat-tab label="{{ 'vehicles.detail.tabs.bookings' | translate }}">
    <ng-template matTabContent>
      <edway-bookings-list [vehicle]="static.imei"></edway-bookings-list>
    </ng-template>
  </mat-tab>-->
  <mat-tab label="{{ 'vehicles.detail.tabs.issues' | translate }}">
    <ng-template matTabContent>
      <edway-issues-list [vehicle]="static.imei"></edway-issues-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'vehicles.detail.tabs.pics' | translate }}" *ngIf="mode !== 'discovery'">
    <ng-template matTabContent>
      <edway-pictures-log [vehicle]="static.imei"></edway-pictures-log>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'vehicles.detail.tabs.logs' | translate }}">
    <ng-template matTabContent>
      <edway-vehicles-log [vehicle]="static.imei"></edway-vehicles-log>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<router-outlet></router-outlet>
