import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { BoolCellRendererComponent } from 'src/app/bool-cell-renderer/bool-cell-renderer.component';
import { EdwayService } from 'src/app/edway.service';
import { ProductRendererComponent } from 'src/app/product-renderer/product-renderer.component';
import { AmendComponent } from 'src/app/rentals/amend/amend.component';

@Component({
  selector: 'edway-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.scss'],
})
export class PackagesListComponent implements OnInit {
  @Input() user: string;
  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  rowData = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private tr: TranslateService
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.e.refreshPackages.subscribe((x) => {
      this.refresh();
    });
    this.refresh();

    this.columnDefs = [{
        field: 'actions',
        filter: false,
        maxWidth: 50,
        headerName: this.tr.instant('fields.rentals.actions'),
        cellRenderer: AmendComponent,
        cellRendererParams: {
          buttons: [
            {
              label: this.tr.instant('op.exhaust'),
              icon: 'auto_delete',
              condition: (params) => {
                return true;
              },
              clicked: (params: ICellRendererParams) => {
                if(confirm(this.tr.instant('users.ops.exhaust'))){
                  this.e.exhaustPackage(params).subscribe(_=>{
                    this.refresh();
                  });
                }
                return false;
              },
            }
          ],
        },
      },
      {
        headerName: this.tr.instant('users.packages.package'),
        field: 'package',
        resizable: true,
        cellRenderer: ProductRendererComponent,
      },
      {
        headerName: this.tr.instant('users.packages.acquired'),
        field: 'acquired',
        resizable: true,
      },
      {
        headerName: this.tr.instant('users.packages.activated'),
        field: 'activated',
        resizable: true,
        cellRenderer: BoolCellRendererComponent,
      },
      {
        headerName: this.tr.instant('users.packages.active'),
        field: 'active',
        resizable: true,
        cellRenderer: BoolCellRendererComponent,
      },
      {
        headerName: this.tr.instant('users.packages.remaining_time'),
        field: 'remaining_time',
        resizable: true,
      },
      {
        headerName: this.tr.instant('users.packages.remaining_unlocks'),
        field: 'remaining_unlocks',
        resizable: true,
      },
      {
        headerName: this.tr.instant('users.packages.exhausted'),
        field: 'exhausted',
        resizable: true,
        cellRenderer: BoolCellRendererComponent,
      },
      
    ];
    if (!this.user){
      this.columnDefs.unshift({
        headerName: this.tr.instant('users.packages.user'),
        field: 'username',
      })
    }
  }

  refresh() {
    let ret = [];
    this.e.getPackages({user:this.user}).subscribe((data: any[]) => {
      console.log(data);
      data.sort(orderPackage);

      this.rowData = data;
      this.show = true;
    });
  }

  onRowClicked(ev) {
    this.r.navigate(['/users', ev.data.ident]);
  }
}

function orderPackage(a, b) {
  // attivi e non exausted
  // non attivi
  // exausted
  return a.exhausted - b.exhausted || b.active - a.active;
}
