<mat-toolbar>
  <h2>POIs</h2>
  <span class="spacer"></span>
  <button mat-icon-button (click)="toggleFilter()">
    <mat-icon>search</mat-icon>
  </button>
</mat-toolbar>

<div class="filter" *ngIf="showFilter">
  <mat-form-field>
    <mat-label>Cerca nel nome...</mat-label>
    <input
      class="text-filter"
      matInput
      placeholder=""
      [(ngModel)]="text_filter"
    />
    <button
      type="button"
      mat-button
      matSuffix
      mat-icon-button
      (click)="clearTextFilter()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-form-field>

  <span
    class="item-tag tag-{{ tag.family }}"
    [ngClass]="{ inactive: !tags_filter[tag.label] }"
    *ngFor="let tag of tags"
    (click)="toggleTag(tag)"
    >{{ tag.label }}</span
  >
</div>

<ng-container *ngFor="let poi of pois_list">
  <div class="list-item col" [value]="poi.poi" *ngIf="canSee(poi)">
    <span class="name">{{ poi.name }}</span>
    <div class="row">
      <span class="list-item-tag tag-{{ t.family }}" *ngFor="let t of poi.tags">
        {{ t.label }}
      </span>
    </div>

    <div class="row">
      <button (click)="go(i)" mat-icon-button>
        <mat-icon>visibility</mat-icon>
      </button>
      <button (click)="selectItem(poi.poi)" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <span class="spacer"></span>
      <button (click)="delete(i)" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
