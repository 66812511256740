<div class="dialog">
  <h1 mat-dialog-title>{{'screens.add_resource'|translate}}</h1>
  <mat-dialog-content>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ "screens.title" | translate }}</mat-label>
      <input required matInput type="text" [(ngModel)]="form_data.title" />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ "screens.locale" | translate }}</mat-label>
      <mat-select required [(ngModel)]="form_data.locale">
        <mat-option value="it">IT</mat-option>
        <mat-option value="en">EN</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'op.cancel'|translate}}</button>
    <button mat-button (click)="save()">{{'op.ok'|translate}}</button>
  </mat-dialog-actions>
</div>
