import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-delete-cell-renderer',
    template: `
    <button mat-icon-button (click)="btnClickedHandler('apply')" #tooltip="matTooltip"
          matTooltip="Applica un coupon per un utente"
          matTooltipPosition="above"
          matTooltipShowDelay="800"><mat-icon>play_arrow</mat-icon></button>
    <button mat-icon-button (click)="btnClickedHandler('redirect')" #tooltip="matTooltip"
          matTooltip="Punta ad un altro coupon"
          matTooltipPosition="above"
          matTooltipShowDelay="800"><mat-icon>double_arrow</mat-icon></button>
    <button mat-icon-button (click)="btnClickedHandler('disable')" #tooltip="matTooltip"
          matTooltip="Disabilita il coupon"
          matTooltipPosition="above"
          matTooltipShowDelay="800"><mat-icon>block</mat-icon></button>
    <button mat-icon-button (click)="btnClickedHandler('delete')" #tooltip="matTooltip"
          matTooltip="Elimina"
          matTooltipPosition="above"
          matTooltipShowDelay="800"><mat-icon>delete</mat-icon></button>
  `,
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
    private params: any;

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    btnClickedHandler(eventType: 'redirect' | 'disable' | 'delete' | 'apply'): void {
        this.params[eventType](this.params);
    }
}
