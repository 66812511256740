<h1 mat-dialog-title>Webhook</h1>

<div mat-dialog-content>
  <mat-horizontal-stepper>
    <mat-step>
        <ng-template matStepLabel>Sources</ng-template>
        <mat-toolbar>Sources</mat-toolbar>
        <mat-radio-group [(ngModel)]="data.sources" aria-label="Select an option">
          <mat-radio-button value="all">All</mat-radio-button><br>
          <mat-radio-button value="selection">Selection</mat-radio-button>
        </mat-radio-group>
    <div class="group"></div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Events</ng-template>
      <mat-toolbar>Events</mat-toolbar>
      <mat-radio-group [(ngModel)]="data.events" aria-label="Select an option">
        <mat-radio-button value="all">All</mat-radio-button><br>
        <mat-radio-button value="selection">Selection</mat-radio-button>
      </mat-radio-group>
      <section  *ngIf="data.events==='selection'" aria-label="Select an option">
        <ng-container *ngFor="let event of events">
          <mat-checkbox [(ngModel)]="data.events_list[event.family+'_'+event.name]" value="position">{{'cloud.events.'+event.family+'.'+event.name|translate}}</mat-checkbox><br>
        </ng-container>
      </section >

    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Format</ng-template>
      <mat-toolbar>Format</mat-toolbar>
      <mat-radio-group [(ngModel)]="data.transformation" aria-label="Select an option">
        <ng-container *ngFor="let transformation of transformations">
          <mat-radio-button [disabled]="!transformation.enabled" [value]="transformation.name">{{transformation.label}}</mat-radio-button><br>
        </ng-container>
      </mat-radio-group>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Target</ng-template>
      <mat-toolbar>Target</mat-toolbar>
      
      <mat-radio-group [(ngModel)]="data.target" aria-label="Select an option">
        <mat-radio-button value="custom">Custom</mat-radio-button><br>
        <div *ngIf="data.target === 'custom'">  
          <mat-form-field class="example-full-width">
            <mat-label>URL</mat-label>
            <input matInput [(ngModel)]="data.target_details.url" type="url" >
          </mat-form-field>        
        </div>
        <mat-radio-button value="hom_fleet">HOM Fleet Manager</mat-radio-button><br>
        <mat-radio-button value="hom_logger">HOM Logger</mat-radio-button><br>
      </mat-radio-group>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div mat-dialog-commands>
    <button mat-button (click)="createWH(data)">ok</button>
    <button mat-button>cancel</button>
</div>