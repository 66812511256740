import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { AddComponent } from './add/add.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddMinutesComponent } from './add-minutes/add-minutes.component';
import { DeleteComponent } from './delete/delete.component';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    AddComponent,
    AddMinutesComponent,
    DeleteComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    TranslateModule,
  ]
})
export class PricingModule { }
