import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { EdwayService } from '../edway.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'edway-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {

  constructor(
    private e: EdwayService,
    private b: MatSnackBar
  ) { }
  ngOnDestroy(): void {}
  value: string;
  status = "ready";
  inner: File;
  stateChanges: Observable<void>;
  id: string;
  placeholder: string;
  ngControl: NgControl;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  onChange: any = () => {}
  onTouch: any = () => {}

  onFileSelected(ev){
    this.inner = ev.target.files[0]
    this.status = "uploading";
    this.writeValue(this.inner.name);
  }
  
  setDescribedByIds(ids: string[]): void {
    throw new Error('Method not implemented.');
  }
  onContainerClick(event: MouseEvent): void {
    throw new Error('Method not implemented.');
  }
  writeValue(obj: string): void {
    this.value = obj;
    
    this.e.uploadFile(this.inner).subscribe(data=>{
      this.status = "uploaded";
      this.b.open('upload done', 'ok', {duration:500});
    })
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
  }

}
