<mat-toolbar>
  <h1 translate>dashboard.screens</h1>
  <span class="spacer"></span>
  <button mat-icon-button (click)="addScreen()">
    <mat-icon>add</mat-icon>
  </button>
</mat-toolbar>
<mat-paginator
  length="1000"
  pageSize="25"
  (page)="toPage($event)"
  aria-label="Select page"
>
</mat-paginator>
<ag-grid-angular
  *ngIf="show"
  class="fullpage ag-theme-alpine"
  [rowData]="list"
  [defaultColDef]="defaultColDef"
  [headerHeight]="36"
  [rowHeight]="40"
  [columnDefs]="columnDefs"
>
</ag-grid-angular>
