import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DlfilterComponent } from './dlfilter/dlfilter.component';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private _sb: MatSnackBar,
    private d: MatDialog,
    private h: HttpClient
  ) { }

  download(url: string, options: any, area?:string): Observable<any> {
    if (area){
      let f = this.d.open(DlfilterComponent, {data:{area: area}});
      return f.afterClosed().pipe(
        switchMap(data=>{
          if (data) {
            options.params = data;
            this._sb.open('Preparing the export...', null, {duration: 500});
            return this.h.get(url, options).pipe(tap(x=> {
              this._sb.open('download available in area and on your telegram channel', 'ok', {duration: 1000})
            }));
          } else {
            return of(false);
          }
        })
      );
    } else{
      this._sb.open('Preparing the export...', null, {duration: 500});
      return this.h.get(url, options).pipe(tap(x=> {
        this._sb.open('download available in area and on your telegram channel', 'ok', {duration: 1000})
      }));
    }
  }
}
