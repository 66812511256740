<h1 mat-dialog-title translate>pricing.add.title</h1>
<mat-dialog-content>
<div class="container">
  <mat-form-field appearance="fill">
    <mat-label translate>common.model</mat-label>
    <mat-select (selectionChange)="onModelSelected($event.value)">
      <mat-option *ngFor="let model of models$ | async" [value]="model.ident">
        {{ model.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.fleet' | translate }}</mat-label>
    <mat-select (selectionChange)="onFleetSelected($event.value)">
      <mat-option *ngFor="let fleet of fleets$ | async" [value]="fleet.id">
        {{ fleet.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.unlock' | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.unlock">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.booking' | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.booking">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.max_cost' | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.max_cost">
  </mat-form-field>
  <edway-file-upload [(ngModel)]="data.image"></edway-file-upload>
</div>
</mat-dialog-content>
<mat-dialog-actions>
<button mat-flat-button color="primary" mat-dialog-close="null">{{'op.cancel'|translate}}</button>
<button mat-flat-button color="primary" (click)="save()">ok</button>
</mat-dialog-actions>