<mat-toolbar>
  <h1 translate>packages.title</h1>
  <span class="spacer"></span>
  <!--<button mat-icon-button title="{{ 'ops.dl' | translate }}">
    <mat-icon>download</mat-icon>
  </button>-->
</mat-toolbar>
<mat-tab-group>
  <mat-tab label="{{ 'packages.packages' | translate }}">
    <mat-toolbar>
      <h3 translate>packages.packages</h3>
      <span class="spacer"></span>
      <button
        (click)="openPacket('add')"
        title="{{ 'ops.add' | translate }}"
        mat-icon-button
      >
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
    <edway-packages-list
      [refreshList]="refreshPackageList"
    ></edway-packages-list>
  </mat-tab>
  <mat-tab label="{{ 'packages.wallets' | translate }}">
    <mat-toolbar>
      <h3 translate>packages.wallets</h3>
      <span class="spacer"></span>
      <button
        (click)="openWallet('add')"
        title="{{ 'ops.add' | translate }}"
        mat-icon-button
      >
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
    <edway-wallet-list></edway-wallet-list> </mat-tab
>  <mat-tab disabled="true" label="{{ 'packages.itineraries' | translate }}">
  <mat-toolbar>
    <h3 translate>packages.itineraries</h3>
    <span class="spacer"></span>
    <button
      (click)="openWallet('add')"
      title="{{ 'ops.add' | translate }}"
      mat-icon-button
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-toolbar>
  <edway-wallet-list></edway-wallet-list> </mat-tab
><mat-tab disabled="true" label="{{ 'packages.experiences' | translate }}">
  <mat-toolbar>
    <h3 translate>packages.experiences</h3>
    <span class="spacer"></span>
    <button
      (click)="openWallet('add')"
      title="{{ 'ops.add' | translate }}"
      mat-icon-button
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-toolbar>
  <edway-wallet-list></edway-wallet-list> </mat-tab
></mat-tab-group>
