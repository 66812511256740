<h1>Infos</h1>
<table>
    <tr>
        <td>Android</td><td>Apple</td>
    </tr>
    <tr>
        <td>{{app.android}}</td>
        <td>https://apps.apple.com/app/{{app.apple}}</td>
    </tr>
    <tr>
        <td></td>
        <td></td>
    </tr>
</table>