import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EdwayService } from '../edway.service';

import {tap, mergeMap} from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private h: HttpClient, private e: EdwayService) { }

  login(username, password){
    localStorage.setItem('username', username);
    const url = environment.api_endpoint+"/auth/login"
    return this.h.post(url, {email: username, password}).pipe(
      tap((x:any)=>{
        localStorage.setItem('access_token', x.access_token);
      }),
      mergeMap(b=> this.e.getLoggedin(username)),
      tap((data: any) => {
          localStorage.setItem('client-id', data.clients[0]);
          localStorage.setItem('clients', JSON.stringify(data.clients));
      }),
      mergeMap(a => this.h.get(environment.api_endpoint+"/admin/roles/users/"+username)),
      tap((xx: any) =>{
        localStorage.setItem('roles', JSON.stringify(xx[0]['roles']));
      })
    );
  }

  logout(){
    const usr = localStorage.getItem('impersonator-username');
    const cli = localStorage.getItem('impersonator-client-id');

    if (usr){
      localStorage.setItem('username', usr);
      localStorage.setItem('client-id', cli);
      localStorage.removeItem('impersonator-username');
      localStorage.removeItem('impersonator-client-id');
    }
    else{

      localStorage.removeItem('access_token');
      localStorage.removeItem('client-id');
      localStorage.removeItem('username');
      localStorage.removeItem('clients');
      localStorage.removeItem('roles');
    }
  }

  isLoggedin(){
    return localStorage.getItem('access_token') !== null &&
      localStorage.getItem('client-id') !== null &&
      localStorage.getItem('username') !== null &&
      localStorage.getItem('roles') !== null &&
      localStorage.getItem('clients') !== null;
      
  }

  getUsername(){
    return localStorage.getItem('username');
  }

  canDo(op, fleet?){
    const roles = JSON.parse(localStorage.getItem('roles'));
    return roles[op] || roles['admin'];
  }

  sendNewPw(user){
    return this.h.post(environment.api_endpoint+"/auth/reset", {user: user});
  }

  telegram(data){
    
    localStorage.setItem('username', data.usernme);
    return this.h.post(environment.api_endpoint+"/auth/telegram", data).pipe(
      tap((x:any)=>{
        localStorage.setItem('access_token', x.access_token);
      }),
      mergeMap(b=> this.e.getLoggedin(data.usernme)),
      tap((data: any) => {
          localStorage.setItem('client-id', data.clients[0]);
          localStorage.setItem('clients', JSON.stringify(data.clients));
      }),
      mergeMap(a => this.h.get(environment.api_endpoint+"/admin/roles/users/"+data.username)),
      tap((xx: any) =>{
        localStorage.setItem('roles', JSON.stringify(xx[0]['roles']));
      })
    );
  
  }

  getOTP(username){
    return this.h.get(environment.api_endpoint+"/auth/otp?user="+username);
  }

  otpLogin(username, otp, password, token){
    const d = {};
    d['email']= username;
    d['password'] = otp;
    d['token'] = token;
    d['new_password'] = password;
    return this.h.post(environment.api_endpoint+"/auth/otp", d).pipe(
      mergeMap(x => this.h.post(environment.api_endpoint+"/auth/login", d)),
      tap((x:any)=>{
        localStorage.setItem('access_token', x.access_token);
      }),
      mergeMap(b=> this.e.getLoggedin(username)),
      tap((data: any) => {
          localStorage.setItem('client-id', data.clients[0]);
          localStorage.setItem('clients', JSON.stringify(data.clients));
      }),
      mergeMap(a => this.h.get(environment.api_endpoint+"/admin/roles/users/"+username)),
      tap((xx: any) =>{
        localStorage.setItem('roles', JSON.stringify(xx[0]['roles']));
      })
    );
  }

  updateRoles(){
    const username = localStorage.getItem('username')
    return this.h.get(environment.api_endpoint+"/admin/roles/users/"+username).pipe(tap((xx: any) =>{
      localStorage.setItem('roles', JSON.stringify(xx[0]['roles']));
    }))
  }
}
