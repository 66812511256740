import { Injectable } from '@angular/core';
import { delay, first, map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getSettings(): Observable<Record<string, unknown>> {
    return this.http.get<Record<string, unknown>>(environment.api_endpoint + '/settings/keys');
  }

  deleteKey(key: string): Observable<Record<string, unknown>> {
    return this.http.delete<Record<string, unknown>>(environment.api_endpoint + '/settings/keys/' + key);
  }

  update(key: string, value: unknown): Observable<Record<string, unknown>> {
    return this.http.post<Record<string, unknown>>(environment.api_endpoint + '/settings/keys/' + key, value);
  }
}
