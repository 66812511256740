import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  data: any = {op: "Add", mode: 'fleet'};
  models: any[];
  fleets: any[];

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) { }

  ngOnInit(): void {
    console.log(this.idata);
    if(this.idata){
      this.data = this.idata;
      this.fleets = this.data.fleets;
    }
  }

  
  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
