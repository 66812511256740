import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-amend-dialog',
  templateUrl: './amend-dialog.component.html',
  styleUrls: ['./amend-dialog.component.scss']
})
export class AmendDialogComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AmendDialogComponent>,
    private ed: EdwayService
  ) { 
    console.log(data);
  }

  ngOnInit(): void {
    console.log(this.data);

    let amount = 0;

    for (let p of this.data.penalties){
      amount += p.amount;
    }

    this.data.amount = amount;
  }

  reimburse(data){
    console.log('reimbursing', data);
    this.ed.reimburse(data).subscribe(d=>{
      this.dialogRef.close(); 
    });
  }

}
