<mat-toolbar>
  <span>{{'coupons.history'|translate}}</span>
  <button mat-icon-button (click)="refresh()"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>
<ag-grid-angular class="ag-theme-alpine" style="display: block; height: 100%;" [rowData]="coupons$ | async"
  [immutableData]="immutableData" [columnDefs]="columnDefs$ | async" [getRowId]="getRowId"
  (gridReady)="onGridReady($event)" 
  [rowHeight]="40"
  [headerHeight]="36">
</ag-grid-angular>
