<h1 mat-dialog-title>{{ "issues.problem_details" | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="column">
      <table>
        <tr>
          <th>{{ "issues.user" | translate }}</th>
          <td>
            <a href="/users/{{ data.user }}">{{ data.user }}</a>
          </td>
        </tr>
        <tr>
          <th>{{ "issues.vehicle" | translate }}</th>
          <td>
            <a href="/vehicles/{{ data.vehicle }}">{{ data.vehicle }}</a>
          </td>
        </tr>
        <tr>
          <th>{{ "issues.signaling" | translate }}</th>
          <td>{{ data.signaling }}</td>
        </tr>
        <tr>
          <th>{{ "issues.signaled" | translate }}</th>
          <td>{{ data.signaled }}</td>
        </tr>
        <tr>
          <th>{{ "issues.issue_type" | translate }}</th>
          <td>{{ 'issue.'+data.issue_type | translate }}</td>
        </tr>
      </table>

      <div *ngIf="data.signaling === 'user'">
        <table>
          <tr *ngFor="let i of data.data.issue">
            <td>{{ i }}</td>
          </tr>
          <tr>
            <th>{{'issues.details'|translate}}:</th>
          </tr>
          <tr>
            <td>{{ data.data.notes }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="column"
      *ngIf="
        data.signaling === 'automatic' &&
        data.issue_type === 'vehicle_out_of_area'
      "
    >
      <div id="map"></div>
    </div>

    <div class="column" *ngIf="data.signaling === 'user'">
      <img [src]="data.data.picture" width="400" />
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Ok</button>
</div>
