<mat-toolbar>
  <h1>{{ "dashboard.issues" | translate }}</h1>
  
  <button mat-icon-button title="{{'ops.refresh'|translate}}" (click)="update(true)"><mat-icon>refresh</mat-icon></button>

  <span class="spacer"></span>
 
  <!--<mat-button-toggle-group name="rental_status" aria-label="Rental Status" [(ngModel)]="filter.status" (click)="update()">
      <mat-button-toggle value="unpaid"><span matTooltip="{{'issues.class.automatic'|translate}}">🤖</span></mat-button-toggle>
      <mat-button-toggle value="paid"><span matTooltip="{{'issues.class.user'|translate}}">👦🏻</span></mat-button-toggle>
  </mat-button-toggle-group>-->
  <span class="spacer"></span>
  <button mat-icon-button title="{{'ops.dl'|translate}}" (click)="download('https://api.hoponmobility.com/admin/data/issues.xlsx')"><mat-icon>download</mat-icon></button>

</mat-toolbar>
<ag-grid-angular
  class="fullpage ag-theme-alpine"
  [defaultColDef]="defaultColDef"
  [rowData]="list"
  rowModelType="infinite"
  [headerHeight]="36"
  [rowHeight]="40"
  
  [columnDefs]="columnDefs"
  (page)="goToPage($event)"
  (rowClicked)="onRowClicked($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
