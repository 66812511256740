import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-battery-cell-renderer',
  templateUrl: './battery-cell-renderer.component.html',
  styleUrls: ['./battery-cell-renderer.component.scss']
})
export class BatteryCellRendererComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
