<mat-toolbar>
  <h1 translate>coupons.models.title</h1>
  <span class="spacer"></span>
  <button mat-icon-button matTooltip="{{'ops.add'|translate}}" (click)="createCouponModel()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<ng-container *ngIf="models$ | async as models">
  <mat-list>
    <mat-list-item *ngFor="let model of models" (click)="gotoDetail(model.id)">
      <mat-icon matListIcon>approval</mat-icon>
      <mat-icon (click)="delete(model.id); $event.stopPropagation()">delete</mat-icon>
      <h2 matLine style="font-weight: 500;">{{ model.name }}</h2>
      <p matLine>{{ model.description }}</p>
      <p class="date" matLine>Creato {{ model.createdAt }}</p>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</ng-container>