import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {
  @Input() tags_list: any;

  item_id: any = null;
  isNewRecord = true;

  @Input() experience_schema = {
    name: 'Esperienza di Esempio',
    pois: [],
    description: "Descrizione dell'esperienza di esempio.",
    gallery: [],
    price: 50.0,
    // duration: '',
    tags: [],
  };

  @Input() selectedExperience: any;

  experience: any = this.experience_schema;

  constructor(private e: EdwayService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.item_id = changes.selectedExperience.currentValue;

    if (this.item_id) {
      this.edit();
    }
  }

  save() {
    alert();
  }

  addPoi() {}

  edit() {
    this.isNewRecord = false;
    this.loadExperienceData(this.item_id);
  }

  loadExperienceData(id: string) {
    this.e.getSingleExperience(id, true).subscribe((data) => {
      this.experience = data;

      this.experience.tags = this.experience.tags.map((tag) => tag.id);
    });
  }
}
