import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { LogdataComponent } from 'src/app/logdata/logdata.component';

@Component({
  selector: 'edway-vehicles-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  gridApi!: GridApi;
  filter = {
    to_time: new Date(Date.now() + 86400000).toISOString().split('T')[0],
    from_time: new Date(Date.now() - 86400000).toISOString().split('T')[0]
  }
  public defaultColDef: ColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    minWidth: 100,
    filter: false,
    floatingFilter: false,
    resizable: true,
  };
  columnDefs: any[] = [

  ];
  logs: any[];
  show = false;
  

  @Input() vehicle: string;

  constructor(
    private ar: ActivatedRoute,
    private r: Router,
    private e: EdwayService,
    public dialog: MatDialog,
    private tr: TranslateService
  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    this.columnDefs = [
      { 
        field: 'logtype', 
        filter: true,
        headerName: this.tr.instant('log.logtype') 
      },
      { 
        field: 'timestamp', 
        headerName: this.tr.instant('log.timestamp'), 
        filter: 'agDateColumnFilter' 
      },
      {
        headerName: this.tr.instant('log.data'),
        children: [
          { 
            field: 'data.event.enum', 
            filter: 'agTextColumnFilter',
            headerName: this.tr.instant('log.event.enum'),
          },
          { 
            field: 'data.edway.battery', 
            headerName: this.tr.instant('log.edway.battery'),
          },
          { 
            field: 'data.position.altitude', 
            headerName: this.tr.instant('log.position.altitude'),
          },
          { 
            field: 'data.vehicle.mileage', 
            headerName: this.tr.instant('log.vehicle.mileage'),
          },
        ]
    },]
    this.updatelogs();
  }

  onRowClicked(e){
    const dialogRef = this.dialog.open(LogdataComponent, {
      width: '400px',
      data: e.data,
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  showTrack(){
    this.r.navigate(['/map', {track:[this.vehicle], from:this.filter.from_time, to:this.filter.to_time}])
  }

  
  onBtnExport() {
    var params = {columnSeparator: null};
    if (params.columnSeparator) {
      alert(
        "NOTE: you are downloading a file with non-standard separators - it may not render correctly in Excel.",
      );
    }
    this.gridApi.exportDataAsCsv(params);
  }

  updatelogs(){
    console.log(this.filter);
    this.e.getVehicleLogs(this.vehicle, this.filter.from_time, this.filter.to_time).subscribe((data:any[])=>{
      data.forEach((v,i)=> {
        v.data = dotToObject(v.data);
      })
      this.logs = data;
      this.show = true;
    })
  }


  filterlogs(){
    this.updatelogs();
  }
  
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

}

function dotToObject(data) {
  function index(parent, key, value) {
    const [mainKey, ...children] = key.split(".");
    if(typeof parent[mainKey] === 'object'){
      parent[mainKey] = parent[mainKey] ;
    } else {
      parent[mainKey] = {};
    }

    if (children.length === 1) {
      parent[mainKey][children[0]] = value;
    } else {
      index(parent[mainKey], children.join("."), value);
    }
  }

  const result = Object.entries(data).reduce((acc, [key, value]) => {
    if (key.includes(".")) {
      index(acc, key, value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
  return result;
}