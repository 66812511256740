<h1 mat-dialog-title>Log Data for {{data.vehicle}}</h1>
<div mat-dialog-content>
    <table padding="0" spacing="0">
        <tr *ngFor="let field of keys(data.data); let i =index;">
            <th>{{field}}</th>
            <td *ngIf="!isDictionary(data.data[field])">{{data.data[field]}}</td>
            <td *ngIf="isDictionary(data.data[field])">
                <table padding="0" spacing="0">
                    <tr *ngFor="let ffield of keys(data.data[field]); let ii =index;">
                        <th>{{ffield}}</th>
                        <td *ngIf="isDictionary(data.data[field][ffield])">
                            <table padding="0" spacing="0">
                                <tr *ngFor="let fffield of keys(data.data[field][ffield]); let ii =index;">
                                    <th>{{fffield}}</th>
                                    <td *ngIf="isDictionary(data.data[field][ffield][fffield])">
                                        <table padding="0" spacing="0">
                                            <tr *ngFor="let ffffield of keys(data.data[field][fffield]); let ii =index;">
                                                <th>{{ffffield}}</th>
                                                <td *ngIf="isDictionary(data.data[field][ffield][fffield][ffffield])">
                                                    
                                                </td>
                                                <td *ngIf="!isDictionary(data.data[field][ffield][fffield][ffffield])">{{data.data[field][ffield][fffield][ffffield]}}</td>
                                            </tr>
                                        </table>    
                                    </td>
                                    <td *ngIf="!isDictionary(data.data[field][ffield][fffield])">{{data.data[field][ffield][fffield]}}</td>
                                </tr>
                            </table>    
                        </td>
                        <td *ngIf="!isDictionary(data.data[field][ffield])">{{data.data[field][ffield]}}</td>
                    </tr>
                </table>    
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Ok</button>
</div>