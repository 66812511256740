<button class="rowbutton" mat-button [matMenuTriggerFor]="menu" (click)="noclick($event)" [disabled]="inUse">
    <i matTooltip="{{'vstatus.online'|translate}}" class="fa-solid fa-check" style="color: green" *ngIf="!inUse && value==='online'"></i>
    <i matTooltip="{{'vstatus.offline'|translate}}" class="fa-solid fa-x" style="color: rgb(255, 162, 0)" *ngIf="!inUse && value==='offline'"></i>
    <i matTooltip="{{'vstatus.unreacheable'|translate}}" class="fa-solid fa-triangle-exclamation" style="color: red" *ngIf="!inUse && value==='unreacheable'"></i>
    <i matTooltip="{{'vstatus.management'|translate}}" class="fa-solid fa-gear" style="color: brown" *ngIf="!inUse && value==='management'"></i>
    <i matTooltip="{{'vstatus.nostatus'|translate}}" class="fa fa-solid fa-question" style="color:rgb(158, 62, 209)" *ngIf="!inUse && value === null"></i>
    <i matTooltip="{{value}}" class="fa fa-solid fa-person" style="color:blue" *ngIf="inUse"></i>
</button>

<mat-menu #menu="matMenu">
    <button (click)="setStatus('online')" mat-menu-item><i class="fa-solid fa-fw fa-check" style="color:green"></i><span translate>vstatus.online</span></button>
    <button (click)="setStatus('offline')" mat-menu-item><i class="fa-solid fa-fw fa-x" style="color: rgb(255, 162, 0)"></i><span translate>vstatus.offline</span></button>
    <button (click)="setStatus('management')" mat-menu-item><i class="fa-solid fa-fw fa-gear" style="color: brown"></i><span translate>vstatus.management</span></button>
  </mat-menu>