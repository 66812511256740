<h1>Navigation Simulator</h1>
<mat-dialog-content>
    <div class="row">
        <div class="col">
            <div id="navmap"></div>
            <div id="directions" class="row">
                <mat-icon>{{stepdata['icon']}}</mat-icon>
                <p>
                    {{indication}}
                </p>
            </div>
        </div>
        <div class="col">     
            <div id="timer">🕒: <input [(ngModel)]="datetime" type="datetime-local"></div>
            <div id="battery">🔋: <input [(ngModel)]="battery" type="number"></div>
            <div id="battery">🧑🏻: <input [(ngModel)]="user" type="text"></div>
            <h3>Nearby: {{nearby.count}}</h3>
            <div id="nearby">
                <div class="poi" *ngFor="let poi of nearby.features">
                    <h2>{{poi.properties.name}} ({{poi.properties.distance}}m)</h2>
                    <p>{{poi.properties.description}}</p>
                    <div class="row">
                        <span class="list-item-tag tag-{{ t.family }}" *ngFor="let t of poi.properties.tags">
                          {{ t.label }}
                        </span>
                      </div>
                </div>
            </div>
        </div>
        <div class="col">     
            <div id="debug">
                {{stepdata|json}}
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button [disabled]="step===0" (click)="nextStep(-1)"><</button>
    <button mat-button [disabled]="step===length" (click)="nextStep()">></button>
    <span class="spacer"></span>
</mat-dialog-actions>
