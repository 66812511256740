<mat-toolbar>
    <h1 translate>vehicles.title</h1>
    <button mat-icon-button (click)="update()"><mat-icon>refresh</mat-icon></button>
    <button mat-icon-button [disabled]="rowsSelected.length == 0" [matMenuTriggerFor]="vehicleMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #statusMenu>
        <button (click)="setStatus('online')" mat-menu-item><i class="fa-solid fa-fw fa-check" style="color:green"></i><span translate>vstatus.online</span></button>
        <button (click)="setStatus('offline')" mat-menu-item><i class="fa-solid fa-fw fa-x" style="color: rgb(255, 162, 0)"></i><span translate>vstatus.offline</span></button>
        <button (click)="setStatus('management')" mat-menu-item><i class="fa-solid fa-fw fa-gear" style="color: brown"></i><span translate>vstatus.management</span></button>
      </mat-menu>
      <mat-menu #vehicleMenu>
        <button mat-menu-item [matMenuTriggerFor]="statusMenu">{{'commands.set_status'|translate}}</button>
        <button mat-menu-item matTooltip="{{'commands.unlock'|translate}}" color="primary" (click)="run('unlock')"><mat-icon><i class="fa-solid fa-lock-open"></i></mat-icon>{{'commands.unlock'|translate}}</button>
        <button mat-menu-item matTooltip="{{'commands.lock'|translate}}" color="" (click)="run('lock')"><mat-icon><i class="fa-solid fa-lock"></i></mat-icon>{{'commands.lock'|translate}}</button>
        <button mat-menu-item matTooltip="{{'commands.unlock_battery'|translate}}" color="" (click)="run('unlock_battery')"><mat-icon><i class="fa-solid fa-battery"></i></mat-icon>{{'commands.unlock_battery'|translate}}</button>
        <button mat-menu-item matTooltip="{{'commands.setmax'|translate}}" color="primary" (click)="run('setmax')"><mat-icon><i class="fa-solid fa-gauge"></i></mat-icon>{{'commands.setmax'|translate}}</button>
        <button mat-menu-item matTooltip="{{'commands.request_update'|translate}}" color="primary" (click)="run('request_update')"><mat-icon><i class="fa-solid fa-refresh"></i></mat-icon>{{'commands.request_update'|translate}}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item matTooltip="{{'op.delete'|translate}}" (click)="groupDelete()"><mat-icon>delete</mat-icon>{{'op.delete'|translate}}</button>
      </mat-menu>
    <span class="spacer"></span>
    <edway-vehicles-filter (change)="apply($event)"></edway-vehicles-filter>
    <!--<button mat-button [routerLink]="['../', 'models']"><span translate>vehicles.models</span></button>-->
    <span class="spacer"></span>
    <!--<button mat-icon-button><mat-icon>upload</mat-icon></button>-->
    <button mat-icon-button title="{{'ops.dl'|translate}}" (click)="download('https://api.hoponmobility.com/admin/data/vehicles.xlsx')"><mat-icon>download</mat-icon></button>
    <button mat-icon-button title="{{'ops.add'|translate}}"  (click)="openDialog()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<ag-grid-angular
    class="fullpage ag-theme-alpine"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    rowModelType="infinite"
    rowSelection="multiple"
    [columnDefs]="columnDefs"
    [headerHeight]="36"
    [rowHeight]="40"
    [paginationPageSize]="500"
    [cacheBlockSize]="500"
    [suppressFieldDotNotation]="true"
    [rowStyle]="getRowStyle"
    (selectionChanged)="selectRow($event)"
        
    (rowClicked)="onRowClicked($event)"
    (gridReady)="onGridReady($event)"

    >
</ag-grid-angular>