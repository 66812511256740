
import { NgModule } from '@angular/core';

//Angular Material Components
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { NgJsonEditorModule } from '@maaxgr/ang-jsoneditor'
import { VehiclePipe } from './vehicle.pipe';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BatteryCellRendererComponent } from './battery-cell-renderer/battery-cell-renderer.component';
import { VehicleCellRendererComponent } from './vehicle-cell-renderer/vehicle-cell-renderer.component';
import { BoolCellRendererComponent, ReverseBoolCellRendererComponent } from './bool-cell-renderer/bool-cell-renderer.component';
import { LogdataComponent } from './logdata/logdata.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';


import { TranslateModule } from '@ngx-translate/core';
import { ProtectDirective } from './protect.directive';
import { FleetPipe } from './fleet.pipe';
import { NgVerticalTimelineModule  } from 'ng-vertical-timeline';
import { ProductRendererComponent } from './product-renderer/product-renderer.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    VehiclePipe,
    BatteryCellRendererComponent,
    VehicleCellRendererComponent,
    BoolCellRendererComponent,
    ReverseBoolCellRendererComponent,
    LogdataComponent,
    ProtectDirective,
    FleetPipe,
    ProductRendererComponent,
    FileUploadComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatBadgeModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    NgJsonEditorModule,
    TranslateModule,
    MatDividerModule,
    NgVerticalTimelineModule,
    
  ],
  exports:[
    FormsModule,    
    ReactiveFormsModule ,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    NgJsonEditorModule,
    MatPaginatorModule,
    VehiclePipe,
    LogdataComponent,
    NgVerticalTimelineModule,
    MatTreeModule,
    FileUploadComponent
  ],
  providers: []
})
export class SharedModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustHtml('./assets/mdi.svg')
    );
  }
 }