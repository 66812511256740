import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'edway-vehicle-info-add',
  templateUrl: './vehicle-info-add.component.html',
  styleUrls: ['./vehicle-info-add.component.scss'],
})
export class VehicleInfoAddComponent implements OnInit {
  vmetas: any[];

  form_data = {
    vmeta_id: '',
    value: '',
    attachment_url: '',
    expiry_date: '',
    ordering: 0,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private e: EdwayService,
    public dialogRef: MatDialogRef<VehicleInfoAddComponent>
  ) {}

  ngOnInit() {
    this.e.getVMetas().subscribe((data: any[]) => {
      this.vmetas = data;
    });
  }

  save() {
    const current_vmeta = this.vmetas.find(
      (vmeta) => vmeta.name === this.form_data.vmeta_id
    );
    const post_data = {
      ...this.form_data,
      vehicle_uuid: this.data.vehicle_uuid,
    };

    if (post_data.expiry_date !== '') {
      post_data.expiry_date = new Date(post_data.expiry_date).toISOString();
    }

    if (!current_vmeta.has_expiration) delete post_data.expiry_date;

    this.e.addVehicleInfo(post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
