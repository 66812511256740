import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { DetailComponent } from './detail/detail.component';
import { WhAddComponent } from './wh-add/wh-add.component';
import { DocsComponent } from './docs/docs.component';
import { AddKeyComponent } from './add-key/add-key.component';
import { MessagingComponent } from './messaging/messaging.component';
import { GeofenceComponent } from './geofence/geofence.component';
import { InfoComponent } from './info/info.component';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    WhAddComponent,
    DocsComponent,
    AddKeyComponent,
    MessagingComponent,
    GeofenceComponent,
    InfoComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    SharedModule,
    TranslateModule,
    MatToolbarModule,
    MatTabsModule,
  ]
})
export class WebhooksModule { }
