<h1 mat-dialog-title>{{'exporter.title'|translate}}</h1>
<div mat-dialog-content class="content">
    <mat-tab-group>
        <mat-tab label="{{'exporter.period.label'|translate}}" *ngIf="fields?.timed">
            <mat-form-field>
                <mat-label >{{'exporter.period.description'|translate}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate [(ngModel)]="filter.start" placeholder="Start date">
                  <input matEndDate [(ngModel)]="filter.end" placeholder="End date">
                </mat-date-range-input>
                <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
        </mat-tab>
    <mat-tab label="Fields" *ngIf="fields?.fields.length>0">
    <mat-selection-list *ngIf="fields?.fields" [(ngModel)]="filter.fields">
        <mat-list-option [value]="field.name" *ngFor="let field of fields.fields">
          {{field.name}}
        </mat-list-option>
      </mat-selection-list> </mat-tab>   
    <mat-tab label="Related" *ngIf="fields?.related.length>0">
    <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let related of fields.related">
          <mat-expansion-panel-header >
            <mat-panel-title>
              {{related.source}}
            </mat-panel-title>
            <mat-panel-description>
              {{related.content}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-selection-list [(ngModel)]="filter.related">
            <mat-list-option value="{{related.source}}__{{f.name}}" *ngFor="let f of related.fields">
              {{f.name}}
            </mat-list-option>
          </mat-selection-list>   
        </mat-expansion-panel>
        </mat-accordion>
       </mat-tab>
    <mat-tab label="Debug">{{filter|json}}</mat-tab>
    

</mat-tab-group>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'op.cancel'|translate}}</button>
  <button mat-button (click)="setFilter()" cdkFocusInitial>Ok</button>
</div>
