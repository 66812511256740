import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-access-dialog',
    templateUrl: './redirect-dialog.component.html',
    styleUrls: ['./redirect-dialog.component.scss']
})
export class RedirectDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<RedirectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { from: string, to: string, options: string[] },
    ) { }

    ngOnInit(): void {

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    submit(): void {
        this.dialogRef.close(this.data);
    }
}
