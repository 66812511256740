<mat-toolbar>
    <h1 translate>discovery.title</h1>
    <button mat-icon-button (click)="update()"><mat-icon>refresh</mat-icon></button>
    <button mat-icon-button [disabled]="rowsSelected.length == 0" [matMenuTriggerFor]="vehicleMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #vehicleMenu>
        <button disabled (click)="setStatus('online')" mat-menu-item><i class="fa-solid fa-fw fa-check" style="color:green"></i><span translate>dstatus.online</span></button>
        <button disabled (click)="setStatus('offline')" mat-menu-item><i class="fa-solid fa-fw fa-x" style="color: rgb(255, 162, 0)"></i><span translate>dstatus.offline</span></button>
      </mat-menu>
    <span class="spacer"></span>
    <edway-vehicles-filter (change)="apply($event)"></edway-vehicles-filter>
    <!--<button mat-button [routerLink]="['../', 'models']"><span translate>vehicles.models</span></button>-->
    <span class="spacer"></span>
    <!--<button mat-icon-button><mat-icon>upload</mat-icon></button>-->
    <button mat-icon-button title="{{'ops.dl'|translate}}" (click)="download('https://api.hoponmobility.com/admin/data/devices.xlsx')"><mat-icon>download</mat-icon></button>
    <button mat-icon-button title="{{'ops.add'|translate}}"  (click)="openDialog()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<ag-grid-angular
    class="fullpage ag-theme-alpine"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    rowModelType="infinite"
    rowSelection="multiple"
    [columnDefs]="columnDefs"
    [headerHeight]="36"
    [rowHeight]="40"
    [paginationPageSize]="500"
    [cacheBlockSize]="500"
    [suppressFieldDotNotation]="true"
    [rowStyle]="getRowStyle"
    (selectionChanged)="selectRow($event)"
    (rowClicked)="onRowClicked($event)"
    (gridReady)="onGridReady($event)"
    >
</ag-grid-angular>