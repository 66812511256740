import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { DownloadService } from 'src/app/download.service';
import { EdwayDatasource } from 'src/app/edway-datasource';
import { EdwayService } from 'src/app/edway.service';
import { DateUtilService } from 'src/app/services/date-util.service';
import { DetailComponent } from '../detail/detail.component';

@Component({
  selector: 'edway-issues-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [EdwayDatasource]
})
export class ListComponent implements OnInit {
  @Input() user: string;
  @Input() vehicle: string;

  filter: any={}

  public defaultColDef: ColDef = {
    editable: false,
    suppressMovable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };

  
  private gridColumnApi!: ColumnApi;
  private gridApi!: GridApi;

  columnDefs: ColDef[] = [];

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private ds: EdwayDatasource,
    private modal: MatDialog,
    private tr: TranslateService,
    private downloadService: DownloadService,
    private dateUtil: DateUtilService
  ) {}

  update(triggered){
    
    this.show = false;
    if (triggered) {
      this.gridApi.purgeInfiniteCache();
    }
  }

  async ngOnInit() {
    
    const op = {};
    if (this.user){
      op['user'] = this.user;
    }
    if (this.vehicle){
      op['vehicle'] = this.vehicle;
    }

    await this.tr.get('dummyTranslation').toPromise().then();
    this.ds.setUrl(this.e.endpoints.issues);

    let ret = [];
    this.ds.setFilter(op)

    this.columnDefs = [
      {
        field: 'user',
        headerName: this.tr.instant('issues.user'),
      },
      { field: 'vehicle', headerName: this.tr.instant('issues.vehicle') },
      { field: 'signaling', headerName: this.tr.instant('issues.signaling'), valueFormatter: this.signalingFormat},
      {
        field: 'signaled',
        headerName: this.tr.instant('issues.signaled'),
        filter: 'agDateColumnFilter',
        valueFormatter: (v) => this.dateUtil.dateTableFormatter(v, 'dd/MM/yyyy')
      },
      { field: 'issue_type', headerName: this.tr.instant('issues.issue_type') },
    ];
 
  }

  signalingFormat(param){
    if(param.value){
      return param.value==="automatic"?"🤖":"👦🏻";
    } else {
      return "";
    }
  }

  
  goToPage(page){
    this.e.getUsers(page.pageIndex + 1)
  }

  onRowClicked(ev) {
    console.log('log', ev);
    this.modal.open(DetailComponent, {
      data: ev.data,
    });
  }

  download(url){
     this.downloadService.download(url, {headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}}, "issues")
    .subscribe(() => {});
  }

  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api!.setDatasource(this.ds);
  }
}
