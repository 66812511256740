
<mat-toolbar>
    <h1 translate>fleets.require_activation.title</h1>
    <span class="spacer"></span>
    <button mat-icon-button title="{{'ops.qrcode'|translate}}" (click)="showQR()"><i class="fa fa-fw fa-qrcode"></i></button>
    <button mat-icon-button title="{{'ops.add'|translate}}" (click)="openDialog()"><mat-icon>add</mat-icon></button>
</mat-toolbar>

<table>
    <tr>
        <th translate>fleets.require_activation.user</th>
        <th translate>fleets.require_activation.access_request</th>
        <th translate>fleets.require_activation.accpted</th>
        <th translate>fleets.require_activation.access_limit</th>
    </tr>
    <tr *ngFor="let acc of permissions">
        <td>{{acc.user}}</td>
        <td>{{acc.request|date}}</td>
        <td>
            <button mat-raised-button mat-button (click)="openDialog(acc)" *ngIf="!acc.accepted">Accetta</button>
            <button mat-raised-button mat-button *ngIf="acc.accepted">Annulla</button>
        </td>
        <td>{{acc.until|date}}</td>
    </tr>
</table>