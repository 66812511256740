<mat-toolbar>
    <h1>{{'dashboard.packages'|translate}}</h1></mat-toolbar>

<ag-grid-angular  *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [headerHeight]="36"
    [rowHeight]="40"
    (rowClicked)="onRowClicked($event)"
    >
</ag-grid-angular>