import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { BoolCellRendererComponent } from 'src/app/bool-cell-renderer/bool-cell-renderer.component';
import { EdwayService } from '../../edway.service';
import { EditComponent } from '../edit/edit.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  mode = 'fleets';

  show = false;
  lst: any[] = [];
  gridApi: GridApi;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    public dialog: MatDialog,
    private tr: TranslateService

  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    let ret = [];
    this.ar.paramMap.subscribe(map=>{
      this.mode = map.get('mode');
      if(this.mode === 'fleets'){
        this.columnDefs = [
          { width: 200, field: 'name', headerName: this.tr.instant('fields.fleet.name.label'), headerTooltip: this.tr.instant('fields.fleet.name.label')},
          { filter: false, maxWidth: 65, headerTooltip: this.tr.instant('fields.fleet.active.description'), headerName: "✔", field: 'active', cellRenderer: BoolCellRendererComponent },
          { filter: false, maxWidth: 65, headerTooltip: this.tr.instant('fields.fleet.exclusive.description'), headerName: "✨", field: 'exclusive', cellRenderer: BoolCellRendererComponent },
          { filter: false, maxWidth: 65, headerTooltip: this.tr.instant('fields.fleet.require_activation.description'), headerName: "✋", field: 'require_activation', cellRenderer: BoolCellRendererComponent },
          { filter: false, maxWidth: 65, headerTooltip: this.tr.instant('fields.fleet.freefloating.description'), headerName: "☁", field: 'freefloating', cellRenderer: BoolCellRendererComponent },
          { filter: false, maxWidth: 100, headerName: this.tr.instant('fields.fleet.payment_mode.label'), headerTooltip: this.tr.instant('fields.fleet.payment_mode.description'), field: 'payment_mode' },
          { filter: false, maxWidth: 200, headerName: this.tr.instant('fields.fleet.payments_accepted.label'), headerTooltip: this.tr.instant('fields.fleet.payments_accepted.description'), field: 'payments_accepted' },
        ];
      } else {
        this.columnDefs = [
          { filter: false, maxWidth: 55, headerTooltip: this.tr.instant('fields.fleet.active.description'), headerName: "✔", field: 'active', cellRenderer: BoolCellRendererComponent },
          { maxWidth: 300, field: 'id', headerName: this.tr.instant('fields.fleet.id.label'), headerTooltip: this.tr.instant('fields.fleet.name.id')},
          { width: 200, field: 'name', headerName: this.tr.instant('fields.fleet.name.label'), headerTooltip: this.tr.instant('fields.fleet.name.label')},
        ];
      }
      this.update();
    })
  }

  update(){
    this.show = false;
    this.e.getFleets().subscribe((data: any[]) => {
      this.lst = data;
      this.show = true;
    })

  }

  onRowClicked(ev){
    this.r.navigate(["fleets", ev.data.id, {mode: this.mode}]);
  }

  addFleet(mode="fleet"){
    const dialogRef = this.dialog.open(EditComponent, {
      width: '250px',
      data: {op: "Add", fleets: this.lst, mode: mode}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result.name, result.parent);
        this.e.addFleet(result.name, result.parent).subscribe(data=>{
          this.update();
        });
      }
    });
  }
  addGeofence(){
    this.addFleet("geofence");
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  dl(){
      if (this.gridApi) {
        this.gridApi.exportDataAsCsv();
      }
    }  

}
