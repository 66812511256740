<div class="reset-wrapper">
  <div class="auth-container">
    <mat-card class="auth-card">
      <mat-card-header>
        <mat-card-title>Reset</mat-card-title>
      </mat-card-header>
      <form>
        <mat-card-content>
          <mat-form-field class="auth-input-field">
            <input matInput [disabled]="status==='post'" #username placeholder="Username/Phone">
          </mat-form-field>
          <mat-form-field class="auth-input-field">
            <input matInput [disabled]="status==='post'" #password type="password" placeholder="New Password">
          </mat-form-field>
          <mat-form-field [ngStyle]="{'display':status === 'pre'?'none':'block'}" class="auth-input-field">
            <input matInput #onetimepass type="text" placeholder="OTP">
          </mat-form-field>
        </mat-card-content>
        <button [ngStyle]="{'display':status === 'post'?'none':'block'}" (click)="start_reset(username.value)"
          mat-stroked-button color="primary" class="auth-button">Get OTP</button>
        <button [ngStyle]="{'display':status === 'pre'?'none':'block'}"
          (click)="pw_reset(username.value, onetimepass.value, password.value)" mat-stroked-button color="primary"
          class="auth-button">Reset Password</button>
      </form>
      <a routerLink="/login" mat-button class="auth-secondary-link">Login</a>
    </mat-card>
  </div>
</div>
