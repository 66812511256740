<mat-toolbar>
    <h2 translate>vehicles.detail.model_info</h2>
    <span class="spacer"></span>
    <!--<button mat-icon-button (click)="editModel(static.model.id)"><mat-icon>edit</mat-icon></button>-->
  </mat-toolbar>
  <div class="row">

      
      <table mat-table [dataSource]="data" style="width:100%">
          
          <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
            
            <!-- Position Column -->
            <ng-container matColumnDef="key">
                <th mat-cell *matCellDef="let element" translate> vehicles.detail.{{element.key}} </th>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="value">
                <td mat-cell *matCellDef="let element"> {{element.value}} </td>
            </ng-container>
            
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>