import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  fleets: any;

  data: any = {
    email:null,
  };
  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<EditComponent>,
  ) { }

  ngOnInit(): void {
    this.e.getFleets().subscribe(data=>{
      this.fleets = data;
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  createUser(){
    const ud = {
      email: this.data.email,
      password: this.data.password,
      phone_number: this.data.phone_number,
      birth_date: this.data.birth_date,
      first_name: this.data.first_name,
      family_name: this.data.family_name
    }
    this.e.createUser(ud).subscribe(data=>{
      console.log(data);
      if (this.data.is_mgr){
        this.e.addManager({
          user:this.data.email, 
          fleet:this.data.fleet
        }).subscribe(d =>{
          console.log(d);
        })
      }
      this.onNoClick()
    })
  }

}
