import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-rental-status-renderer',
  templateUrl: './rental-status-renderer.component.html',
  styleUrls: ['./rental-status-renderer.component.scss']
})
export class RentalStatusRendererComponent implements ICellRendererAngularComp  {
  private params!: ICellRendererParams;
  public text!: string;
  public style!: any;
  public value!: string;
  public imgForMood!: string;

  constructor() { }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setMood(params);  }

  private setMood(params: ICellRendererParams) {
    console.log(params);
    if(params.node.data){
      this.text = params.node.data.status;
      if (this.text === 'cancel')
        this.value = 'cancel';
      else {
        if(params.node.data.end){
          const paid = params.node.data.penalties.filter(x=>x.stripe_confirmed).length;
          const unpaid = params.node.data.penalties.filter(x=>!x.stripe_confirmed).length;
          if (paid == 0){
            this.value = 'unpaid';
            if (params.node.data.payment){
              if (params.node.data.payment.amount == 0){
                this.value = "nopay";
              }
            } 
          } else {
            if (unpaid == 0)
              this.value = 'paid';
            else{
              this.value = "partial";
            }
          }
        }else {
          if (this.text = "start_pending")
            this.value = "start_pending"
          else 
            this.value = 'ongoing';
        }
      }
    }
  }
}
