<mat-toolbar>
  <h2>Experience</h2>
</mat-toolbar>

<mat-tab-group>
  <mat-tab>
    <form>
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input
          matInput
          placeholder="Nome dell'esperienza"
          [(ngModel)]="experience.name"
          name="name"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>POIs</mat-label>
        <input
          matInput
          placeholder="Elenco di POIs (testuale)"
          [(ngModel)]="experience.pois"
          name="pois"
        />

        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Locate"
          (click)="addPoi()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Descrizione</mat-label>
        <textarea
          matInput
          placeholder="Descrizione dell'esperienza"
          [(ngModel)]="experience.description"
          name="description"
        ></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tags</mat-label>
        <input
          matInput
          placeholder="Elenco di Tags"
          [(ngModel)]="experience.tags"
          name="tags"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Galleria</mat-label>
        <input
          matInput
          placeholder="Galleria dell'esperienza (JSON)"
          [(ngModel)]="experience.gallery"
          name="gallery"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Prezzo</mat-label>
        <input
          matInput
          placeholder="Prezzo dell'esperienza"
          [(ngModel)]="experience.price"
          name="price"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Durata</mat-label>
        <input
          matInput
          placeholder="Durata dell'esperienza (hh:mm:ss)"
          [(ngModel)]="experience.duration"
          name="duration"
        />
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="save()">Salva</button>
    </form>
  </mat-tab>
</mat-tab-group>
