import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'edway-vehicles-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  filter;
  filterSet = false;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.filter = {
      "type":["ebike", "moped", "scooter", "car"],
      "maxBattery": 100
    };
  }

  update(ev){
    console.log(ev);
    console.log(this.filter);
    this.filterSet = true;
    this.change.emit(this.filter);
  }

  clear(){
    this.filter = {
      "type":["ebike", "moped", "scooter", "car"],
      "maxBattery": 100
    };
    this.filterSet = false;
    this.change.emit(this.filter);
  }

}
