<h1 mat-dialog-title>Attiva Coupon</h1>
<div mat-dialog-content>
    <p>Attiva un nuovo coupon</p>
    <div class="column">
        <mat-form-field appearance="fill">
            <mat-label>Utente</mat-label>
            <input matInput [(ngModel)]="user" name="name">
        </mat-form-field>
        <p [(ngModel)]="result"></p>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button (click)="submit()" cdkFocusInitial>Applica Coupon</button>
</div>