<mat-toolbar>
  <h1 *ngIf="mode === 'fleets'"><span translate>fleets.title</span> - {{fleet.name}}</h1>
  <h1 *ngIf="mode === 'geofences'"><span translate>geofences.title</span> - {{fleet.name}}</h1>
  <span class="spacer"></span>
  <!--<button mat-icon-button [disabled]="!auth.canDo('fleet.managers')" (click)="addManager(fleet.id)"
    matTooltip="{{'ops.managers'|translate}}">
    <mat-icon><i class="fa-solid fa-xs fa-user"></i></mat-icon>
  </button>
  <button mat-icon-button [disabled]="!auth.canDo('fleet.edit')" (click)="editFleet(fleet.id)"
    matTooltip="{{'ops.edit'|translate}}">
    <mat-icon>edit</mat-icon>
  </button>-->
  <button mat-icon-button [disabled]="!auth.canDo('fleet.delete')" (click)="deleteFleet(fleet.id)"
    matTooltip="{{'ops.delete'|translate}}">
    <mat-icon>delete</mat-icon>
  </button>
</mat-toolbar>


<div class="row">
  <mat-tab-group id="tabs">
    <mat-tab *ngIf="mode === 'fleets'" label="{{'fleets.settings'|translate}}">
      <ng-template matTabContent>
        
        <div class="row">
          <div style="max-width:400px;">
            
                        <!--<div *ngIf="auth.canDo('fleet.change.payment_mode')">
                          <mat-toolbar>
                            <span translate>fleets.general_settings.payment_mode</span>
                            <span class="spacer"></span>-->
                            <!--<button mat-icon-button (click)="managers(fleet.id)" title="Managers"><mat-icon>edit</mat-icon></button>-->
                          <!--</mat-toolbar>
                          <mat-button-toggle-group class="fill" [(ngModel)]="fleet.payment_mode">
                            <mat-button-toggle *ngFor="let fpa of ['user_pays','fleet_forfait']" [value]="fpa" >{{'fleets.general_settings.payment_modes.'+fpa|translate}}</mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>-->
            <mat-toolbar>
              <span translate>fleets.general_settings.title</span>
              <span class="spacer"></span>
              <!--<button mat-icon-button (click)="managers(fleet.id)" title="Managers"><mat-icon>edit</mat-icon></button>-->
            </mat-toolbar>
            <table>
              <tr>
                <td colspan="2">
                  <mat-slide-toggle matTooltip="{{'fleets.general_settings.active'|translate}}" (change)="toggleSetting($event, fleet.id, 'active')"
                    [disabled]="!auth.canDo('fleet.activate', fleet)" [checked]="fleet?.active">{{'fleets.general_settings.active'|translate}}
                  </mat-slide-toggle>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <mat-slide-toggle matTooltip="{{'fleets.general_settings.freefloating'|translate}}"
                    (change)="toggleSetting($event, fleet.id, 'freefloating')"
                    [disabled]="!auth.canDo('fleet.freefloating', fleet)" [checked]="fleet?.freefloating">{{'fleets.general_settings.freefloating'|translate}}
                  </mat-slide-toggle>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <mat-slide-toggle matTooltip="{{'fleets.general_settings.require_activation'|translate}}"
                    (change)="toggleSetting($event, fleet.id, 'require_activation')"
                    [disabled]="!auth.canDo('fleet.require_activation', fleet)" [checked]="fleet?.require_activation">{{'fleets.general_settings.require_activation'|translate}}</mat-slide-toggle>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <mat-slide-toggle matTooltip="{{'fleets.general_settings.exclusive'|translate}}"
                    (change)="toggleSetting($event, fleet.id, 'exclusive')"
                    [disabled]="!auth.canDo('fleet.exclusive', fleet)" [checked]="fleet?.exclusive" >{{'fleets.general_settings.exclusive'|translate}}
                  </mat-slide-toggle>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <mat-slide-toggle matTooltip="{{'fleets.general_settings.require_photo'|translate}}"
                    (change)="toggleSetting($event, fleet.id, 'require_photo')"
                    [disabled]="!auth.canDo('fleet.require_photo', fleet)" [checked]="fleet?.require_photo">{{'fleets.general_settings.require_photo'|translate}}
                    </mat-slide-toggle>
                </td>
              </tr>
            </table>

            <div>
              <mat-toolbar>
                <span translate>fleets.general_settings.accepted</span>
                <span class="spacer"></span>
                <!--<button mat-icon-button (click)="managers(fleet.id)" title="Managers"><mat-icon>edit</mat-icon></button>-->
              </mat-toolbar>
              <div class="column">
                <mat-slide-toggle class="row" *ngFor="let fpa of ['card','wallet', 'package']" [checked]="fleet?.payments_accepted.indexOf(fpa)>=0"> {{'fleets.general_settings.accept.'+fpa|translate}}</mat-slide-toggle>
              </div>
            </div>
          </div>
          <div style="flex: 1 1 10px">
            <mat-toolbar>
              <span translate>fleets.bots.title</span>
              <span class="spacer"></span>
              <!--<button mat-icon-button *ngIf="canDo('dev')" (click)="add_telegram(fleet.id)" title="Add Telegram channel"><mat-icon>add</mat-icon></button>-->
            </mat-toolbar>
            <table>
              <tr>
                <th style="width: 36px" translate>fleets.bots.type.label</th>
                <th style="width: auto;" translate>fleets.bots.link.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.silent.description'|translate}}" translate>fleets.bots.silent.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.trip_start.description'|translate}}" translate>fleets.bots.trip_start.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.trip_end.description'|translate}}" translate>fleets.bots.trip_end.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.validate_docs.description'|translate}}" translate>fleets.bots.validate_docs.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.alert.description'|translate}}" translate>fleets.bots.alert.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.acquire_package.description'|translate}}" translate> fleets.bots.acquire_package.label</th>
                <th style="width:65px; text-align: center;" title="{{'fleets.bots.acquire_wallet.description'|translate}}" translate> fleets.bots.acquire_wallet.label</th>
              </tr>
              <tr *ngFor="let g of fleet?.groups">
                <td><i class="fa fa-fw fa-xl fa-brands fa-{{g.channel_type}}"></i></td>
                <td><a target="_blank" href="{{g.channel_url}}">{{g.channel_name}}</a></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'slient')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.slient"></mat-slide-toggle></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'trip_start')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.trip_start"></mat-slide-toggle></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'trip_end')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.trip_end"></mat-slide-toggle></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'validate_docs')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.validate_docs"></mat-slide-toggle></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'alert')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.alert"></mat-slide-toggle></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'acquire_package')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.acquire_package"></mat-slide-toggle></td>
                <td><mat-slide-toggle (change)="toggleChannel($event,g.id,'acquire_wallet')" [disabled]="!canDo('edit_bots', fleet.id)" [checked]="g.acquire_wallet"></mat-slide-toggle></td>
              </tr>
            </table>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'fleets.require_activation.tab'|translate}}" *ngIf="mode === 'fleets' && fleet?.require_activation">
      <ng-template matTabContent>
        <edway-fleet-access [fleet]="fleet.id"></edway-fleet-access>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'fleets.areas'|translate}}">
      <ng-template matTabContent>
        <edway-fleet-areas (triggerUpdate)="update()" [fleet]="fleet" [pagemode]="mode"></edway-fleet-areas>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'fleets.settings'|translate}}">
    <ng-template matTabContent>
      <mat-toolbar>
        <h1>{{'fleets.settings'|translate}}</h1>
        <span class="spacer"></span>
        <button mat-icon-button (click)="openAddFleetSetting()"><mat-icon>add</mat-icon></button>
      </mat-toolbar>
      <table>
        <tr *ngFor="let kv of fleet.settings">
          <td><button mat-icon-button (click)="deleteFleetSetting(fleet.id, kv.key)"><i class="fa-solid fa-trash"></i></button></td>
          <th>{{kv.key}}</th>
          <td>{{kv.value}}</td>
        </tr>
      </table>
    </ng-template>
  </mat-tab>
  </mat-tab-group>
</div>
