import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { LogoutComponent } from './logout/logout.component';


import { AngularTelegramLoginWidgetModule } from "angular-telegram-login-widget";
import { ResetComponent } from './reset/reset.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ResetComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    HttpClientModule,
    
    AngularTelegramLoginWidgetModule
  ],
  providers:[{ 
    provide: HTTP_INTERCEPTORS, 
    useClass: AuthInterceptor , 
    multi: true 
  }]
})
export class AuthModule { }
