import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-ian-add',
  templateUrl: './ian-add.component.html',
  styleUrls: ['./ian-add.component.scss'],
})
export class IanAddComponent implements OnInit {
  form_data = {
    title: '',
    content: '',
  };

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<IanAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  save() {
    const post_data = {
      ...this.form_data,
      user: this.data.target,
    };

    if (!this.form_data.title || !this.form_data.content) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.addIan(post_data).subscribe((data) => {
      this.dialogRef.close(data);
      window.location.reload();
    });
  }
}
