<mat-toolbar *ngIf="rental">
    Noleggio - {{rental.id}}
    <button mat-icon-button matTooltip="{{'rentals.pause'|translate}}" *ngIf="rental.end===null" (click)="pauseRental(rental)" title="Chiudi Noleggio"><mat-icon>pause</mat-icon></button>
    <button mat-icon-button matTooltip="{{'rentals.stop'|translate}}" *ngIf="rental.end===null" (click)="closeRental(rental)" title="Chiudi Noleggio"><mat-icon>stop</mat-icon></button>
    <button mat-icon-button matTooltip="{{'rentals.force_stop'|translate}}" style="color:red" *ngIf="rental.end===null" (click)="closeRentalForce(rental)" title="Chiudi Noleggio Forzando"><mat-icon>stop</mat-icon></button>
    <span class="spacer"></span>
    <button (click)="openPenalties()" mat-icon-button matTooltip="{{'rentals.penalties'|translate}}"><mat-icon>add</mat-icon></button>
    <a mat-icon-button  matTooltip="{{'rentals.download'|translate}}" href="//api.hoponmobility.com/admin/rentals/{{rental.id}}/print" target="_blank"><mat-icon>download</mat-icon></a>
</mat-toolbar>
<table  *ngIf="rental">
    <tr><th>User:</th><td>{{rental.user}} <a [href]="'../users/' + rental.user" >Go</a></td></tr>
    <tr><th>Start Time:</th><td>{{rental.start|date:'d/M/yy, h:mm a'}}</td></tr>
    <tr><th>End Time:</th><td>{{rental.end|date:'d/M/yy, h:mm a'}}</td></tr>
    <tr><th>Vehicle:</th><td><a href="/vehicles/{{rental.vehicle}}">{{rental.vehicle|vehicle}}</a></td></tr>
</table>
<mat-toolbar *ngIf="rental">
    Info
    <span class="spacer"></span>
    <button mat-icon-button [disabled]="rental.foto.length<2" (click)="show(rental.foto[1])" matTooltip="Foto precedente"><mat-icon>photo</mat-icon></button>
    <button mat-icon-button [disabled]="rental.foto.length==0" (click)="show(rental.foto[0])" matTooltip="Foto"><mat-icon>photo</mat-icon></button>
</mat-toolbar>
<div class="row">
<div id="rental_map"></div>
<mat-tab-group id="rental_info" *ngIf="rental">
    <mat-tab label="Log">
        <ng-template matTabContent>
                <div id="log">
                    <table style="overflow:auto">
                        <tr><th></th><th>timestamp</th><th>event</th><th>🔋</th><th>💨</th></tr>
                        <tr *ngFor="let log of events" (click)="onRowClicked(log)" (mouseenter)="onRowHover(log)" (mouseout)="onRowHover(null)">
                            <td>
                                <i class="fa-solid fa-user" *ngIf="log.tag === 'rental'"></i>
                                <i class="fa-solid fa-gears" *ngIf="log.logtype === 'rawlog'"></i>
                                <i class="fa-regular fa-rectangle-list" *ngIf="log.logtype === 'usercommand'"></i>
                                <i class="fa-regular fa-play" *ngIf="log.logtype === 'command'"></i>
                            </td>
                            <td>{{log.timestamp|date:'d/M/yy h:mm a'}}</td>
                            <td>{{log.logtype}}</td>
                            <td *ngIf="log.logtype==='rawlog'">{{log.data['scooter.battery.level']}}</td>
                            <td *ngIf="log.logtype==='rawlog'">{{log.data['position.speed']}}</td>
                            <td *ngIf="log.logtype==='usercommand'" colspan="2">{{log.data['command']}}</td>
                            <td *ngIf="log.logtype==='command'" colspan="2">{{log.data['command']}}</td>
                        </tr>
                    </table>
                </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Transactions" >
        <edway-payments-list [trip]="rental?.id"></edway-payments-list>
    </mat-tab>
    <mat-tab label="Feedback">
        <edway-polls-list [trip]="rental?.id"></edway-polls-list>
    </mat-tab>
</mat-tab-group></div>