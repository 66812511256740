import { from, Observable, of } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';

import { CouponsService } from '../coupons.service';
import { Coupon } from '../models';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { CouponApplication } from '../models/coupon-application.model';
import { DateUtilService } from 'src/app/services/date-util.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-coupons-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserCouponListComponent implements OnInit {
  @Input() user: string;
  @Input() model: string;
  coupons$: Observable<CouponApplication[]>;
  columnDefs$: Observable<ColDef[]> ;
  immutableData: boolean;
  gridAPI: GridApi;

  constructor(
    private readonly couponsService: CouponsService, 
    public dialog: MatDialog, 
    private dateUtil: DateUtilService,
    private tr: TranslateService
    ) {
  }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    this.coupons$ = this.couponsService.getCouponsActivations({ user: this.user, model: this.model});
    this.columnDefs$ = of([
      { headerName: this.tr.instant('coupons.fields.user'), field: 'user', filter: 'agTextColumnFilter' },
      { headerName: this.tr.instant('coupons.fields.code'), field: 'code', filter: 'agTextColumnFilter' },
      { headerName: this.tr.instant('coupons.fields.template'), field: 'model', filter: 'agTextColumnFilter' },
      { headerName: this.tr.instant('coupons.fields.used'), field: 'appliedAt', valueFormatter: (v) => this.dateUtil.dateTableFormatter(v, 'dd/MM/yyyy HH:mm') },
    ]);
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridAPI = params.api;
    params.api.sizeColumnsToFit();
  }

  refresh(){
    this.coupons$ = this.couponsService.getCouponsActivations({ user: this.user, model: this.model});
  }

  public redrawUI(): void {
    if (!this.gridAPI) {
      return;
    }

    this.gridAPI.sizeColumnsToFit();
    this.gridAPI.redrawRows();
  }

  public getRowId(params: GetRowIdParams): string {
    return params.data.code + '-' + params.data.user;
  }

}
