<mat-toolbar>
  <input mat-input type="date" />
  <input mat-input type="date" />
  <button mat-button>Filter</button>
  <span class="spacer"></span>
  <mat-paginator [pageSize]="25" [pageIndex]="1"></mat-paginator>
</mat-toolbar>
<mat-list>
  <mat-list-item *ngFor="let li of rentals"
    >{{ li.logtype }} - {{ li.timestamp | date : "medium" }} -
    <button mat-icon-button (click)="show(li.data)">
      <mat-icon>info</mat-icon>
    </button></mat-list-item
  >
</mat-list>
