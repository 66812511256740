
<input type="file" class="file-input"
       (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">

   {{value || "upload"|translate}}

   <button [disabled]="status !== 'ready'" mat-mini-fab color="primary" class="upload-btn"
   (click)="fileUpload.click()">
     <mat-icon>attach_file</mat-icon>
 </button>
 <button *ngIf="status!=='ready'" mat-mini-fab color="accent" class="upload-btn"
      (click)="delete()">
        <mat-icon>delete</mat-icon>
    </button>
</div>