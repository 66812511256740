import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererComp,
  ICellRendererParams,
  IDetailCellRendererParams,
  IsFullWidthRowParams,
  RowHeightParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { AddComponent } from '../add/add.component';
import { DeleteComponent } from '../delete/delete.component';
import { DetailComponent } from '../detail/detail.component';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/app/cache.service';
import { BoolCellRendererComponent } from 'src/app/bool-cell-renderer/bool-cell-renderer.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  @Input() pricings: string;

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private d: MatDialog,
    private ar: ActivatedRoute,
    private tr: TranslateService,
    private g: MatSnackBar,
    private c: CacheService,
  ) {}

  update(){
    this.show = false;
    this.e.getPricing().subscribe((data: any[]) => {
      this.list = data;
      this.show = true;
    });
  }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    let ret = [];
    this.update();

    this.columnDefs = [
      {
        headerName: this.tr.instant('pricing.actions'),
        field: 'actions',
        filter: false,
        maxWidth: 75,
        cellRenderer: DeleteComponent,
        cellRendererParams: {
          buttons: [
            {
              label: 'op.delete',
              icon: 'delete',
              condition: () => {
                return true;
              },
              clicked: (params: any) => {
                if (confirm('Confirm?')) {
                  this.e.deletePricing(params.id).subscribe((_) => {
                    this.g.open('Deleted', null, {duration: 500});
                    this.update();
                  });
                }
                return false;
              },
            },
            // {
            //   label: 'op.disable',
            //   icon: 'x',

            //   condition: (params: any) => {
            //     return params.active;
            //   },
            //   clicked: (params: any) => {
            //     this.e.deactivate(params.id).subscribe((_) => {
            //       console.log('disabled');
            //     });
            //   },
            // },
            // {
            // label: "op.enable",
            // icon: "check",
            // condition: (params: any) =>{
            //   return !params.active;
            // },
            // clicked: (params: any) => {
            //   this.e.deactivate(params.id).subscribe(_=>{
            //     console.log('enabled')
            //   })
            // }
            // }
          ],
        },
      },
      {
        headerName: this.tr.instant('pricing.active'),
        field: 'active',
        cellRenderer: BoolCellRendererComponent,
      },
      {
        headerName: this.tr.instant('pricing.detail.vtype'),
        field: 'vtype',
      },
      {
        headerName: this.tr.instant('pricing.detail.model'),
        field: 'model.model_name',
      },
      {
        headerName: this.tr.instant('pricing.detail.middleware'),
        field: 'model.middleware',
      },
      {
        headerName: this.tr.instant('pricing.detail.fleet'),
        field: 'fleet',
        valueFormatter: (params)=>{
          return this.c.get('fleets', params.value);
        }
      },
      {
        headerName: this.tr.instant('pricing.currency'),
        field: 'currency',
      },
      {
        headerName: this.tr.instant('pricing.detail.max_daily'),
        field: 'max_cost',

        valueFormatter: currencyFormatter,
      },
      {
        headerName: this.tr.instant('pricing.detail.booking'),
        field: 'booking',
        valueFormatter: currencyFormatter,
      },
      {
        headerName: this.tr.instant('pricing.detail.unlock'),
        field: 'unlock',
        valueFormatter: currencyFormatter,
      },
      {
        headerName: this.tr.instant('pricing.per_km'),
        field: 'minutes',
        valueFormatter: modeFormatter,
      },
      {
        headerName: this.tr.instant('pricing.per_min'),
        field: 'kms',
        valueFormatter: modeFormatter,
      },
    ];
  }

  onRowClicked(ev) {
    this.r.navigate(['pricing', ev.data.id]);
  }

  addPricing() {
    const dialogRef = this.d.open(AddComponent);
    dialogRef.afterClosed().subscribe((ret) => {
      if (ret) {
        this.e.getPricing().subscribe((data: any[]) => {
          this.list = data;
          this.show = true;
        });
      }
    });
  }
}

function currencyFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return '€ ' + params.value.toFixed(2);
  } else {
    return null;
  }
}
function modeFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return params.value.length > 0 ? '✔' : '❌';
  } else {
    return null;
  }
}
