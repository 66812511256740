import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { AddMinutesComponent } from '../add-minutes/add-minutes.component';
import { DeleteComponent } from '../delete/delete.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, ICellRendererAngularComp {
  public data: any;
  public flag!: string;
  priceId: string;
  show = true;

  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  constructor(
    private ar: ActivatedRoute,
    private e: EdwayService,
    private d: MatDialog,
    private tr: TranslateService
  ) {}

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.data = params.node.data;
    this.flag = `https://www.ag-grid.com/example-assets/large-flags/${this.data.code}.png`;
  }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.ar.params.subscribe((data) => {
      this.priceId = data.id;
      this.load();
    });

    this.columnDefs = [
      {
        field: 'starts_at',
        headerName: this.tr.instant('pricing.detail.starts_at'),
      },
      {
        field: 'usage_per_minute',
        headerName: this.tr.instant('pricing.detail.usage_per_minute'),
        valueFormatter: currencyFormatter,
      },
      {
        field: 'pause_per_minute',
        headerName: this.tr.instant('pricing.detail.pause_per_minute'),
        valueFormatter: currencyFormatter,
      },
      //{ field: 'max_cost', headerName:'Max daily price', valueFormatter: currencyFormatter },
      //{ field: 'booking', headerName:'Booking price', valueFormatter: currencyFormatter},
      //{ field: 'unlock', headerName:'Unlock price', valueFormatter: currencyFormatter },
      //{ field: 'minutes', headerName:'Paid by min', valueFormatter: modeFormatter  },
      //{ field: 'kms', headerName:'Paid by km', valueFormatter: modeFormatter },
      {
        field: 'actions',
        filter: false,
        cellRenderer: DeleteComponent,
        cellRendererParams: {
          buttons: [
            {
              label: 'op.edit',
              icon: 'edit',
              condition: () => {
                return true;
              },
              clicked: (params: any) => {
                this.editMinuteRange(params);
              },
            },
          ],
        },
        headerName: this.tr.instant('pricing.actions'),
      },
    ];
  }

  load() {
    this.e.getPricing().subscribe((pdata: any[]) => {
      this.data = pdata.filter((x) => x.id == this.priceId)[0];
    });
  }

  addKmRange() {}

  addMinuteRange() {
    const dr = this.d.open(AddMinutesComponent, {
      data: { pricing: this.priceId },
    });
    dr.afterClosed().subscribe((ret) => {
      if (ret) {
        this.load();
      }
    });
  }

  editMinuteRange(data) {
    const dr = this.d.open(AddMinutesComponent, {
      data: { mode: 'edit', pricing: this.priceId, ...data },
    });
    dr.afterClosed().subscribe((ret) => {
      if (ret) {
        this.load();
      }
    });
  }
}

function currencyFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return '€ ' + params.value.toFixed(2);
  } else {
    return null;
  }
}
