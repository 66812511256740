import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  cache: any;

  constructor() { }

  set(data){
    this.cache = data;
    console.log(data);
  }

  get(context, id){
    try {
      return this.cache[context][id];
    }
    catch(ex){
      return null;
    }
  }

  list(context) {
    return Object.keys(this.cache[context]);
  }
}
