import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../shared.module';
import { DetailComponent } from './detail/detail.component';
import { AgGridModule } from 'ag-grid-angular';
import { AccessComponent } from './access/access.component';
import { AccessDialogComponent } from './access-dialog/access-dialog.component';
import { SettingDialogComponent } from './setting-dialog/setting-dialog.component';
import { EditComponent } from './edit/edit.component';
import { AreaDrawComponent } from './area-draw/area-draw.component';
import { AreasComponent } from './areas/areas.component';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { AddAreaSettingComponent } from './add-area-setting/add-area-setting.component';
import { TelegramComponent } from './telegram/telegram.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddFleetSettingComponent } from './add-fleet-setting/add-fleet-setting.component';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    AccessComponent,
    AccessDialogComponent,
    SettingDialogComponent,
    EditComponent,
    AreaDrawComponent,
    AreasComponent,
    AddAreaSettingComponent,
    TelegramComponent,
    AddFleetSettingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    VehiclesModule,
    TranslateModule,
  ],
  exports: [
    ListComponent
  ]
})
export class FleetModule { }
