<h1 mat-dialog-title>{{'fleets.area_settings.add.label'|translate}}</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{'fleets.area_settings.key.header'|translate}}</mat-label>
      <mat-select matInput [(ngModel)]="data.key">
        <mat-option *ngIf="adata.pagemode==='fleets'" value="max_speed">{{'fleets.area_settings.key.max_speed'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="can_park">{{'fleets.area_settings.key.can_park'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="shut_down">{{'fleets.area_settings.key.shut_down'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="discount_percentage">{{'fleets.area_settings.key.discount_percentage'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="discount_value">{{'fleets.area_settings.key.discount_value'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="color">{{'fleets.area_settings.key.color'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="label">{{'fleets.area_settings.key.label'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="description">{{'fleets.area_settings.key.description'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="visible">{{'fleets.area_settings.key.visible'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="activation">{{'fleets.area_settings.key.activation'|translate}}</mat-option>
        <mat-option *ngIf="adata.pagemode==='fleets'" value="icon">{{'fleets.area_settings.key.icon'|translate}}</mat-option>
        <mat-option value="trigger">{{'fleets.area_settings.key.trigger'|translate}}</mat-option>
        <mat-option value="on_enter">{{'fleets.area_settings.key.on_enter'|translate}}</mat-option>
        <mat-option value="on_exit">{{'fleets.area_settings.key.on_exit'|translate}}</mat-option>
        <mat-option value="cron">{{'fleets.area_settings.key.cron'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['max_speed'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.max_speed'|translate}}</mat-label>
      <input matInput type="number" min="3" max="30" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['shut_down'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.shut_down'|translate}}</mat-label>
      <input matInput type="number" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['discount_percentage'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.discount_percentage'|translate}}</mat-label>
      <input matInput type="number" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['discount_value'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.discount_value'|translate}}</mat-label>
      <input matInput type="number" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['color'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.color'|translate}}</mat-label>
      <input matInput type="color" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['label'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.label'|translate}}</mat-label>
      <input matInput type="text" [(ngModel)]="data.value">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['description'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.description'|translate}}</mat-label>
      <textarea matInput type="text" [(ngModel)]="data.value"></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['icon'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.description'|translate}}</mat-label>
      <mat-select [(ngModel)]="data.value">
        <mat-option value=""><img src=""></mat-option>
        <mat-option value=""><img src=""></mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['trigger'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.trigger'|translate}}</mat-label>
      <mat-select [(ngModel)]="data.value">
        <mat-option value="enter">{{'fleets.geofence.trigger.enter'|translate}}</mat-option>
        <mat-option value="exit">{{'fleets.geofence.trigger.exit'|translate}}</mat-option>
        <mat-option value="cron">{{'fleets.geofence.trigger.cron'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['on_enter'].indexOf(data.key) >= 0 || ['on_exit'].indexOf(data.key) >= 0 ">
      <mat-label>{{'fleets.area_settings.label.trigger'|translate}}</mat-label>
      <mat-select [(ngModel)]="data.value">
        <mat-option value="start_trip">{{'fleets.geofence.on_enter.start_trip'|translate}}</mat-option>
        <mat-option value="end_trip">{{'fleets.geofence.on_enter.end_trip'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['cron'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.trigger'|translate}}</mat-label>
      
      <input matInput type="text" placeholder="* 0 0 0 0" [(ngModel)]="data.value">
    </mat-form-field>
    
    <br *ngIf="['icon'].indexOf(data.key) >= 0">
    
    <!--<mat-form-field class="example-full-width" appearance="fill" *ngIf="['icon'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.description'|translate}}</mat-label>
      <edway-file-upload [(ngModel)]="data.value"></edway-file-upload>
    </mat-form-field>-->
    
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['icon'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.description'|translate}}</mat-label>
      <textarea matInput type="text" [(ngModel)]="data.value"></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['can_park'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.can_park'|translate}}</mat-label>
      <mat-select matInput type="text" [(ngModel)]="data.value">
        <mat-option value="True">{{'common.bool.yes'|translate}}</mat-option>
        <mat-option value="False">{{'common.bool.no'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['visible'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.visible'|translate}}</mat-label>
      <mat-select matInput type="text" [(ngModel)]="data.value">
        <mat-option value="True">{{'common.bool.yes'|translate}}</mat-option>
        <mat-option value="False">{{'common.bool.no'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="['activation'].indexOf(data.key) >= 0">
      <mat-label>{{'fleets.area_settings.label.activation'|translate}}</mat-label>
      <mat-select matInput type="text" [(ngModel)]="data.value">
        <mat-option value="True">{{'common.bool.yes'|translate}}</mat-option>
        <mat-option value="False">{{'common.bool.no'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="save()">{{'ops.ok'|translate}}</button>
</div>

