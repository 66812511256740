<h2>Package</h2>

<form>
  <mat-form-field>
    <mat-label>Nome</mat-label>
    <input
      matInput
      placeholder="Nome del pacchetto"
      [(ngModel)]="package.name"
      name="name"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Prezzo</mat-label>
    <input
      matInput
      placeholder="Prezzo del pacchetto"
      [(ngModel)]="package.price"
      name="price"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Itineraries</mat-label>
    <input
      matInput
      placeholder="Elenco di Itineraries"
      [(ngModel)]="package.itineraries"
      name="itineraries"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Experiences</mat-label>
    <input
      matInput
      placeholder="Elenco di Experiences"
      [(ngModel)]="package.experiences"
      name="experiences"
    />
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="save()">Salva</button>
</form>
