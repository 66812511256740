import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'vehicles-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {

  @Input() imei: string;
  @Input() protocol: any = null;
  @Input() commands = [
    "request_update", "start", "stop", "start_horn", "stop_horn", "open_box"
  ];

  @Output() done = new EventEmitter<any>();
  advanced = false;

  constructor(
    private e: EdwayService,
    private tr: TranslateService,
    private _snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    if(this.protocol){
      console.log(this.protocol);
      this.e.getAvailableCommands(this.protocol).subscribe(data=>{
        console.log(data);
        this.commands = Object.keys(data);
      });
    }
  }

  run(action) {
    let speed = 0;
    const params = {};
    if(action === 'setmax'){
      try{
        speed = parseInt(prompt(this.tr.instant('commands.max_speed_prompt')));
        params['speed'] = speed;
      } catch(ex){
        console.log(ex);
        alert(this.tr.instant('commands.max_speed_parameter_error'));
      }
    }
    if(confirm(this.tr.instant('commands.confirm_command'))){
      this.e.doCommand(action, this.imei, params).subscribe((data: any) =>{
        if (data.result === "ok"){
          this._snackBar.open(this.tr.instant('commands.command_sent', {action:action}), "ok", {duration: 2000});
          this.done.emit(data);
        } else {
          this._snackBar.open(this.tr.instant('commands.command_failed', {action:action, reason:data.reason}), "ok", {duration: 2000});
        }
      });
    }
  }

}
