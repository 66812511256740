<mat-toolbar>
    <h1>{{'bookings.list.title'|translate}}</h1>
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="list"
    [headerHeight]="36"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    >
</ag-grid-angular>