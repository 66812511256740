<mat-toolbar>
    <h1 translate>map.title</h1>
    <button mat-icon-button (click)="update()"><mat-icon>refresh</mat-icon></button>
    <button mat-icon-button [ngClass]="{running:keeprefreshing, stopped:!keeprefreshing}" (click)="keeprefreshing = !keeprefreshing"><mat-icon *ngIf="!keeprefreshing">play_arrow</mat-icon><mat-icon *ngIf="keeprefreshing">pause</mat-icon></button>
    <span class="spacer"></span>
    <edway-vehicles-filter (change)="apply($event)"></edway-vehicles-filter>

    <span class="spacer"></span>
</mat-toolbar>
<div id="map"></div>

<div id="timeline" *ngIf="mapmode === 'track'">
    <button mat-icon-button><mat-icon>play_arrow</mat-icon></button>
    <button mat-icon-button><mat-icon>pause</mat-icon></button>
    <span>{{from_time}}</span>
    <mat-slider></mat-slider>
    <span>{{to_time}}</span>
</div>

<mat-menu #matMenu>
    <button mat-menu-item><mat-icon>dialpad</mat-icon><span>Avvia Noleggio</span></button>
    <button mat-menu-item><mat-icon>dialpad</mat-icon><span>Veicolo</span></button>
    <button mat-menu-item><mat-icon>dialpad</mat-icon><span>Flotta</span></button>
    <button mat-menu-item><mat-icon>dialpad</mat-icon><span>Utente</span></button>
</mat-menu>