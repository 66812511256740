
<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer [opened]="true" mode="side">
        <mat-toolbar>
            <span translate>dashboard.stats</span>
        </mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="/statistics/general">Trips</a>
            <a mat-list-item routerLink="/statistics/overview">Overview</a>
        </mat-nav-list>    
    </mat-drawer>
  
    <div class="example-sidenav-content">
        <router-outlet></router-outlet>
    </div>
  
  </mat-drawer-container>