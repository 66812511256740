import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'edway-models-info',
  templateUrl: './models-info.component.html',
  styleUrls: ['./models-info.component.scss']
})
export class ModelsInfoComponent implements OnInit {

  @Input() model;
  displayedColumns: string[] = ['key', 'value'];
  data = []

  exclude = ['ident', 'image', 'protocol']
  type_b = ['requires_doc']
  type_u = ['manual']

  constructor() { }

  ngOnInit(): void {
    for (let k of Object.keys(this.model)){
      if (this.exclude.indexOf(k) < 0){
        const d = {
          key: k,
          value: this.model[k],
        }
        if (this.type_b.indexOf(k) == 0)
          d['type'] = 'bool';
        else if (this.type_u.indexOf(k) == 0)
          d['type'] = 'url';
        else 
          d['type'] = 'str';
        this.data.push(d);
      }
    }
  }

}
