<div class="stats" *ngIf="stats">
  <table mat-table [dataSource]="stats" class="stats-table dark">
    <ng-container matColumnDef="feedback_count">
      <th mat-header-cell *matHeaderCellDef>
        {{ "polls.stats.feedback_count" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.feedback_count }}</td>
    </ng-container>

    <ng-container matColumnDef="rating_min">
      <th mat-header-cell *matHeaderCellDef>
        {{ "polls.stats.rating_min" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.rating_min }}</td>
    </ng-container>

    <ng-container matColumnDef="rating_max">
      <th mat-header-cell *matHeaderCellDef>
        {{ "polls.stats.rating_max" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.rating_max }}</td>
    </ng-container>

    <ng-container matColumnDef="mean">
      <th mat-header-cell *matHeaderCellDef>
        {{ "polls.stats.mean" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.mean }}</td>
    </ng-container>

    <ng-container matColumnDef="mode">
      <th mat-header-cell *matHeaderCellDef>
        {{ "polls.stats.mode" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.mode }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
