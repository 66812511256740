import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-list-pois',
  templateUrl: './list-pois.component.html',
  styleUrls: ['./list-pois.component.scss'],
})
export class ListPoisComponent implements OnInit {
  showFilter = false;
  tags: any[] = [];
  tags_filter: any = {};
  text_filter: string = '';

  @Input() pois_list: any;
  @Input() event: EventEmitter<String>;
  @Output() modeChange: EventEmitter<String> = new EventEmitter<String>();

  constructor(private e: EdwayService) {}

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  toggleTag(tag) {
    this.tags_filter[tag.label] = !this.tags_filter[tag.label];
  }

  canSee(poi) {
    if (this.text_filter != '') {
      if (!poi.name.toLowerCase().includes(this.text_filter.toLowerCase())) {
        return false;
      }
    }
    return poi.tags.filter((x) => this.tags_filter[x.label] == true).length > 0;
  }

  ngOnInit(): void {
    for (let p of this.pois_list) {
      for (let t of p.tags) {
        if (this.tags.filter((x) => x.label === t.label).length == 0) {
          this.tags.push(t);
          this.tags_filter[t.label] = true;
        }
      }
    }
  }

  selectItem(id): void {
    this.modeChange.emit('single-poi');
    setTimeout((_) => {
      this.event.emit(id);
    }, 200);
  }

  clearTextFilter() {
    this.text_filter = '';
  }
}
