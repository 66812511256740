<mat-toolbar><h1>{{'dashboard.connectivity'|translate}}</h1></mat-toolbar>
<mat-tab-group>
    <mat-tab label="{{'connectivity.simcards'|translate}}">
        <mat-toolbar><h1>{{'connectivity.simcards'|translate}}</h1> 
            <span class="spacer"></span>
        </mat-toolbar>
        
        <ag-grid-angular
            class="fullpage ag-theme-alpine"
            [readOnlyEdit]="true"
            [rowData]="lst"
            [columnDefs]="columnDefs"
            [headerHeight]="36"
            [rowHeight]="40"
            (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
    </mat-tab>
    <mat-tab label="{{'connectivity.accounts'|translate}}">
        <mat-toolbar><h1>{{'connectivity.accounts'|translate}}</h1> 
            <span class="spacer"></span>
            <button (click)="addAccount()" mat-icon-button matTooltip="{{'op.add'|translate}}"><mat-icon>add</mat-icon></button>
        </mat-toolbar>
        <table style="width:100%">
            <tr>
                <th>{{'connectivity.provider'|translate}}</th>
                <th>{{'connectivity.label'|translate}}</th>
                <th>{{'connectivity.key'|translate}}</th>
                <th>{{'connectivity.actions'|translate}}</th>
            </tr>
            <tr *ngFor="let a of keys">
                <td>{{a.operator}}</td>
                <td>{{a.label}}</td>
                <td>{{a.api_key}}</td>
                <td>
                    <button mat-icon-button matTooltip="{{'op.refresh'|translate}}"><mat-icon>refresh</mat-icon></button>
                    <button mat-icon-button matTooltip="{{'op.delete'|translate}}"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>
        </table>
    </mat-tab>
</mat-tab-group>