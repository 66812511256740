<ag-grid-angular
  *ngIf="show"
  class="fullpage ag-theme-alpine"
  [rowData]="list"
  [headerHeight]="36"
  [rowHeight]="40"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
>
</ag-grid-angular>
