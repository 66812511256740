import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from '@maaxgr/ang-jsoneditor';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {

  trans: any = {};
  public editorOptions: JsonEditorOptions;
  mode: unknown;

  lang = "it";
  sub: Subscription;

  dirty = false;

  @ViewChild(JsonEditorComponent) edit: JsonEditorComponent

  constructor(
    private e: EdwayService,
    private ar: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.ar.data.subscribe(data=>{
      this.mode = data.mode;      
    })
    this.showTranslation('it');
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.edit.destroy();
  }

  dlTrans(){
    const fil = new File([JSON.stringify(this.trans)], "translations.json", {type: 'text/jsonMcharset=utf-8'})
    FileSaver.saveAs(fil);
  }

  save(){
    this.e.saveTranslation(this.mode, this.trans, this.lang).subscribe(data=>{
      this.dirty= false;
    })
  }

  upTrans(){

  }

  setDirty(){
    this.dirty = true;
  }


  showTranslation(lang: string) {
    this.lang=lang;
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.onChangeJSON = () => {}

    this.trans = {}

    this.e.getTranslation(this.mode, lang).subscribe(data=>{
      this.trans = data;
    })
  }
}
