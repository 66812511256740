import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  list
  status = "todo";
  user = "";
  constructor(
    private ed: EdwayService,
    private _snackBar: MatSnackBar,
    
  ) { }

  ngOnInit(): void {
    this.refresh();
  }


  refresh(){
    this.ed.getDocuments(this.status).subscribe(data => {
      this.list = data;
    });
  }

  search(){
    const un = prompt('username');
    if(un !== null){
      this.user = un;
    }
  }


  toggleFilter(filtr){
    if (this.status !== filtr){
      this.status = filtr;
    } else {
      this.status = "all";
    }
    this.refresh();
  }
  
  accept(user: string, doc: string) {
    this.ed.acceptDoc(user, doc).subscribe((data) => {
      this._snackBar.open(`${doc} accettato`, '');
      this.refresh();
    });
  }

  refuse(user: string, doc: string) {
    this.ed.refuseDoc(user, doc).subscribe((data) => {
      this._snackBar.open(`${doc} rifiutato`, '');
      this.refresh();
    });
  }

  open(url){
    
  }
}
