import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from '../edway.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-dlfilter',
  templateUrl: './dlfilter.component.html',
  styleUrls: ['./dlfilter.component.scss']
})
export class DlfilterComponent implements OnInit {

  fields: any = [];

  filter: any = {};

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(
    private e: EdwayService,
    public d: MatDialogRef<DlfilterComponent>,
    private tr: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.e.getFields(this.data.area).subscribe(data=>{
      this.fields = data;
    })
  }

  onNoClick(){
    this.d.close(false);
  }

  setFilter(){
    if (this.filter.start)
      this.filter.start = this.filter.start.toISOString().replaceAll(':', '%3A');
    if (this.filter.end)
      this.filter.end = this.filter.end.toISOString().replaceAll(':', '%3A');
    this.d.close(this.filter);
  }

}
