<mat-toolbar>
    <h1 translate *ngIf="mode==='fleets'">fleets.title</h1>
    <h1 translate *ngIf="mode==='geofences'">geofences.title</h1>
    <span class="spacer"></span>
    <button mat-icon-button disabled="true" (click)="dl()" matTooltip="{{'ops.dl'|translate}}"><mat-icon>download</mat-icon></button>
    <button mat-icon-button *ngIf="mode==='fleets'" matTooltip="{{'ops.add'|translate}}" (click)="addFleet()"><mat-icon>add</mat-icon></button>
    <button mat-icon-button *ngIf="mode==='geofences'" matTooltip="{{'ops.add'|translate}}" (click)="addGeofence()"><mat-icon>add</mat-icon></button>
</mat-toolbar>

<ag-grid-angular  *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="lst"
    [columnDefs]="columnDefs"
    [headerHeight]="36"
    [rowHeight]="40"
    [defaultColDef]="defaultColDef"

    (rowClicked)="onRowClicked($event)"
    >
</ag-grid-angular>