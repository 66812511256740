import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-distribution-detail',
  templateUrl: './distribution-detail.component.html',
  styleUrls: ['./distribution-detail.component.scss'],
})
export class DistributionDetailComponent implements OnInit {
  form_data = {
    target_type: '',
    target_fleet: '',
    target_user: '',
    target: '',
    distribution_modes: [],
    deadline: '',
  };

  fleets: unknown;
  distribution_modes: unknown;

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<DistributionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tr: TranslateService
  ) {
    if (data.target_type === 'user') {
      data.target_user = data.target;
    }
    if (data.target_type === 'fleet') {
      data.target_fleet = data.target;
    }

    data.distribution_modes = data.distribution_modes.map((dist) => dist.id);

    this.form_data = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.fleets = this.e.getFleets();
    this.distribution_modes = this.e.getDistributionModes();

    if (this.data) {
      this.data.deadline = this.data.deadline.split('T')[0];
    }
  }

  save() {
    const post_data = {
      ...this.form_data,
      news_id: this.data.news,
    };

    if (post_data.deadline !== '') {
      post_data.deadline = new Date(post_data.deadline).toISOString();
    }

    if (post_data.target_type === 'user') {
      post_data.target = post_data.target_user;
    }
    if (post_data.target_type === 'fleet') {
      post_data.target = post_data.target_fleet;
    }
    if (post_data.target_type === 'all') {
      post_data.target = '';
    }

    if (
      !this.form_data.target_type ||
      this.form_data.distribution_modes.length === 0
    ) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.updateDistribution(this.data.id, post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }

  send(speed) {
    const id = this.data.id;

    if (
      confirm(
        this.tr.instant('ops.confirm_deliver', { itm: `${id} (${speed} mode)` })
      )
    ) {
      this.e.sendDistribution(id, speed).subscribe((data) => {
        this.dialogRef.close();
        window.location.reload();
      });
    }
  }

  deleteHandler() {
    const id = this.data.id;
    if (confirm(this.tr.instant('ops.confirm_deletion', { itm: `"${id}"` }))) {
      this.e.deleteDistribution(id).subscribe((data) => {
        window.location.reload();
      });
    }
  }
}
