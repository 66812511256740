import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.scss']
})
export class SettingDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
