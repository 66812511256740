import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from './../shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent, ActionCellRenderer } from './list/list.component';
import { AddComponent } from './add/add.component';

@NgModule({
  declarations: [ListComponent, AddComponent, ActionCellRenderer],
  imports: [
    CommonModule,
    MatToolbarModule,
    CommonModule,
    SharedModule,
    MatToolbarModule,
    AgGridModule,
    TranslateModule,
  ],
})
export class ScreensModule {}
