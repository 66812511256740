import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigateComponent } from '../navigate/navigate.component';

@Component({
  selector: 'app-list-itineraries',
  templateUrl: './list-itineraries.component.html',
  styleUrls: ['./list-itineraries.component.scss'],
})
export class ListItinerariesComponent implements OnInit {
  @Input() itineraries_list: any;
  @Output() ItinerarySelectedEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() modeChange: EventEmitter<String> = new EventEmitter<String>();

  show_users = false;

  constructor(
    private d: MatDialog
  ) {}

  toggleUsers(){
    this.show_users = !this.show_users;
  }

  ngOnInit(): void {}

  edit(item) {
    console.log(item);
    this.modeChange.emit('single-itinerary');
    this.ItinerarySelectedEmitter.emit(item.id);
  }

  navigate(id){
    this.d.open(NavigateComponent, {data:{id}});
  }
}
