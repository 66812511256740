import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add-wallet',
  templateUrl: './add-wallet.component.html',
  styleUrls: ['./add-wallet.component.scss']
})
export class AddWalletComponent implements OnInit {

  data: any = {op: "Add"};
  models: any[];

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) { }

  ngOnInit(): void {
    console.log(this.idata);
    if(this.idata){
      this.data = this.idata;
    }
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
