<mat-toolbar>
  <span translate>pricing.title</span>&nbsp;-&nbsp;<span class="title">{{
    data?.id
  }}</span>
</mat-toolbar>

<div class="row">
  <table style="flex: 1 1 10px">
    <tr>
      <th translate>pricing.detail.vtype</th>
      <td>{{ data.vtype }}</td>
    </tr>
    <tr>
      <th translate>pricing.detail.model</th>
      <td>{{ data.model?.name }} - {{ data.model?.middleware }}</td>
    </tr>
    <tr>
      <th translate>pricing.detail.fleet</th>
      <td>{{ data.fleet }}</td>
    </tr>
  </table>
  <table style="flex: 1 1 10px">
    <tr>
      <th translate>pricing.detail.max_daily</th>
      <td>{{ data.max_cost }} €</td>
    </tr>
    <tr>
      <th translate>pricing.detail.booking</th>
      <td>{{ data.booking }} €</td>
    </tr>
    <tr>
      <th translate>pricing.detail.unlock</th>
      <td>{{ data.unlock }} €</td>
    </tr>
  </table>
</div>

<mat-toolbar>
  <span class="fasce" translate>pricing.range</span
  ><span class="ft" *ngIf="data.kms.length > 0" translate>pricing.per_km</span
  >&nbsp;<span class="ft" *ngIf="data.minutes.length > 0" translate
    >pricing.per_min</span
  >
  <span class="spacer"></span>
  <button
    disabled="true"
    matTooltip="{{ 'pricing.add.kms' | translate }}"
    *ngIf="
      data.kms.length > 0 ||
      (data.kms.length === 0 && data.minutes.length === 0)
    "
    mat-icon-button
    title="{{ 'pricing.add.kms' | translate }}"
    (click)="addKmRange()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <button
    matTooltip="{{ 'pricing.add.mins' | translate }}"
    *ngIf="
      data.minutes.length > 0 ||
      (data.kms.length === 0 && data.minutes.length === 0)
    "
    mat-icon-button
    title="{{ 'pricing.add.mins' | translate }}"
    (click)="addMinuteRange()"
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-toolbar>

<ag-grid-angular
  *ngIf="show && data.kms.length > 0"
  class="fullpage ag-theme-alpine"
  [rowData]="data.kms"
  [defaultColDef]="defaultColDef"
  [headerHeight]="36"
  [rowHeight]="40"
  [columnDefs]="columnDefs"
  (rowClicked)="onRowClicked($event)"
  [detailCellRendererParams]="detailCellRendererParams"
  (firstDataRendered)="onFirstDataRendered($event)"
>
</ag-grid-angular>

<ag-grid-angular
  *ngIf="show && data.minutes.length > 0"
  class="fullpage ag-theme-alpine"
  [rowData]="data.minutes"
  [headerHeight]="36"
  [rowHeight]="40"
  [defaultColDef]="defaultColDef"
  [columnDefs]="columnDefs"
  (rowClicked)="onRowClicked($event)"
  [detailCellRendererParams]="detailCellRendererParams"
  (firstDataRendered)="onFirstDataRendered($event)"
>
</ag-grid-angular>
