import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from '../shared.module';
import { DetailsComponent } from './details/details.component';
import { RouterModule } from '@angular/router';
import { LogComponent } from './log/log.component';
import { RentalsComponent } from './rentals/rentals.component';
import { CommandsComponent } from './commands/commands.component';
import { ModelsListComponent } from './models-list/models-list.component';
import { ModelsDetailComponent } from './models-detail/models-detail.component';
import { RentalsModule } from '../rentals/rentals.module';
import { CodeEditorComponent } from './code-editor/code-editor.component';
import { FleetEditorComponent } from './fleet-editor/fleet-editor.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { TrackComponent } from './track/track.component';
import { CodePipe } from './code.pipe';
import { VehicleInfoComponent } from './vehicle-info/vehicle-info.component';
import { VehicleInfoAddComponent } from './vehicle-info/vehicle-info-add/vehicle-info-add.component';
import { TranslateModule } from '@ngx-translate/core';
import { IssuesModule } from '../issues/issues.module';
import { FilterComponent } from './filter/filter.component';
import { MetaComponent } from './meta/meta.component';
import { ModelsInfoComponent } from './models-info/models-info.component';
import { ErrorCodesComponent } from './error-codes/error-codes.component';
import { MatBadgeModule } from '@angular/material/badge';
import { PicturesComponent } from './pictures/pictures.component';
import { DiagnosticsComponent } from './detais/diagnostics/diagnostics.component';
import { BoolPipe } from './detais/bool.pipe';
import { DiscoveryComponent } from './discovery/discovery.component';
import { DiscoveryOnlineCellRendererComponent } from './discovery-online-cell-renderer/discovery-online-cell-renderer.component';

@NgModule({
  declarations: [
    ListComponent,
    EditComponent,
    DetailsComponent,
    LogComponent,
    RentalsComponent,
    CommandsComponent,
    ModelsListComponent,
    ModelsDetailComponent,
    CodeEditorComponent,
    FleetEditorComponent,
    QrcodeComponent,
    TrackComponent,
    CodePipe,
    VehicleInfoComponent,
    VehicleInfoAddComponent,
    FilterComponent,
    MetaComponent,
    ModelsInfoComponent,
    ErrorCodesComponent,
    PicturesComponent,
    DiagnosticsComponent,
    BoolPipe,
    DiscoveryComponent,
    DiscoveryOnlineCellRendererComponent,
  ],
  imports: [
    CommonModule,
    AgGridModule,
    SharedModule,
    RouterModule,
    RentalsModule,
    IssuesModule,
    TranslateModule,
    MatBadgeModule,
  ],
  exports: [
    ListComponent,
    DetailsComponent,
    LogComponent,
    RentalsComponent,
    EditComponent,
    FilterComponent,
  ],
})
export class VehiclesModule {}
