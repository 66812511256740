<h1 mat-dialog-title>Usa questo Coupon</h1>
<div mat-dialog-content>
    <p>Inserisci il codice del coupon che verrà usato al posto di {{data.from}}</p>
    <div class="column">
        <mat-form-field appearance="fill">
            <mat-label>Codice</mat-label>
            <mat-select [(ngModel)]="data.to">
                <mat-option *ngFor="let code of data.options" [value]="code">
                    {{code}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button (click)="submit()" cdkFocusInitial>Ok</button>
</div>