<mat-toolbar>
  <button mat-button (click)="setGraph('trip-count-by-fleet')">
    {{ "stats.meas.trips_per_fleet" | translate }}
  </button>
  <button mat-button (click)="setGraph('trip-value-by-fleet')">
    {{ "stats.meas.revenue_per_fleet" | translate }}
  </button>
  <button mat-button (click)="setGraph('trip-sum-duration-by-fleet')">
    {{ "stats.meas.duration_per_fleet" | translate }}
  </button>
  <span class="spacer"></span>
  <button mat-button (click)="setPeriod('day')">D</button>
  <button mat-button (click)="setPeriod('week')">W</button>
  <button mat-button (click)="setPeriod('month')">M</button>
  <button mat-button (click)="setPeriod('year')">Y</button>
</mat-toolbar>
<mat-toolbar>
  <span translate>stats.meas.trips_per_fleet</span>
</mat-toolbar>
<div id="graph"></div>
