<h1 mat-dialog-title>Unlock phone</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width">
        <mat-label>Number</mat-label>
        <input matInput placeholder="+39..." [(ngModel)]="number">
      </mat-form-field>
    
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close="false">Cancel</button>
    <button mat-button mat-dialog-close="true" (click)="clear()">Ok</button>
</div>
