import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EdwayService } from 'src/app/edway.service';

declare const L: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  data;

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<DetailComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any,
    private tr: TranslateService
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    if (this.idata) {
      this.data = this.idata;
    }
  }

  ngAfterViewInit() {
    if (this.data.issue_type === 'vehicle_out_of_area') {
      var map = L.map('map').setView(
        this.data.data.details.point.reverse(),
        17
      );
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap',
      }).addTo(map);
      var marker = L.marker(this.data.data.details.point).addTo(map);
      this.e.getAreas(this.data.data.details.fleet.id).subscribe((data) => {
        var myLayer = L.geoJSON().addTo(map);
        myLayer.addData(data);
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
