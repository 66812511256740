import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EdwayService } from 'src/app/edway.service';
import { WhAddComponent } from '../wh-add/wh-add.component';
import { AddKeyComponent } from '../add-key/add-key.component';
import { InfoComponent } from '../info/info.component';

@Component({
  selector: 'hopon-webhook-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  whs: any[] = [];
  keys: any[] = [];
  groups: any[] = [];

  events:any[] = [];

  constructor(
    private e: EdwayService,
    private d: MatDialog,
    private s: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getWHs();
    this.getKeys();
    this.getGroups();
    this.e.getCloudPlatformEvents().subscribe(data=>{
      this.events = data.items;
    })
  }

  addWH(){
    const md = this.d.open(WhAddComponent, {});
    md.afterClosed().subscribe(result=>{
      this.getWHs();
    });
  }

  addKey(){
    this.e.createCloudApiKey().subscribe(data=>{
      this.getKeys();
    });
  }

  getGroups(){
    this.e.getCloudTelegramGroups().subscribe((data:any)=>{
      this.groups = data.items;
    });
  }

  getKeys(){
    this.e.getCloudApiKeys().subscribe((data:any)=>{
      this.keys = data.items;
    })
  }

  getWHs(){
    this.e.getCloudWebhooks().subscribe((data:any) =>{
      this.whs = data.items;
    })
  }

  toClip(key){
    navigator.clipboard.writeText(key);
    this.s.open('Key copied', null, {duration:500})
  }

  deleteWH(id){
    this.e.deleteCloudWebhook(id).subscribe((data:any) => {
      this.getWHs();
    })
  }

  getInfo(){
    this.d.open(InfoComponent);
  }
}
