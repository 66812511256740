<mat-toolbar>
  <h2>Itinerary</h2>
</mat-toolbar>

<mat-tab-group>
  <mat-tab>
    <form>
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input
          matInput
          placeholder="Nome dell'itinerario"
          [(ngModel)]="itinerary.name"
          name="name"
        />
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>POIs</mat-label>
        <input
          matInput
          placeholder="Elenco di POIs"
          [(ngModel)]="itinerary.pois"
          name="pois"
        />

        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Locate"
          (click)="addPoi()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field> -->

      <mat-form-field>
        <mat-label>POI</mat-label>
        <mat-select name="pois_list_add" [(ngModel)]="poi_to_add">
          <mat-option *ngFor="let p of pois_list" [value]="p">
            {{ p.name }}
          </mat-option>
        </mat-select>
        <button
          type="button"
          mat-button
          matSuffix
          mat-icon-button
          (click)="addPoiToItem()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>

      <div cdkDropList class="pois-list" (cdkDropListDropped)="drop($event)">
        <div
          class="pois-box"
          *ngFor="let p of itinerary.pois_visual; index as i"
          cdkDrag
        >
          #{{ i + 1 }} {{ p.name }}

          <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Locate"
            (click)="removePoi(p.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <mat-form-field>
        <mat-label>Experiences</mat-label>
        <input
          matInput
          placeholder="Elenco di Experiences"
          [(ngModel)]="itinerary.experiences"
          name="experiences"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Galleria</mat-label>
        <input
          matInput
          placeholder="Galleria dell'itinerario (JSON)"
          [(ngModel)]="itinerary.gallery"
          name="gallery"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Utente</mat-label>
        <input
          matInput
          placeholder="Utente"
          [(ngModel)]="itinerary.user"
          name="user"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Durata</mat-label>
        <input
          matInput
          placeholder="Durata dell'itinerario (hh:mm:ss)"
          [(ngModel)]="itinerary.duration"
          name="duration"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Percorso (JSON)</mat-label>
        <input
          matInput
          placeholder="Percorso dell'itinerario (JSON)"
          [(ngModel)]="itinerary.path"
          name="path"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tag</mat-label>
        <mat-select [(ngModel)]="itinerary.tags" name="tags" multiple>
          <mat-option *ngFor="let t of tags_list" [value]="t.id">
            {{ t.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="save()">Salva</button>
    </form>
  </mat-tab>
</mat-tab-group>
