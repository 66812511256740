<button
  style="background: none; border: none; cursor: pointer"
  (click)="btnOpenHandler()"
>
  <mat-icon>link</mat-icon>
</button>
<button
  style="background: none; border: none; cursor: pointer"
  (click)="btnDeleteHandler()"
>
  <mat-icon>delete</mat-icon>
</button>
