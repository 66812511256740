<h1 mat-dialog-title translate>settings.add_parameter</h1>
<div mat-dialog-content>
  <p>Enter the name of the new parameter and its initial value</p>
  <div class="column">
    <mat-form-field appearance="fill">
      <mat-label translate>settings.dialog.name</mat-label>
      <input matInput [(ngModel)]="name" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label translate>settings.dialog.value</mat-label>
      <input [(ngModel)]="value" matInput />
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="submit()" cdkFocusInitial>Ok</button>
</div>
