import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { saveAs } from 'file-saver';
import { AmendComponent } from '../amend/amend.component';
import { MatDialog } from '@angular/material/dialog';
import { AmendDialogComponent } from '../amend-dialog/amend-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/download.service';
import { DatePipe } from '@angular/common';
import { DateUtilService } from 'src/app/services/date-util.service';

@Component({
  selector: 'edway-payments-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  /*
  amount: 4.10002148
  amount_pre_filters: 4.10002148
  currency: "eur"
  date: "2022-06-15T08:59:09.320Z"
  description: null
  id: "f1d1a21e-ca57-4144-983e-0b17acfade1b"
  note: "user_pays"
  ownership: "806af00f-827f-4e4a-a5c6-93ffa80bd763"
  payment_type: "trip"
  stripe_confirmed: false
  stripe_id: null
  trip: "4df5668a-0edf-415d-b214-809a07df9fd3"
  unique: null
  url: null
  user: "sirmmo@gmail.com"
  */

  columnDefs: ColDef[] = [];
  pageEvent: PageEvent;

  stats: any;

  @Input() fleet: string;
  @Input() vehicle: string;
  @Input() user: string;
  @Input() trip: string;

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private d: MatDialog,
    private ar: ActivatedRoute,
    private tr: TranslateService,
    private downloadService: DownloadService,
    private dateUtil: DateUtilService
  ) {}
  // MatPaginator Output

  async ngOnInit() {
    this.e.changes.subscribe((data) => {
      this.stats = data;
    });
    await this.tr.get('dummyTranslation').toPromise().then();
    let ret = [];
    this.columnDefs = [
      /*{
      field: 'actions', filter:false,
      cellRenderer: AmendComponent,
      cellRendererParams: {
        buttons:[{
          label: "op.amend",
          icon: "edit",
          condition: (params: any) => {
            return params.stripe_id !== null;
          },
          clicked: (params: any) => {
            this.d.open(AmendDialogComponent, {data: params})
          }
        }, {
          label: "op.repeat",
          icon: "refresh",
          condition: (params: any) =>{
            return params.stripe_confirmed == false;
          },
          clicked: (params: any) => {
            if(confirm('Retry? The operation will be retried automatically')){
            }
          }
        }]
      },
      headerName: this.tr.instant('fields.payments.actions.label')
    },*/
      {
        headerName: this.tr.instant('fields.payments.date.label'),
        field: 'date',
        valueFormatter: (v) => this.dateUtil.dateTableFormatter(v),
      },
      {
        headerName: this.tr.instant('fields.payments.user.label'),
        field: 'user',
      },
      {
        headerName: this.tr.instant('fields.payments.payment_type.label'),
        field: 'payment_type',
        valueFormatter: paymentTypeFormatter,
      },
      {
        headerName: this.tr.instant('fields.payments.product_label.label'),
        field: 'product_label',
      },
      {
        headerName: this.tr.instant('fields.payments.amount_pre_filters.label'),
        field: 'amount_pre_filters',
        valueFormatter: currencyFormatter,
      },
      {
        headerName: this.tr.instant('fields.payments.amount.label'),
        field: 'amount',
        valueFormatter: currencyFormatter,
      },
      {
        headerName: this.tr.instant('fields.payments.stripe_id.label'),
        field: 'stripe_id',
      },
      {
        headerName: this.tr.instant('fields.payments.stripe_confirmed.label'),
        field: 'stripe_confirmed',
        valueFormatter: boolFormatter,
      },
      {
        headerName: this.tr.instant('fields.payments.stripe_reason.label'),
        field: 'stripe_reason',
      },
    ];
    this.updatePayments();
  }

  onRowClicked(ev) {
    this.r.navigate(['transactions', ev.data.stripe_id]);
  }

  updatePayments(page: number = 1) {
    this.e
      .getPayments({
        fleet: this.fleet,
        vehicle: this.vehicle,
        user: this.user,
        page: page.toString(),
        trip: this.trip,
      })
      .subscribe((data: any[]) => {
        this.list = data;
        this.show = true;
      });
  }

  toPage(page) {
    this.updatePayments(page.pageIndex + 1);
  }

  download(url) {
    this.downloadService
      .download(
        url,
        {
          headers: {
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        },
        'transactions'
      )
      .subscribe(() => {});
  }

  getRowStyle(params) {
    if (params.data.stripe_confirmed == false) {
      return { background: '#ffd0d0' };
    } else if (params.data.stripe_confirmed == null) {
      return { background: '#ffeed0' };
    }
  }
}

function currencyFormatter(params: ValueFormatterParams) {
  if (params.value) {
    try {
      return (
        params.data.currency.replace('eur', '€ ') + params.value.toFixed(2)
      );
    } catch (ex) {
      return '0';
    }
  } else {
    return null;
  }
}

function boolFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return '✅';
  } else {
    return '⛔';
  }
}

function paymentTypeFormatter(params: ValueFormatterParams) {
  if (params.value === "trip") {
    return '🚴';
  } else if(params.value === "direct") {
    return '💶';
  } else {
    return '📦';
  }
}
