import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { AgGridModule } from 'ag-grid-angular';
import { PenaltyComponent } from './penalty/penalty.component';
import { AmendComponent } from './amend/amend.component';
import { AmendDialogComponent } from './amend-dialog/amend-dialog.component';
import { PaymentsModule } from '../payments/payments.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterComponent } from './filter/filter.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NewRentalComponent } from './new-rental/new-rental.component';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    PenaltyComponent,
    AmendComponent,
    AmendDialogComponent,
    FilterComponent,
    NewRentalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    PaymentsModule,
    TranslateModule,
    MatBadgeModule,
  ],
  exports: [
    ListComponent,
    DetailComponent,
    AmendComponent,
    AmendDialogComponent
  ]
})
export class RentalsModule { }
