<h1 mat-dialog-title>{{ "common.wallet" | translate }}</h1>
<mat-dialog-content class="container">
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.name" | translate }}</mat-label>
    <input type="string" matInput [(ngModel)]="data.name" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.price" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.price" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.value" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.value" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.bonus" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.bonus" disabled />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.currency" | translate }}</mat-label>
    <mat-select type="string" matInput [(ngModel)]="data.currency" disabled>
      <mat-option value="eur">EUR</mat-option>
      <mat-option value="usd">USD</mat-option>
      <mat-option value="chf">CHF</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.times" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.times" required />
  </mat-form-field>
  <!-- <mat-form-field appearance="fill">
    <mat-label>{{ "common.image" | translate }}</mat-label>
    <input type="string" matInput [(ngModel)]="data.image" />
  </mat-form-field> -->
  <!-- <mat-checkbox [(ngModel)]="data.active">{{
    "common.active" | translate
  }}</mat-checkbox> -->
  <mat-checkbox [(ngModel)]="data.auto_renew">{{
    "common.auto_renew" | translate
  }}</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">{{ "ops.cancel" | translate }}</button>
  <button mat-button (click)="save()">{{ "ops.ok" | translate }}</button>
</mat-dialog-actions>
