import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  transaction_id;


  events:any = [
    {
      id: 0,
      title: null,
      content: 'There are so much countries in the world...',
      date: '2016 - 2019',
      icon: 'https://cdn.iconscout.com/icon/free/png-256/free-stripe-2-498440.png'
    },
    {
      id: 1,
      title: null,
      content: 'The best job I could possibly get!',
      date: '2015 - 2016',
      icon: 'https://cdn.iconscout.com/icon/free/png-256/free-stripe-2-498440.png'
    },
    {
      id: 2,
      title: null,
      content: 'This is the university I went...',
      date: '2011',
      icon: 'https://cdn.iconscout.com/icon/free/png-256/free-stripe-2-498440.png'
    }
  ];
  constructor(
    private ar: ActivatedRoute,
    private e: EdwayService,
  ) { }

  ngOnInit(): void {
    this.ar.paramMap.subscribe(params=>{
      this.transaction_id = params.get('id');
    })
  }

  updateLog(){
    this.e.getLog(this.transaction_id).subscribe((data:any)=>{
      this.events = data;
    })
  }


}
