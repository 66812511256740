import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-experiences',
  templateUrl: './list-experiences.component.html',
  styleUrls: ['./list-experiences.component.scss'],
})
export class ListExperiencesComponent implements OnInit {
  @Input() experiences_list: any;
  @Output() ExperienceSelectedEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() modeChange: EventEmitter<String> = new EventEmitter<String>();

  constructor() {}

  ngOnInit(): void {}

  edit(item) {
    console.log(item);
    this.modeChange.emit('single-experience');
    this.ExperienceSelectedEmitter.emit(item.id);
  }
}
