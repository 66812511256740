import { Component, OnInit } from '@angular/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-list-tags',
  templateUrl: './list-tags.component.html',
  styleUrls: ['./list-tags.component.scss']
})
export class ListTagsComponent implements OnInit {

  tags: any[] = [];
  icons: any = {};
  
  constructor(
    private e: EdwayService
  ) { }

  ngOnInit(): void {
    this.e.getExpTags().subscribe((data:any[])=>{
      this.tags = data;
    })

    this.e.getTagIcons().subscribe((data:any)=>{
      this.icons = data;
    })
  }

}
