import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, EventEmitter } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { EdwayService } from '../edway.service';
import { SettingsService } from '../settings.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HelpComponent } from '../help/help.component';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '../cache.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  clients: string[];
  settings: any;
  profile: any;
  apps: any;

  languages: string[];
  selectedLanguage: string;

  docs_todo;
  dls_todo;
  current: any;
  issues: any;
  running_rentals;

  isMobile: boolean = false;
  isDesktop: boolean = false;

  helpOpen = false;
  helpDialog:MatDialogRef<HelpComponent> = null;

  large_side=true;

  constructor(
    private ht: HttpClient,
    private e: EdwayService,
    private c: CacheService,
    private ar: ActivatedRoute,
    private a: AuthService,
    public s: SettingsService,
    private dev: DeviceDetectorService,
    private d: MatDialog,
    private tr: TranslateService,
    private t: Title,
  ) { }

  ngOnInit(): void {
    this.t.setTitle('HopOnMobility - Dashboard');
    this.e.changes.subscribe((data:any)=>{
      this.docs_todo = data.documents_todo;
      this.dls_todo = data.dls_todo;
      this.current = data.current;
      this.issues = data.config_issues;
      this.running_rentals = data.running_rentals;
    })
    this.isMobile = this.dev.isMobile();
    this.isDesktop = this.dev.isDesktop();
    this.large_side = window.innerWidth > 950;
    this.ht.get('https://api.hoponmobility.com/utils/settings').subscribe(data=>{
      this.s.set(data);
      this.settings = this.s.get();
    });
    this.e.getCached().subscribe(data=>{
      this.c.set(data);
      this.settings = this.s.get();
    });
    this.ht.get('https://api.hoponmobility.com/app/').subscribe((data:any)=>{
      this.apps = data;
      this.ht.get('https://api.hoponmobility.com/user/profile').subscribe((pdata:any)=>{
        this.profile = pdata;
        this.clients = this.apps.filter(x=>this.profile.clients.indexOf(x.ident) >= 0);
      });
    });
    this.languages = ['it', 'en', 'zh'] //this.tr.getLangs();
    this.selectedLanguage = this.tr.currentLang;
  }

  resize(){
    this.e.resize.emit();
  }

  canDo(op){
    return this.a.canDo(op);
  }

  toggle_side(){
    this.large_side=!this.large_side;
  }

  setClient(client){
    localStorage.setItem('client-id', client);
    this.a.updateRoles().subscribe(data =>{
      window.location = window.location;
    })
  }

  changeLanguage(lang: string) {
    this.tr.use(lang);
  }

  toggleHelp(){

    if (!this.helpOpen){
      this.helpDialog = this.d.open(HelpComponent)
      this.helpDialog.afterClosed().subscribe(data=>{
        this.helpOpen=false;
      });
      this.helpOpen = true;
    } else {
      this.helpDialog.close();
    }
  }



}
