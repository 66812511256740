import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings:any;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public settings$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  public showLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.isLoading.subscribe(value=>{
      this.showLoading.emit(value);
    });
    this.showLoading.emit(false);
  }

  set(s: any){
    this.settings = s;
    this.settings$.next(true)
  }

  get(field?:string){
    if (field){
      return this.settings[field];
    } else {
      return this.settings;
    }
  }
}
