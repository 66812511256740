import { DistributionDetailComponent } from './../distribution-detail/distribution-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-distributions-list',
  templateUrl: './distributions-list.component.html',
  styleUrls: ['./distributions-list.component.scss'],
})
export class DistributionsListComponent implements ICellRendererAngularComp {
  constructor(private d: MatDialog) {}

  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

  public distributions: any;

  agInit(params: any): void {
    this.distributions = params.data.distributions;
  }

  btnClickedHandler(dist, event) {
    this.d.open(DistributionDetailComponent, { data: dist });
  }
}
