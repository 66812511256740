import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { SharedModule } from '../shared.module';
import { SimcardComponent } from './simcard/simcard.component';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddAccountComponent } from './add-account/add-account.component';



@NgModule({
  declarations: [
    ListComponent,
    SimcardComponent,
    AddAccountComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    TranslateModule
    
  ]
})
export class ConnectivityModule { }
