<mat-toolbar>
  <h1>{{ "dashboard.experiences" | translate }}</h1>
  <span class="spacer"></span>
  <button
    (click)="NewPoi.emit($event); drawer_open = true; drawerMode = 'single-poi'"
    mat-icon-button
    matTooltip="{{ 'experiences.add_poi' | translate }}"
  >
    <mat-icon>pin_drop</mat-icon>
  </button>
  <button
    (click)="
      NewItinerary.emit($event);
      drawer_open = true;
      drawerMode = 'single-itinerary'
    "
    mat-icon-button
    matTooltip="{{ 'experiences.add_itinerary' | translate }}"
  >
    <mat-icon>show_chart</mat-icon>
  </button>
  <button
    (click)="drawer_open = true; drawerMode = 'list'"
    mat-icon-button
    matTooltip="{{ 'experiences.lists' | translate }}"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <button
    (click)="drawer_toggle()"
    mat-icon-button
    matTooltip="{{ 'experiences.toggle_side' | translate }}"
  >
    <mat-icon *ngIf="drawer_open">chevron_right</mat-icon
    ><mat-icon *ngIf="!drawer_open">chevron_left</mat-icon>
  </button>
</mat-toolbar>
<mat-drawer-container class="exp-container" autosize>
  <div class="row"></div>
  <mat-drawer-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <span>{{ "experiences.search_by" | translate }}</span>
        <span class="fill-remaining-space"></span>
        <div class="search-container">
          <mat-form-field>
            <mat-label>{{ "experiences.search" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'experiences.search_dots' | translate }}"
              [(ngModel)]="searchTerm"
              name="searchTerm"
            />
            <button mat-button *ngIf="searchTerm" (click)="clearSearch()">
              <mat-icon>clear</mat-icon>
            </button>
          </mat-form-field>
          <button mat-icon-button>
            <mat-icon>local_offer</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <div id="map"></div>
  </mat-drawer-content>

  <mat-drawer
    #drawer
    class="exp-sidenav"
    position="end"
    mode="side"
    [opened]="drawer_open"
  >
    <mat-tab-group
      mat-align-tabs="center"
      class="exp-list"
      *ngIf="drawerMode === 'list'"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">location_on</mat-icon>
        </ng-template>
        <app-list-pois
          [pois_list]="pois_list"
          [event]="POISelected"
          (modeChange)="changeMode($event)"
        ></app-list-pois>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">stars</mat-icon>
        </ng-template>
        <app-list-experiences
          [experiences_list]="experiences_list"
          (ExperienceSelectedEmitter)="editExperience($event)"
          (modeChange)="changeMode($event)"
        >
        </app-list-experiences>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">show_chart</mat-icon>
        </ng-template>
        <app-list-itineraries
          [itineraries_list]="itineraries_list"
          (ItinerarySelectedEmitter)="editItinerary($event)"
          (modeChange)="changeMode($event)"
        ></app-list-itineraries>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">inventory_2</mat-icon>
        </ng-template>
        <app-list-packages></app-list-packages>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">sell</mat-icon>
        </ng-template>
        <app-list-tags></app-list-tags>
      </mat-tab>
    </mat-tab-group>
    <app-poi
      *ngIf="drawerMode === 'single-poi'"
      [tags_list]="tags_list"
      [itineraries_list]="itineraries_list"
      [eventEmitter]="POISelected"
      [addClicked]="NewPoi"
    ></app-poi>

    <!--mat-tab label="Experiences">
    </mat-tab>-->
    <app-experience
      *ngIf="drawerMode === 'single-experience'"
      [selectedExperience]="selectedExperience"
    ></app-experience>

    <app-itinerary
      *ngIf="drawerMode === 'single-itinerary'"
      [selectedItinerary]="selectedItinerary"
      [tags_list]="tags_list"
      [pois_list]="pois_list"
    ></app-itinerary>

    <app-package *ngIf="drawerMode === 'single-package'"></app-package>
    <!-- mat-tab-group -->
  </mat-drawer>
</mat-drawer-container>
