import { Observable, of } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

import { CouponsService } from '../coupons.service';
import { CouponModel } from '../models';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-coupons-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {
  isCreate = false;
  couponModel: CouponModel;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly couponsService: CouponsService,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.isCreate = id === 'create';
    if (this.isCreate) {
      this.couponModel = new CouponModel();
    } else {
      this.couponsService.getCouponModel(Number(id)).subscribe( (couponModel) => {
        // Sanitize for null values
        if (couponModel.validation === null) {
          delete couponModel.validation;
        }
        this.couponModel = couponModel;
      });
    }
  }

  async save(): Promise<void> {
    if (this.isCreate) {
      const res = await this.couponsService.createCouponModel(this.couponModel);
      res.subscribe(() => {
        this.snackBar.open('Coupon model created!', '', {duration: 1000});
      });
    } else {
      const res = await this.couponsService.updateCouponModel(this.couponModel);
      res.subscribe(() => {
        this.snackBar.open('Coupon model updated!', '', {duration: 1000});
      });
    }
  }

  back(): void {
    this.router.navigate(['/coupons']);
  }

  actionsChanged(actions: string): void {
    this.couponModel.actions = JSON.parse(actions);
  }

  validationChanged(validation: string): void {
    this.couponModel.validation = JSON.parse(validation);
  }
}
