<ng-container *ngFor="let dist of distributions">
  <button
    class="compact"
    mat-button
    matTooltip="Dist {{ dist.id }}"
    (click)="btnClickedHandler(dist, $event)"
  >
    <mat-icon>schedule_send</mat-icon>
  </button>
</ng-container>
