<mat-toolbar>
  <h1 translate>coupons.title</h1>
  <span class="spacer"></span>
  <button mat-icon-button matTooltip="{{'ops.download'|translate}}" (click)="download()"><mat-icon>download</mat-icon></button>
  <button mat-icon-button matTooltip="{{'ops.add'|translate}}" (click)="createCoupon()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<mat-tab-group (selectedIndexChange)="onTabChange($event)">
  <mat-tab label="{{'coupons.active.tab'|translate}}">
    <ag-grid-angular 
    [headerHeight]="36" class="fullpage ag-theme-alpine" [defaultCsvExportParams]="exportOptions" [rowData]="coupons$ | async"
      [immutableData]="immutableData" [columnDefs]="columnDefs$ | async" [getRowId]="getRowId"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </mat-tab>
  <mat-tab label="{{'coupons.activated.tab'|translate}}">
    <app-user-coupons-list class="fullpage" style="display: block;"></app-user-coupons-list>
  </mat-tab>
</mat-tab-group>

