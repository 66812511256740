<mat-toolbar>
    <h1 translate>rentals.title</h1>
    <button mat-icon-button title="{{'ops.refresh'|translate}}" (click)="update(true)"><mat-icon>refresh</mat-icon></button>

    <span class="spacer"></span>
    <!--
    <mat-button-toggle-group name="rental_status" aria-label="Rental Status" [(ngModel)]="filter.status" (click)="update()">
        <mat-button-toggle value="unpaid"><span matTooltip="{{'rentals.class.unpaid.label'|translate}}">⛔</span></mat-button-toggle>
        <mat-button-toggle value="paid"><span matTooltip="{{'rentals.class.paid.label'|translate}}">✅</span></mat-button-toggle>
        <mat-button-toggle value="nopay"><span matTooltip="{{'rentals.class.nopay.label'|translate}}">❎</span></mat-button-toggle>
        <mat-button-toggle value="start_pending"><span matTooltip="{{'rentals.class.start_pending.label'|translate}}">💳</span></mat-button-toggle>
        <mat-button-toggle value="ongoing"><span matTooltip="{{'rentals.class.ongoing.label'|translate}}">🏃🏻‍♂️</span></mat-button-toggle>
    </mat-button-toggle-group>-->
    <edway-rentals-filter (change)="apply($event)"></edway-rentals-filter>
    <span class="spacer"></span>
    <button mat-icon-button [disabled]="!multiSelected" title="rerun" (click)="massRetry($event)"><mat-icon>refresh</mat-icon></button>
    <button mat-icon-button title="{{'ops.dl'|translate}}" (click)="download('https://api.hoponmobility.com/admin/data/rentals.xlsx')"><mat-icon>download</mat-icon></button>
    <!--<button mat-icon-button title="{{'ops.add'|translate}}"><mat-icon>add</mat-icon></button>-->
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="list"
    rowModelType="infinite"
    [defaultColDef]="defaultColDef"
    [headerHeight]="36"
    [rowHeight]="40"
    [columnDefs]="columnDefs"
    (page)="goToPage($event)"
    (rowClicked)="onRowClicked($event)"

    (gridReady)="onGridReady($event)"
    rowSelection="multiple"
    (selectionChanged)="onSelectionChanged($event)"
    >
</ag-grid-angular>