import { Component, OnInit } from '@angular/core';
import { EdwayService } from '../../edway.service';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';
import { AddAccountComponent } from '../add-account/add-account.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  keys:any[] = [];
  lst:any[] = [];
  private gridColumnApi!: ColumnApi;
  private gridApi!: GridApi;

  columnDefs: ColDef[] = [];

  constructor(
    private e: EdwayService,
    private tr: TranslateService,
    private d: MatDialog
  ) { }
  

  async ngOnInit() {

    await this.tr.get('dummyTranslation').toPromise().then();
    
    this.columnDefs = [
      { field: 'operator', headerName: this.tr.instant('connectivity.operator') },
      { field: 'iccid', headerName: this.tr.instant('connectivity.iccid') },
      { field: 'provider', headerName: this.tr.instant('connectivity.provider')},
      { field: 'card_data', headerName: this.tr.instant('connectivity.card_data') },
    ];
 

    this.e.getSimAccounts().subscribe((data:any)=>{
      this.keys = data;
    })
    this.e.getSims().subscribe((data:any)=>{
      this.lst = data;
    })
  }

  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  addAccount(){
    this.d.open(AddAccountComponent)
  }
}
