import { EdwayService } from 'src/app/edway.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent implements OnInit {
  data = { subject: '', content: '' };

  form_data = {
    title: '',
    locale: '',
  };

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddComponent>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  addRole(data) {
    this.e.addManager(data).subscribe((data) => {
      this.onNoClick();
    });
  }

  save() {
    const post_data = {
      ...this.form_data,
    };

    if (!this.form_data.title || !this.form_data.locale) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.addFaqResource(post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
