<h1 mat-dialog-title>Add Account</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>{{'connectivity.add.label'|translate}}</mat-label>
        <input matInput type="text" [(ngModel)]="data.label">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
    <mat-select   [(ngModel)]="data.operator">
        <mat-option value="truephone">truphone</mat-option>
        <mat-option value="1nce">1nce</mat-option>
        <mat-option value="resiot">res iot</mat-option>
        <mat-option value="thingsmobile">things mobile</mat-option>
    </mat-select>
</mat-form-field> 
    <mat-form-field class="example-full-width" appearance="fill" >
        <mat-label>{{'connectivity.add.api_key'|translate}}</mat-label>
        <input matInput type="text" [(ngModel)]="data.api_key">
      </mat-form-field>
    
</div>
<div mat-dialog-actions>
    <button mat-button>ok</button>
    <button mat-button>cancel</button>
</div>