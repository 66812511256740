import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DetailComponent } from './detail/detail.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { DistributionsListComponent } from './distributions-list/distributions-list.component';
import { DistributionDetailComponent } from './distribution-detail/distribution-detail.component';



@NgModule({
  declarations: [
    ListComponent,
    AddComponent,
    DetailComponent,
    DeliveryComponent,
    DistributionsListComponent,
    DistributionDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatToolbarModule,
    AgGridModule,
    TranslateModule,
  ], exports:[
    ListComponent
  ]
})
export class NewsModule { }
