<h1 mat-dialog-title translate>fleets.{{data.op}}.{{data.mode}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label translate>fleets.add.name.label</mat-label>
    <input type="text" matInput [(ngModel)]="data.name">
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="data.mode === 'fleet'">
    <mat-label translate>fleets.add.fleet.label</mat-label>
    <mat-select type="text"  [(ngModel)]="data.parent">
      <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">{{fleet?.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{"ops.cancel"|translate}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"ops.ok"|translate}}</button>
</div>
