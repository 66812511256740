import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridReadyEvent, IDatasource, IGetRowsParams, ValueFormatterParams } from 'ag-grid-community';
import { EdwayDatasource } from 'src/app/edway-datasource';
import { EdwayService } from '../../edway.service';
import { ReverseBoolCellRendererComponent } from 'src/app/bool-cell-renderer/bool-cell-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import { EditComponent } from '../edit/edit.component';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/download.service';

@Component({
  selector: 'edway-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [EdwayDatasource]
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  rowData =[];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private ds: EdwayDatasource,
    private d: MatDialog,
    private tr: TranslateService,
    private downloadService: DownloadService
  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    let ret = [];
    this.ds.setUrl(this.e.endpoints.users);
    this.e.getUsers().subscribe((data:any[]) => {
      this.rowData = data;
      this.show = true;
    });

    this.columnDefs = [
      { filter: false, headerName: this.tr.instant('fields.users.deleted'), field: 'deleted', resizable: false, maxWidth:30, cellRenderer: ReverseBoolCellRendererComponent  },
      { filter: false, headerName: this.tr.instant('fields.users.blacklist'), field: 'blacklist', resizable: false, maxWidth:30, valueFormatter: this.blformatter},
      { headerName: this.tr.instant('fields.users.username'), field: 'username', resizable: true },
      { headerName: this.tr.instant('fields.users.firstname'), field: 'firstname', resizable: true },
      { headerName: this.tr.instant('fields.users.familyname'), field: 'familyname', resizable: true },
      { headerName: this.tr.instant('fields.users.birthdate'), field: 'birthdate', resizable: true },
      { headerName: this.tr.instant('fields.users.phone'), field: 'phone', resizable: true },
      { headerName: this.tr.instant('fields.users.registration'), field: 'registration', resizable: true },
      { filter: false, headerName: this.tr.instant('fields.users.wallet.wallet'), field: 'wallet_wallet', resizable: true },
      //{ headerName: this.tr.instant('fields.users.wallet.bonus'), field: 'wallet_bonus', resizable: true },
      { filter: false,resizable: false, headerClass:'compactCol', maxWidth:40, headerName: this.tr.instant('fields.users.has_pm'), field: 'has_pm', valueFormatter: this.boolFormatter },
      { filter: false,resizable: false, headerClass:'compactCol', maxWidth:40, headerName: this.tr.instant('fields.users.has_docs'), field: 'has_docs', valueFormatter: this.boolFormatter },
      { filter: false,resizable: false, headerClass:'compactCol', maxWidth:40, headerName: this.tr.instant('fields.users.has_drivs'), field: 'has_drivs', valueFormatter: this.boolFormatter },
      { filter: false, headerName: this.tr.instant('fields.users.total_trips'), field: 'trips', resizable: true },
      { filter: false, headerName: this.tr.instant('fields.users.total_paid'), field: 'total_paid', resizable: true, valueFormatter: params => this.currencyFormatter(params, "€")  },
      { filter: false, headerName: this.tr.instant('fields.users.total_reimbursed'), field: 'reimbursed', resizable: true, valueFormatter: params => this.currencyFormatter(params, "€") },
      { filter: false, headerName: this.tr.instant('fields.users.total_packs'), field: 'total_packs', resizable: true },
    ];
  }

  blformatter(params:ValueFormatterParams){
    if (params.value==='toxic_behaviour'){
      return '☠';
    } else if(params.value === 'outstanding_payment'){
      return '💰';
    }
  }

  currencyFormatter(params, sign) {
    if (isNaN(params.value)){
      return '';
    } else {
      const currency = params.value;
      if (currency != null){
        var sansDec = currency.toFixed(0);
        var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return sign + `${formatted}`;
      }
    }
  }


  boolFormatter(param){
    if (param.value){
      return "✅";
    } else {
      return "⛔";
    }
  }

  onRowClicked(ev){
    this.r.navigate(["/users", ev.data.username])
  }
  
  goToPage(page){
    this.e.getUsers(page.pageIndex + 1)
  }

  onGridReady(params: GridReadyEvent) {
    params.api!.setDatasource(this.ds);
  }

  download(url){
    this.downloadService.download(url, {headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}}, "users")
    .subscribe(() => {});
  }

  addUser(){
    this.d.open(EditComponent);
  }
}
