import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  SimpleChanges,
  Output,
} from '@angular/core';
import { EdwayService } from 'src/app/edway.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
})
export class ItineraryComponent implements OnInit {
  @Input() tags_list: any;
  @Input() pois_list: any;

  item_id: any = null;
  isNewRecord = true;
  poi_to_add: any = null;

  itinerary_schema = {
    name: 'Itinerario di Esempio',
    pois: [],
    pois_visual: [],
    experiences: [],
    gallery: [],
    user: 'user',
    // duration: '',
    path: '',
    tags: [],
  };

  @Input() selectedItinerary: any;

  itinerary: any = this.itinerary_schema;

  constructor(private e: EdwayService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.item_id = changes.selectedItinerary.currentValue;
    console.log(changes);

    if (this.item_id) {
      this.edit();
    }
  }

  save() {
    const post_data = {
      ...this.itinerary,
    };

    if (this.isNewRecord) {
      this.e.addItinerary(post_data).subscribe((data) => {
        alert('Salvataggio completato');
        console.log(data);
        // window.location.reload();
      });
    } else {
      console.log(post_data);
      this.e.updateItinerary(this.item_id, post_data).subscribe((data) => {
        alert('Salvataggio completato');
        console.log(data);
        // window.location.reload();
      });
    }
  }

  edit() {
    this.isNewRecord = false;
    this.loadItineraryData(this.item_id);
  }

  loadItineraryData(id: string) {
    this.e.getSingleItinerary(id, true).subscribe((data) => {
      this.itinerary = data;

      this.itinerary.tags = this.itinerary.tags.map((tag) => tag.id);

      this.itinerary.pois_visual = this.itinerary.pois;
      this.itinerary.pois = this.itinerary.pois.map((tag) => tag.id);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.itinerary.pois_visual,
      event.previousIndex,
      event.currentIndex
    );

    this.orderItemPois();
  }

  addPoiToItem() {
    console.log(this.poi_to_add);
    this.itinerary.pois_visual.push({
      id: this.poi_to_add.poi, // !!!
      name: this.poi_to_add.name,
    });
    this.orderItemPois();
  }

  removePoi(poi_id) {
    this.itinerary.pois_visual = this.itinerary.pois_visual.filter(
      (p) => p.id !== poi_id
    );
    this.orderItemPois();
  }

  orderItemPois() {
    this.itinerary.pois = this.itinerary.pois_visual.map((tag) => tag.id);
  }
}
