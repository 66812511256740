import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';
import { AddComponent } from '../add/add.component';

import { ColDef } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { EdwayService } from 'src/app/edway.service';
import { DateUtilService } from 'src/app/services/date-util.service';

@Component({
  selector: 'btn-cell-renderer',
  // template: `
  //   <button (click)="btnOpenHandler()">Open</button>
  //   <button (click)="btnDeleteHandler()">Delete</button>
  // `,
  templateUrl: './action.component.html',
})
export class ActionCellRenderer implements ICellRendererAngularComp {
  private params: any;

  constructor(private e: EdwayService, private tr: TranslateService) {}

  agInit(params: any): void {
    this.params = params;
  }

  btnDeleteHandler() {
    const { id } = this.params.data;

    if (confirm(this.tr.instant('ops.confirm_deletion', { itm: id }))) {
      this.e.deleteFaqResource(id).subscribe((data) => {
        window.location.reload();
      });
    }
  }

  btnOpenHandler() {
    const url = `https://faq.hoponmobility.com/resources/${this.params.data.uuid}/edit`;
    window.open(url);
  }

  refresh() {
    return false;
  }
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  list: any = {};
  show = false;

  public defaultColDef: ColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    minWidth: 20,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  constructor(
    private e: EdwayService,
    private d: MatDialog,
    private tr: TranslateService,
    private dateUtil: DateUtilService
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.toPage();
    this.columnDefs = [
      { headerName:this.tr.instant('screens.title'), field: 'title' },
      { headerName:this.tr.instant('screens.locale'), field: 'locale' },
      { headerName:this.tr.instant('screens.active'), field: 'active' },
      { headerName:this.tr.instant('screens.tags'), field: 'tags' },
      { headerName:this.tr.instant('screens.updated_at'), field: 'updated_at', valueFormatter: (v) => this.dateUtil.dateTableFormatter(v, 'dd/MM/yyyy HH:mm') },
      { headerName:this.tr.instant('screens.uuid'), field: 'uuid' },
      { headerName:this.tr.instant('screens.action'), field: 'action',
        cellRenderer: ActionCellRenderer,
      },
    ];
  }

  toPage(page: number = 1) {
    this.e.getFaqResources().subscribe((data) => {
      console.log(data);
      this.list = data;
      // Nicco: Mostra il primo tag, su tabella si vede [Object object] (Non so quanti tag ci sono per ogni screen)
      this.list.map(e => e.tags = e.tags[0]?.title || null)
      this.show = true;
    });
  }

  addScreen() {
    this.d.open(AddComponent, { data: {} });
  }

  onRowClicked(ev) {
    const url = `https://faq.hoponmobility.com/resources/${ev.data.uuid}/edit`;
    window.open(url);
  }

  deleteInfo(id) {
    if (confirm(this.tr.instant('ops.confirm_deletion', { itm: id }))) {
      // alert(id);

      this.e.deleteVehicleInfo(id).subscribe((data) => {
        window.location.reload();
      });
    }
  }
}
