import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coupon, CouponModel, CreateCoupon, CreateCouponModel } from './models';
import { environment } from 'src/environments/environment';
import { CouponApplication } from './models/coupon-application.model';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(private http: HttpClient) { }

  public createCouponModel(coupon: CreateCouponModel): Observable<CouponModel> {
    console.log('createCouponModel', coupon);
    return this.http.post<CouponModel>(environment.api_endpoint + '/coupons/models', coupon);
  }

  public getCouponModel(id: number): Observable<CouponModel> {
    return this.http.get<CouponModel>(environment.api_endpoint + '/coupons/models/' + id);
  }

  public deleteCouponModel(id: number): Observable<void> {
    return this.http.delete<void>(environment.api_endpoint + '/coupons/models/' + id);
  }

  public updateCouponModel(coupon: CouponModel): Observable<CouponModel> {
    return this.http.put<CouponModel>(environment.api_endpoint + '/coupons/models/' + coupon.id, coupon);
  }

  public getCouponModels(): Observable<CouponModel[]> {
    return this.http.get<CouponModel[]>(environment.api_endpoint + '/coupons/models');
  }

  public createCoupon(coupon: CreateCoupon): Observable<Coupon> {
    return this.http.post<Coupon>(environment.api_endpoint + '/coupons', coupon).pipe(map(injectEnabledProperty));
  }

  public getCoupon(code: string): Observable<Coupon> {
    return this.http.get<Coupon>(environment.api_endpoint + '/coupons/' + code).pipe(map(injectEnabledProperty));
  }

  public deleteCoupon(code: string): Observable<Coupon> {
    return this.http.delete<Coupon>(environment.api_endpoint + '/coupons/' + code);
  }

  public getCoupons(): Observable<Coupon[]> {
    return this.http.get<Coupon>(environment.api_endpoint + '/coupons').pipe(
      map((data: any) => data.map(injectEnabledProperty) as Coupon[]));
  }

  public applyCoupon(code: string, user?: string): Observable<void> {
    const query = user ? '?user=' + user : '';
    return this.http.post<void>(environment.api_endpoint + '/coupons/' + code + '/apply' + query, {});
  }

  public disableCoupon(code: string): Observable<Coupon> {
    return this.http.post<Coupon>(environment.api_endpoint + '/coupons/' + code + '/disable', {});
  }

  public redirectCoupon(from: string, to: string): Observable<Coupon> {
    return this.http.post<Coupon>(environment.api_endpoint + '/coupons/' + from + '/redirect?redirectTo=' + to, {});
  }

  public getCouponsActivations(filter: {user?: string, model?: string} = {}): Observable<CouponApplication[]> {
    const query = [];

    if (filter.user) {
      query.push('user=' + filter.user);
    }

    if (filter.model) {
      query.push('model=' + filter.model);
    }
    const queryString = query.length ? '?' + query.join('&') : '';
    return this.http.get<CouponApplication[]>(environment.api_endpoint + '/coupons/activations' + queryString).pipe(map(
      (data: any) =>  {
        const result: CouponApplication[] = [];
        for (const remoteCouponActivation of data) {
          for (const appliedBy of remoteCouponActivation.appliedBy) {
            result.push({
              code: remoteCouponActivation.code,
              user: appliedBy.user,
              appliedAt: appliedBy.appliedAt,
              model: remoteCouponActivation.model.name,
              couponId: 0,
              modelId: 0
            });
          }
        }
        return result;
      }));
  }
}

function injectEnabledProperty(coupon: any) {
  return { ...coupon, enabled: !coupon.disabled };
}
