import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from 'src/app/cache.service';

@Component({
  selector: 'app-new-rental',
  templateUrl: './new-rental.component.html',
  styleUrls: ['./new-rental.component.scss']
})
export class NewRentalComponent implements OnInit {

  options: any[] = [];
  d = {
    vehicle: "",
    username: "",
    one_shot: true,
    user:null,
  };

  constructor(
    public dialogRef: MatDialogRef<NewRentalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      this.d.vehicle = data.vehicle;
  }

  ngOnInit(): void {
    console.log(this.data);
  }
}

