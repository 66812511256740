<mat-toolbar>
    <button (click)="showTranslation('it')" mat-button>it</button>
    <button (click)="showTranslation('en')" mat-button>en</button>
    <button (click)="showTranslation('es')" mat-button>es</button>
    <button (click)="showTranslation('gr')" mat-button>gr</button>
    <button (click)="showTranslation('de')" mat-button>de</button>
    <button (click)="showTranslation('ru')" mat-button>ru</button>
    <button mat-icon-button><mat-icon>add</mat-icon></button>
    <span class="spacer"></span>
    <button mat-icon-button [disabled]="!dirty" (click)="save()"><mat-icon>save</mat-icon></button>
    <button mat-icon-button (click)="dlTrans()"><mat-icon>download</mat-icon></button>
    <button mat-icon-button (click)="upTrans()"><mat-icon>upload</mat-icon></button>
    
</mat-toolbar>

<json-editor #edit [options]="editorOptions" (change)="setDirty()" [data]="trans"></json-editor>