import { ScreensModule } from './screens/screens.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TracksModule } from './tracks/tracks.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';
import { AuthModule } from './auth/auth.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapComponent } from './map/map.component';
import { VehiclesModule } from './vehicles/vehicles.module';
import { TranslationsModule } from './translations/translations.module';
import { UsersModule } from './users/users.module';
import { RentalsModule } from './rentals/rentals.module';
import { ButtonsComponent } from './buttons/buttons.component';
import { ProfileComponent } from './profile/profile.component';
import { FleetModule } from './fleet/fleet.module';
import { PricingModule } from './pricing/pricing.module';
import { PackagesModule } from './packages/packages.module';
import { StatsModule } from './stats/stats.module';
import { IssuesModule } from './issues/issues.module';
import { PaymentsModule } from './payments/payments.module';
import { CouponsModule } from './coupons/coupons.module';
import { SettingsModule } from './settings/settings.module';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ListComponent } from './roles/list/list.component';
import { AddComponent } from './roles/add/add.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { BillingComponent } from './billing/billing.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { NewsModule } from './news/news.module';
import { NgJsonEditorModule } from '@maaxgr/ang-jsoneditor';
import { AngularTelegramLoginWidgetModule } from 'angular-telegram-login-widget';
import { ClientinfoComponent } from './clientinfo/clientinfo.component';
import { TimestampCellRendererComponent } from './timestamp-cell-renderer/timestamp-cell-renderer.component';
import { MatBadgeModule } from '@angular/material/badge';
import { GsmCellRendererComponent } from './gsm-cell-renderer/gsm-cell-renderer.component';
import { OnlineCellRendererComponent } from './online-cell-renderer/online-cell-renderer.component';
import { RentalStatusRendererComponent } from './rental-status-renderer/rental-status-renderer.component';
import { HelpComponent } from './help/help.component';
import { GravatarModule } from 'ngx-gravatar';
import { BookingsModule } from './bookings/bookings.module';
import { FilesComponent } from './dashboard/files/files.component';
import { DlfilterComponent } from './dlfilter/dlfilter.component';
import { ImageboxComponent } from './imagebox/imagebox.component';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PlatformStatusComponent } from './platform-status/platform-status.component';
import { WebhooksModule } from './webhooks/webhooks.module';
import { ExperiencesModule } from './experiences/experiences.module';
import { ConnectivityModule } from './connectivity/connectivity.module';
import { BeaconsComponent } from './beacons/beacons.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MapComponent,
    ButtonsComponent,
    ProfileComponent,
    ListComponent,
    AddComponent,
    FeedbackComponent,
    BillingComponent,
    ClientinfoComponent,
    TimestampCellRendererComponent,
    GsmCellRendererComponent,
    OnlineCellRendererComponent,
    RentalStatusRendererComponent,
    HelpComponent,
    FilesComponent,
    DlfilterComponent,
    ImageboxComponent,
    PlatformStatusComponent,
    BeaconsComponent,
  ],
  imports: [
    CommonModule,
    TracksModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    VehiclesModule,
    TranslationsModule,
    FleetModule,
    NgJsonEditorModule,
    UsersModule,
    RentalsModule,
    PricingModule,
    PackagesModule,
    StatsModule,
    SettingsModule,
    IssuesModule,
    PaymentsModule,
    CouponsModule,
    AgGridModule,
    NewsModule,
    ExperiencesModule,
    MatBadgeModule,
    ScreensModule,
    HttpClientModule,
    BookingsModule,
    WebhooksModule,
    AngularTelegramLoginWidgetModule,
    GravatarModule,
    ConnectivityModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private tr: TranslateService) {
    this.tr.use(navigator.language.split('-')[0]);
  }
}
