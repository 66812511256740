<h1 mat-dialog-title translate>pricing.minutes.title</h1>
<mat-dialog-content>
  <div class="container">
    <mat-form-field appearance="fill">
      <mat-label>{{ "pricing.detail.starts_at" | translate }}</mat-label>
      <input type="number" matInput [(ngModel)]="data.starts_at" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ "pricing.detail.usage_per_minute" | translate }}</mat-label>
      <input type="number" matInput [(ngModel)]="data.usage_per_minute" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ "pricing.detail.pause_per_minute" | translate }}</mat-label>
      <input type="number" matInput [(ngModel)]="data.pause_per_minute" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close="null">{{'op.cancel'|translate}}</button>
  <button mat-button (click)="addRange()">Ok</button>
</mat-dialog-actions>
