<mat-toolbar>
    <h1 translate>pricing.title</h1>
    <span class="spacer"></span>
    <button mat-icon-button title="{{'ops.dl'|translate}}"><mat-icon>download</mat-icon></button>
    <button mat-icon-button title="{{'ops.add'|translate}}" (click)="addPricing()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="list"
    [defaultColDef]="defaultColDef"
    [headerHeight]="36"
    [rowHeight]="40"
    [columnDefs]="columnDefs"
    (rowClicked)="onRowClicked($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    >
</ag-grid-angular>