import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { MapComponent } from './map/map.component';

import { ListComponent as TracksListComponent } from './tracks/list/list.component';
import { DetailComponent as TracksDetailsComponent } from './tracks/detail/detail.component';

import { ListComponent as VehiclesListComponent } from './vehicles/list/list.component';
import { DetailsComponent as VehiclesDetailsComponent } from './vehicles/details/details.component';
import { LogComponent as VehicleLogComponent } from './vehicles/log/log.component';
import { RentalsComponent as VehicleRentalsComponent } from './vehicles/rentals/rentals.component';

import { ListComponent as FleetListComponent } from './fleet/list/list.component';
import { DetailComponent as FleetDetailComponent } from './fleet/detail/detail.component';

import { ListComponent as UsersListComponent } from './users/list/list.component';
import { DetailComponent as UsersDetailComponent } from './users/detail/detail.component';

import { ListComponent as RentalsListComponent } from './rentals/list/list.component';
import { DetailComponent as RentalsDetailComponent } from './rentals/detail/detail.component';

import { ListComponent as PricingListComponent } from './pricing/list/list.component';
import { DetailComponent as PricingDetailComponent } from './pricing/detail/detail.component';

import { GeneralComponent as PackagesListComponent } from './packages/general/general.component';
import { PackagesListComponent as PackagesGeneralComponent } from './users/packages-list/packages-list.component';
import { DetailComponent as PackagesDetailComponent } from './packages/detail/detail.component';

import { ListComponent as TransactionsListComponent } from './payments/list/list.component';
import { DetailComponent as TransactionsDetailComponent } from './payments/detail/detail.component';

import { ListComponent as NewsListComponent } from './news/list/list.component';
import { DetailComponent as NewsDetailComponent } from './news/detail/detail.component';

import { ListComponent as TranslationsListComponent } from './translations/list/list.component';
import { DetailComponent as TranslationDetailComponent } from './translations/detail/detail.component';

import { ListComponent as CouponsListComponent } from './coupons/list/list.component';
import { ModelListComponent as CouponsModelListComponent } from './coupons/model-list/model-list.component';
import { ModelComponent as CouponsModelComponent } from './coupons/model/model.component';

import { MainComponent as StatsComponent } from './stats/main/main.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { ProfileComponent } from './profile/profile.component';

import { ListComponent as ScreensListComponent } from './screens/list/list.component';

import { ListComponent as WebhookListComponent } from './webhooks/list/list.component';
import { DetailComponent as WebhookDetailsComponent } from './webhooks/detail/detail.component';

import { SettingsComponent } from './settings/settings.component';
import { ModelsListComponent } from './vehicles/models-list/models-list.component';
import { ModelsDetailComponent } from './vehicles/models-detail/models-detail.component';
import { AuthGuard } from './auth/auth.guard';
import { PlatformStatusComponent } from './platform-status/platform-status.component';
import { ListComponent as RolesListComponent } from './roles/list/list.component';
import { GeneralComponent } from './stats/general/general.component';
import { OverviewComponent } from './stats/overview/overview.component';
import { ResetComponent } from './auth/reset/reset.component';
import { ClientinfoComponent } from './clientinfo/clientinfo.component';
import { ListComponent as IssuesListComponent } from './issues/list/list.component';
import { DocsComponent } from './users/docs/docs.component';
import { ExporterComponent } from './payments/exporter/exporter.component';
import { FilesComponent } from './dashboard/files/files.component';
import { BotpComponent } from './users/botp/botp.component';
import { SoldPackagesComponent } from './packages/sold-packages/sold-packages.component';
import { DiscoveryComponent } from './vehicles/discovery/discovery.component';
import { BillingComponent } from './billing/billing.component';
import { MainComponent as ExperiencesMainComponent } from './experiences/main/main.component';

import { ListComponent as ConnectivityListComponent } from './connectivity/list/list.component';
import { SimcardComponent } from './connectivity/simcard/simcard.component';
import { BeaconsComponent } from './beacons/beacons.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ButtonsComponent },
      { path: 'map', component: MapComponent },
      { path: 'files', component: FilesComponent },

      { path: 'tracks', component: TracksListComponent },
      { path: 'tracks/:id', component: TracksDetailsComponent },

      { path: 'vehicles', component: VehiclesListComponent },
      { path: 'beacons', component: BeaconsComponent },
      {
        path: 'vehicles/:id',
        component: VehiclesDetailsComponent,
        children: [
          { path: 'log', component: VehicleLogComponent },
          { path: 'rentals', component: VehicleRentalsComponent },
        ],
      },

      
      { path: 'discovery', component: DiscoveryComponent },
      {
        path: 'discovery/:id',
        component: VehiclesDetailsComponent, data: {mode: 'discovery'},
        children: [
          { path: 'log', component: VehicleLogComponent },
        ],
      },

      { path: 'billing', component: BillingComponent},

      { path: 'issues', component: IssuesListComponent },

      { path: 'models', component: ModelsListComponent },
      { path: 'models/:id', component: ModelsDetailComponent },

      { path: 'users', component: UsersListComponent },
      { path: 'users/botp', component: BotpComponent },
      { path: 'users/:id', component: UsersDetailComponent },

      { path: 'rentals', component: RentalsListComponent },
      {
        path: 'rentals/problems',
        component: RentalsListComponent,
        data: { filter: 'anomalies' },
      },
      { path: 'rentals/:id', component: RentalsDetailComponent },

      { path: 'news', component: NewsListComponent },
      { path: 'news/:id', component: NewsDetailComponent },

      { path: 'fleets', component: FleetListComponent },
      { path: 'fleets/:fleetId', component: FleetDetailComponent },

      { path: 'pricing', component: PricingListComponent },
      { path: 'pricing/:id', component: PricingDetailComponent },
      
      { path: 'webhooks', component: WebhookListComponent },
      { path: 'webhooks/:id', component: WebhookDetailsComponent },

      { path: 'spackages', component: SoldPackagesComponent },
      { path: 'packages', component: PackagesListComponent },
      { path: 'packages/:id', component: PackagesDetailComponent },

      { path: 'transactions', component: TransactionsListComponent },
      { path: 'transactions/export', component: ExporterComponent },
      { path: 'transactions/:id', component: TransactionsDetailComponent },

      {path: 'connectivity', component: ConnectivityListComponent },
      {path: 'connectivity/:id', component: SimcardComponent},
      
      {
        path: 'statistics',
        component: StatsComponent,
        children: [
          { path: 'general', component: GeneralComponent },
          { path: 'overview', component: OverviewComponent },
        ],
      },

      {
        path: 'screens',
        component: ScreensListComponent,
        data: { helpTag: 'dashboard/screens' },
      },
      { path: 'docs', component: DocsComponent },

      {
        path: 'translations',
        component: TranslationsListComponent,
        children: [
          {
            path: 'mobile',
            component: TranslationDetailComponent,
            data: { mode: 'mobile' },
          },
          {
            path: 'dashboard',
            component: TranslationDetailComponent,
            data: { mode: 'dashboard' },
          },
        ],
      },
      { path: 'settings', component: SettingsComponent },

      { path: 'experiences', component: ExperiencesMainComponent },

      // {
      //   path: 'experiences',
      //   loadChildren: () =>
      //     import('./experiences/experiences.module').then(
      //       (m) => m.ExperiencesModule
      //     ),
      // },

      { path: 'profile', component: ProfileComponent },

      { path: 'coupons', component: CouponsModelListComponent },
      { path: 'coupons/:id', component: CouponsModelComponent },

      { path: 'activated_coupons', component: CouponsListComponent },

      { path: 'platform-status', component: PlatformStatusComponent },
      { path: 'roles', component: RolesListComponent },

      { path: 'clientinfo', component: ClientinfoComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
