import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EdwayService } from '../../edway.service';

@Component({
  selector: 'app-online-cell-renderer',
  templateUrl: './discovery-online-cell-renderer.component.html',
  styleUrls: ['./discovery-online-cell-renderer.component.scss']
})
export class DiscoveryOnlineCellRendererComponent implements ICellRendererAngularComp {

  private params!: ICellRendererParams;
  public style!: any;
  public value!: string;
  public imgForMood!: string;
  public inUse!: boolean

  constructor(
    private e: EdwayService
  ) { }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setMood(params);  }

  private setMood(params: ICellRendererParams) {
    this.value = params.value;
    this.inUse = this.value !== 'online' && this.value !== 'offline' && this.value !== 'management' && this.value !== 'unreacheable' && this.value !== null;
  }

  toggleOffline(ev: Event){
    ev.stopPropagation();
    ev.preventDefault();
    console.log('toggle', this.params);
    let newValue = "offline";
    if (this.params.value == "offline")
      newValue="online"
    this.e.toggleVehicleVisibility(this.params.data.ident, newValue, this.params.value).subscribe((data:any)=>{
      this.params.value=data.status;
      this.refresh(this.params);
    })
  }

  setStatus(status){
    this.e.toggleVehicleVisibility(this.params.data.ident, status, this.params.value).subscribe((data:any)=>{
      this.params.value=data.status;
      this.refresh(this.params);
    })
  }

  noclick(ev){
    ev.stopPropagation();
    ev.preventDefault();
  }

}