import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'edway-vote-stats',
  templateUrl: './vote-stats.component.html',
  styleUrls: ['./vote-stats.component.scss'],
})
export class VoteStatsComponent implements OnInit {
  uid: string = null;

  displayedColumns: string[] = [
    'feedback_count',
    'rating_min',
    'rating_max',
    'mean',
    'mode',
  ];

  stats: any = {};
  constructor(private e: EdwayService, private ar: ActivatedRoute) {}

  load() {
    this.e.getUserFeedbacks(this.uid, 'info').subscribe((stats_data: any[]) => {
      this.stats = [stats_data];
    });
  }

  ngOnInit(): void {
    this.ar.params.subscribe((data) => {
      this.uid = data.id;
      this.load();
    });
  }
}
