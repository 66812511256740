import { Component, OnInit } from '@angular/core';
import { EdwayService } from '../edway.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helps = [];
  tickets = [];
  openTicket = {};

  ticket = {
    subject: '',
    description: ''
  }

  macroticket = {
    topic: "",
    subject: '',
    description: ''
  }

  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private _sb: MatSnackBar
  ) { }

  options = {
    "vehicles": "Veicoli",
    "users": "Utenti",
    "fleets": "Flotte",
    "files": "Files",
  }

  ngOnInit(): void {
    console.log(this.r.url);
    this.e.getContextualHelp(this.r.url).subscribe(data=>{
      this.helps = data;
    });
    this.refreshTickets();
    const steps = this.r.url.split('/');
    for(let i in steps){
      if (parseInt(i) == 1){
        this.macroticket.topic = this.options[steps[1]];
      }else if (parseInt(i)==2){
        this.macroticket.subject = steps[2];
      }
    }
  }

  refreshTickets(){
    this.e.getTickets().subscribe((data:any)=>{
      this.tickets = data;
    });

  }



  open(url:string){

  }

  getTicket(t){
    this.e.getTickets(t.id).subscribe((data:any)=>{
      this.openTicket = data;
    })

  }

  submit(){
    this.ticket.subject = "["+this.macroticket.topic+"] "+this.macroticket.subject
    this.ticket.description = this.macroticket.description;
    this.e.createTicket(this.ticket).subscribe(data=>{
      this._sb.open('ticket created', 'ok', {duration:500});
      this.clear();
      this.refreshTickets();
    })
  }

  clear(){
    this.ticket = {
      subject: null,
      description: null
    }
  }

}
