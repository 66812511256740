import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings.service';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public loaderService: SettingsService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.isLoading.next(true);
    if(request.url.indexOf('login') >= 0){
      console.log(request);
      return next.handle(request).pipe(
        finalize(
          () => {
            this.loaderService.isLoading.next(false);
          }
        ))
    } else {
      const accessToken = localStorage.getItem('access_token');
      const clientId = localStorage.getItem('client-id');
      let rh = request.headers.set('Authorization', `Bearer ${accessToken}`);
      if(clientId){
        rh = rh.set('Client-Id', clientId);
      }
        
      const modifiedReq = request.clone({ 
        headers: rh
      });
      
      return next.handle(modifiedReq).pipe(
        finalize(
          () => {
            this.loaderService.isLoading.next(false);
          }
        )
      );
    }
  }
}
