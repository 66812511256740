<mat-toolbar>
  <h1>Coupon Model</h1>
  <span class="spacer"></span>
</mat-toolbar>
<ng-container *ngIf="couponModel">
  <div class="container">
    <mat-form-field appearance="fill" *ngIf="!isCreate">
      <mat-label>ID</mat-label>
      <input matInput [(ngModel)]="couponModel.id" disabled>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="couponModel.name">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <textarea matInput [(ngModel)]="couponModel.description"></textarea>
    </mat-form-field>
    <div class="jsoninput">
      <h2>Actions</h2>
      <p><b>Note</b> that you can use <code>client_id</code> and <code>user</code> variables as 
        they are inject automatically by the backend </p>
      <ngx-codemirror [ngModel]="couponModel.actions | json" [options]="{
          lineNumbers: true,
          theme: 'base16-light',
          mode: 'javascript'
        }" (ngModelChange)="actionsChanged($event)"></ngx-codemirror>
    </div>
    <div class="jsoninput">
      <h2>Validation</h2>
      <ngx-codemirror [ngModel]="couponModel.validation | json" [options]="{
        lineNumbers: true,
        theme: 'base16-light',
        mode: 'javascript'
      }" (ngModelChange)="validationChanged($event)"></ngx-codemirror>
    </div>
    <section class="example-section">
      <mat-checkbox [(ngModel)]="couponModel.hasMaxUses">Has Max uses?</mat-checkbox>
      <mat-checkbox [(ngModel)]="couponModel.hasExpirationDate">Has Expiration Date?</mat-checkbox>
    </section>
    <mat-form-field appearance="fill" *ngIf="!isCreate">
      <mat-label>Tenant</mat-label>
      <input matInput [(ngModel)]="couponModel.tenant" disabled>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="!isCreate">
      <mat-label>Creation date</mat-label>
      <input matInput [(ngModel)]="couponModel.createdAt" disabled>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="!isCreate">
      <mat-label>Last update</mat-label>
      <input matInput [(ngModel)]="couponModel.updatedAt" disabled>
    </mat-form-field>
    <section style="display: flex; flex-direction: row; gap: 5px;">
      <button mat-flat-button color="primary" (click)="save()">Save</button>
      <button mat-flat-button (click)="back()">Back</button>
    </section>
  </div>
</ng-container>
