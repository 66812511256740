<mat-toolbar>
    <h2>Logs</h2>
    <button mat-icon-button (click)="showTrack()"><mat-icon><i class="fa-solid fa-share-nodes"></i></mat-icon></button>
    <span class="spacer"></span>
    <input mat-input [(ngModel)]="filter.from_time" type="datetime">
    <input mat-input [(ngModel)]="filter.to_time" type="datetime">
    <button mat-icon-button (click)="filterlogs()"><mat-icon>filter_list</mat-icon></button>
    <button mat-icon-button (click)="onBtnExport()"><mat-icon>download</mat-icon></button>
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="logs"
    [headerHeight]="36"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    (rowClicked)="onRowClicked($event)"
    (gridReady)="onGridReady($event)"

    >
</ag-grid-angular>
