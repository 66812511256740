<mat-toolbar>
  <h1 translate>dashboard.ian</h1>
  <span class="spacer"></span>
  <button mat-icon-button (click)="addIan()" matTooltip="Nuova Notifica In-App">
    <mat-icon>app_shortcut</mat-icon>
  </button>
</mat-toolbar>
<mat-paginator
  length="1000"
  pageSize="25"
  (page)="toPage($event)"
  aria-label="Select page"
>
</mat-paginator>
<ag-grid-angular
  *ngIf="show"
  class="fullpage ag-theme-alpine"
  [rowData]="list"
  [defaultColDef]="defaultColDef"
  [headerHeight]="36"
  [rowHeight]="28"
  [columnDefs]="columnDefs"
  (rowClicked)="onRowClicked($event)"
>
</ag-grid-angular>
