
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CheckboxSelectionCallbackParams,
  ColDef,
  ColumnApi,
  GridApi
} from 'ag-grid-community';
import { BoolCellRendererComponent } from 'src/app/bool-cell-renderer/bool-cell-renderer.component';
import { DownloadService } from 'src/app/download.service';
import { EdwayDatasource } from 'src/app/edway-datasource';
import { EdwayService } from 'src/app/edway.service';
import { GsmCellRendererComponent } from 'src/app/gsm-cell-renderer/gsm-cell-renderer.component';
import { OnlineCellRendererComponent } from 'src/app/online-cell-renderer/online-cell-renderer.component';
import { TimestampCellRendererComponent } from 'src/app/timestamp-cell-renderer/timestamp-cell-renderer.component';
import { VehicleCellRendererComponent } from 'src/app/vehicle-cell-renderer/vehicle-cell-renderer.component';
import { EditComponent } from '../edit/edit.component';
import { DiscoveryOnlineCellRendererComponent } from '../discovery-online-cell-renderer/discovery-online-cell-renderer.component';

function any(iterable) {
  for (var index = 0; index < iterable.length; index++) {
      if (iterable[index]) return true;
  }
  return false;
}

@Component({
  selector: 'hopon-discovery-list',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.scss'],
  providers: [EdwayDatasource],
})
export class DiscoveryComponent implements OnInit {

  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];
  rowData: any[];

  private gridColumnApi!: ColumnApi;
  private gridApi!: GridApi;

  @Input() fleet: string;
  int: any;

  rowsSelected = [];

  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    public dialog: MatDialog,
    public tr: TranslateService,
    private ds: EdwayDatasource,
    private _snackBar: MatSnackBar,
    private downloadService: DownloadService
  ) {}

  selectRow(params: CheckboxSelectionCallbackParams): boolean {
    this.rowsSelected = params.api.getSelectedRows();
    console.log(this.rowsSelected);
    return true;
  }

  async ngOnInit() {
    let ret = [];

    this.ds.setUrl(this.e.endpoints.vehicles);

    this.ds.setPaging(5000, 5000);
    
    await this.tr.get('dummyTranslation').toPromise().then();
    this.columnDefs = [
      {
        suppressSizeToFit: true,
        maxWidth: 200,
        minWidth: 150,
        checkboxSelection: true,
        field: 'edway.name',
        headerName: this.tr.instant('fields.vehicles.name.internal.label'),
        headerTooltip: this.tr.instant(
          'fields.vehicles.name.internal.description'
        ),
        width: 150,
      },
      {
        suppressSizeToFit: true,
        maxWidth: 200,
        minWidth: 150,
        field: 'ident',
        headerName: this.tr.instant('fields.vehicles.imei.label'),
        headerTooltip: this.tr.instant('fields.vehicles.imei.description'),
        width: 150,
      },
      {
        suppressSizeToFit: true,
        maxWidth: 175,
        minWidth: 75,
        field: 'edway.status',
        headerName: this.tr.instant('fields.discovery.online.label'),
        cellRenderer: DiscoveryOnlineCellRendererComponent,
        headerTooltip: this.tr.instant('fields.discovery.online.description'),
        width: 75,
      },
      {
        suppressSizeToFit: true,
        maxWidth: 175,
        minWidth: 75,
        field: 'edway.battery',
        filter: 'agNumberColumnFilter',
        headerName: this.tr.instant('fields.vehicles.battery.label'),
        cellRenderer: VehicleCellRendererComponent,
        headerTooltip: this.tr.instant('fields.vehicles.battery.description'),
        width: 75,
      },
      {
        suppressSizeToFit: true,
        maxWidth: 175,
        minWidth: 75,
        field: 'gsm.signal.level',
        filter: 'agNumberColumnFilter',
        headerName: this.tr.instant('fields.vehicles.gsm.label'),
        cellRenderer: GsmCellRendererComponent,
        headerTooltip: this.tr.instant('fields.vehicles.gsm.description'),
        width: 75,
      },

      {
        suppressSizeToFit: true,
        maxWidth: 150,
        minWidth: 120,
        field: 'edway.model',
        headerName: this.tr.instant('fields.vehicles.model.name.label'),
        headerTooltip: this.tr.instant(
          'fields.vehicles.model.name.description'
        ),
        width: 120,
      },
      {
        suppressSizeToFit: true,
        maxWidth: 150,
        minWidth: 100,
        field: 'edway.vtype',
        headerName: this.tr.instant('fields.vehicles.model.vehicle_type.label'),
        headerTooltip: this.tr.instant(
          'fields.vehicles.model.vehicle_type.description'
        ),
        width: 100,
      },
      {
        suppressSizeToFit: true,
        maxWidth: 250,
        minWidth: 200,
        field: 'timestamp',
        headerName: this.tr.instant('fields.vehicles.last_seen.label'),
        cellRenderer: TimestampCellRendererComponent,
        headerTooltip: this.tr.instant('fields.vehicles.last_seen.description'),
        width: 200,
      }
    ];

    this.ds.setPostOperartions((d) => {
      const f = this.gridApi.getFilterModel();
      console.log(f);
      for (let k in f) {
        d = d.filter((xx) => {
          if (xx[k]) {
            if (f[k].filterType === 'text') {
              if (f[k].operator){ //conditions

              }
              else if (f[k].filter.indexOf(' OR ') >= 0){
                const fc = f[k].filter.split(' OR ');
                const fo = fc.map(x=> xx[k].toLowerCase().indexOf(x.toLowerCase()) >= 0)
                return any(fo);
              }
              else {
                return (
                  xx[k].toLowerCase().indexOf(f[k].filter.toLowerCase()) >= 0
                );
              }
            } else if (f[k].filterType === 'number') {
              if (f[k].type === 'lessThanOrEqual')
                return xx[k] <= f[k].filter;
            } else if (typeof xx[k] === 'object') {
              if (length in xx[k]) {
                // array
                return (
                  xx[k]
                    .join(',')
                    .toLowerCase()
                    .indexOf(f[k].filter.toLowerCase()) >= 0
                );
              }
            }
          }
        });
      }

      function compare(field, dir) {
        return function do_compare(a, b) {
          if (a[field] < b[field]) {
            return dir === 'asc' ? -1 : 1;
          }
          if (a[field] > b[field]) {
            return dir === 'asc' ? 1 : -1;
          }
          return 0;
        };
      }

      const s = this.gridColumnApi.getColumnState().filter((x) => x.sort);

      for (let so of s) {
        d.sort(compare(so.colId, so.sort));
      }

      return d;
    });
    this.update();
  }

  onRowClicked(ev) {
    this.r.navigate(['/discovery', ev.data.ident]);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api!.setDatasource(this.ds);
  }

  update(triggered = false) {
    this.show = false;
    if (triggered) {
      this.gridApi.purgeInfiniteCache();
    }
    const params = {};
    if (this.fleet) {
      params['fleet'] = this.fleet;
    }
    //this.e.getVehicles(params).subscribe((data: any[]) => {
    //  this.rowData =
    //  data.map(x=>{
    //    x.name = {};
    //    for(let c of x.codes){
    //      x.name[c.context] = c.code;
    //    }
    //    return x;
    //  });
    //  this.show = true;
    //})
  }

  ngOnDestroy(): void {
    clearInterval(this.int);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditComponent, {
      width: '670px',
      data: { op: 'Add', vehicles: [{ imei: null, model: null, fleet: null }] },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.update();
    });
  }

  download(url) {
    this.downloadService
      .download(
        url,
        {
          headers: {
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        },
        'vehicles'
      )
      .subscribe(() => {});
  }

  apply(filter) {
    console.log(filter);
    if (filter.type){
      const f = this.gridApi.getFilterInstance('edway.vtype');
      f.setModel({
        filterType: 'text',
        type: 'contains',
        filter: filter.type.join(' OR '),
      });
    }
    if (filter.maxBattery){
      const j = this.gridApi.getFilterInstance('edway.battery');
      j.setModel({
        filterType: 'number',
        type: 'lessThanOrEqual',
        filter: filter.maxBattery,
      });
    }
    this.gridApi.onFilterChanged();
  }

  run(action) {
    for (let row of this.gridApi.getSelectedRows()) {
      let speed = 0;
      const params = {};
      if (action === 'setmax') {
        try {
          speed = parseInt(
            prompt(this.tr.instant('commands.max_speed_prompt'))
          );
          params['speed'] = speed;
        } catch (ex) {
          console.log(ex);
          alert(this.tr.instant('commands.max_speed_parameter_error'));
        }
      }
      if (confirm(this.tr.instant('commands.confirm_command'))) {
        this.e.doCommand(action, row.imei, params).subscribe((data: any) => {
          if (data.result === 'ok') {
            this._snackBar.open(
              this.tr.instant('commands.command_sent', { action: action }),
              'ok',
              { duration: 2000 }
            );
          } else {
            this._snackBar.open(
              this.tr.instant('commands.command_failed', {
                action: action,
                reason: data.reason,
              }),
              'ok',
              { duration: 2000 }
            );
          }
          this.update();
        });
      }
    }
  }

  setStatus(status){
    
  }

  getRowStyle(params) {
    console.log(params.timestamp);
  }
}
