import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CacheService } from 'src/app/cache.service';
import { EdwayService } from 'src/app/edway.service';
import { AmendComponent } from 'src/app/payments/amend/amend.component';

@Component({
  selector: 'edway-packages-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  @Input() pricings: string;
  @Input() refreshList: boolean = false;

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private tr: TranslateService,
    private c: CacheService
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    let ret = [];

    this.load();

    this.columnDefs = [
      {
        field: 'visible',
        headerName: this.tr.instant('packages.user_visible'),
        valueFormatter: boolFormatter,
      },
      { field: 'name', headerName: this.tr.instant('common.name') },
      {
        field: 'vehicle_type',
        headerName: this.tr.instant('common.vehicle_type'),
      },
      {
        field: 'vehicle_model',
        headerName: this.tr.instant('common.vehicle_model'),
        valueFormatter: (params) => {
          return this.c.get('models', params.value);
        },
      },
      {
        field: 'fleet',
        headerName: this.tr.instant('common.fleet'),
        valueFormatter: (params) => {
          return this.c.get('fleets', params.value);
        },
      },
      {
        field: 'seconds',
        headerName: this.tr.instant('common.duration'),
        valueFormatter: (params) => {
          if (params.value) {
            const v = params.value;
            const s = v % 60;
            const m = Math.floor(v / 60) % 60;
            const h = Math.floor(v / 3600) % 60;
            return '' + h + 'h ' + m + 'min ' + s.toFixed(0) + 's';
          } else {
            return '';
          }
        },
      },
      {
        field: 'in_timeframe',
        headerName: this.tr.instant('common.timeframe'),
        valueFormatter: (params) => {
          if (params.value) {
            const v = params.value;
            const s = v % 60;
            const m = Math.floor(v / 60) % 60;
            const h = Math.floor(v / 3600) % 60;
            return '' + h + 'h ' + m + 'min ' + s.toFixed(0) + 's';
          } else {
            return '';
          }
        },
      },
      {
        field: 'from_activation',
        headerName: this.tr.instant('common.from_activation'),
        valueFormatter: boolFormatter,
      },
      // {
      //   field: 'times',
      //   headerName: this.tr.instant('common.times'),
      // },
      {
        field: 'price',
        headerName: this.tr.instant('common.price'),
        valueFormatter: currencyFormatter,
      },
      {
        field: 'unlock_price',
        headerName: this.tr.instant('common.unlock_price'),
        valueFormatter: currencyFormatter,
      },
      {
        field: 'unlock_limit',
        headerName: this.tr.instant('common.unlock_limit'),
      },

      {
        field: 'actions',
        filter: false,
        cellRenderer: AmendComponent,
        cellRendererParams: {
          buttons: [
            {
              label: 'ops.delete',
              icon: 'delete',
              condition: (params: any) => {
                return true;
              },
              clicked: (params: any) => {
                const d = this.tr.instant('ops.confirm_deletion', {
                  itm: `'${params.name}'`,
                });
                if (confirm(d)) {
                  this.deletePackage(params.id);
                }
              },
            },
            {
              label: 'packages.show_hide_user',
              icon: 'visibility',
              condition: (params: any) => {
                return true;
              },
              clicked: (params: any) => {
                const d = this.tr.instant('ops.confirm_toggle_visibility', {
                  itm: `'${params.name}'`,
                });
                if (confirm(d)) {
                  this.togglePackageVisibility(params);
                }
              },
            },
          ],
        },
        headerName: this.tr.instant('fields.payments.actions.label'),
      },
    ];
  }

  ngOnChanges() {
    if (this.refreshList) {
      this.load();
      this.refreshList = false;
    }
  }

  deletePackage(id): void {
    this.e.deleteProduct(id).subscribe((data) => {
      this.load();
    });
  }

  togglePackageVisibility(product): void {
    const data = { ...product, visible: !product.visible };

    this.e.updateProduct(product.id, data).subscribe((data) => {
      this.load();
    });
  }

  load() {
    this.e.getProducts().subscribe((data: any[]) => {
      this.list = data;
      this.show = true;
    });
  }
}

function currencyFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return (
      params.data.currency.replace('EUR', '€ ').replace('eur', '€ ') +
      params.value.toFixed(2)
    );
  } else {
    return null;
  }
}

function boolFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return '✅';
  } else {
    return '❌';
  }
}
