import { Component, OnInit } from '@angular/core';
import { EdwayService } from '../edway.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile: any;

  telegramBotName = "";

  constructor(
    private e: EdwayService
  ) { }

  ngOnInit(): void {
    this.e.getProfile().subscribe(data => {
      this.profile = data;
    })
  }

  onTelegramLogin(ev){

  }

}
