import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '../shared.module';
import { DeleteCellRendererComponent } from './delete-cell-renderer.component';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SettingsComponent,
    DeleteCellRendererComponent,
    AddDialogComponent,
  ],
  imports: [AgGridModule, CommonModule, SharedModule, TranslateModule],
  exports: [SettingsComponent],
})
export class SettingsModule {}
