export class CreateCoupon {
  public couponModelId: number;
  public code?: string;
  public expirationDate?: Date|string;
  public maxUses?: number;
  public variables?: { [key: string]: string };
  constructor() {
    this.expirationDate = null;
    this.maxUses = null;
    this.variables = {};
  }
}
