import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { SharedModule } from '../shared.module';
import { GeneralComponent } from './general/general.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';



@NgModule({
  declarations: [
    MainComponent,
    GeneralComponent,
    OverviewComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    TranslateModule,
  ]
})
export class StatsModule { }
