import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class DateUtilService {
  constructor(private datePipe: DatePipe) {}

  dateTableFormatter(value: ValueFormatterParams, format = 'HH:mm dd/MM/yy') {
    if (value?.value) {
      return this.datePipe.transform(value.value, format);
    } else return null;
  }
}
