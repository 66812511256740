import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientinfo',
  templateUrl: './clientinfo.component.html',
  styleUrls: ['./clientinfo.component.scss']
})
export class ClientinfoComponent implements OnInit {

  app = {
    apple: 'network-world-travel/id6447265858',
    android: ''
  }

  constructor() {
    console.log('ClientinfoComponent');
  }

  ngOnInit(): void {
  }

}
