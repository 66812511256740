import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EdwayService } from '../edway.service';
import { SettingsService } from '../settings.service';

declare const mapboxgl: any;

@Component({
  selector: 'edway-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  mapbox_key: string;
  map: any;

  ti: any;

  

  vehicles = {
    type: 'FeatureCollection',
    features: [],
  };
  areas = {
    type: 'FeatureCollection',
    features: [],
  };

  filter = ['ebike', 'moped', 'scooter', 'car'];
  mapmode = "current";

  from_time = "";
  to_time = "";

  keeprefreshing = false;

  @Input() fleet: string;

  constructor(
    private e: EdwayService,
    private s: SettingsService,
    private ar: ActivatedRoute,
    private r: Router
  ) {}

  ngOnInit(): void {
    this.s.settings$.subscribe( (settingsOk: boolean) =>{
      if(settingsOk) {
        this.mapbox_key = this.s.get('mapbox_key');
        mapboxgl.accessToken =
          'pk.eyJ1Ijoic2lybW1vIiwiYSI6ImNsM3R2djh5ajBjdTgzam52NDBvZzVhcXEifQ.wSyHLLyQ7X5SmSXHLw5clA';
        this.initMap();
      }
    });

    setInterval(()=>{
      if(this.keeprefreshing)
        this.update();
    },5000)
  }

  

  initMap(){
    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [11.360105, 41.754922], // starting position [lng, lat]
      zoom: 5, // starting zoom
      hash: true,
    });

    // Add geolocate control to the map.
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );

    this.map.on('load', () => {
      
      this.e.resize.subscribe(() => {
        setTimeout(() => {
          this.map.resize();
        }, 250);
      });

      this.map.addSource('areas', {
        type: 'geojson',
        data: this.areas,
      });
      this.map.addLayer({
        id: 'areas',
        source: 'areas',
        type: 'fill',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.2,
        },
      });

      this.map.addSource('vehicles', {
        type: 'geojson',
        data: this.vehicles,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 25, // Radius of each cluster when clustering points (defaults to 50)
      });

      this.map.addSource('track', {
        type: 'geojson',
        data: this.vehicles,
      });

      this.map.addSource('track_line', {
        type: 'geojson',
        data: this.vehicles,
      });

      
      this.map.addLayer({
        id: 'track-line',
        source: 'track_line',
        type: 'line',
        filter: ['==', 'type', 'track'],
        paint: {
          'line-color': ["get", "color"],
          'line-width': 2
        },
      });
      this.map.addLayer({
        id: 'track-crash',
        source: 'track',
        type: 'circle',
        filter: ["all", ['==', 'type', 'point'], ["==", "crash", true]],
        paint: {
          'circle-color': "#ff0000",
          'circle-radius': 8
        },
      });
      this.map.addLayer({
        id: 'track-evts',
        source: 'track',
        type: 'circle',
        filter: ['has', 'event'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'event'],
            '#51bbd6',
            240,
            '#f1f075',
            300,
            '#f28cb1',
          ],
          'circle-radius': 6
        },
      });
      this.map.addLayer({
        id: 'track-pts',
        source: 'track',
        type: 'circle',
        filter: ['==', 'type', 'point'],
        paint: {
          'circle-color': ["get", "color"],
          'circle-radius': 3
        },
      });

      this.map.addLayer({
        id: 'track-lbls',
        source: 'track',
        type: 'symbol',
        filter: ['==', 'type', 'point'],
        layout: {
          "text-field": "{timestamp}",
          "text-offset": [0,1],
          "text-size":7
        },
      });
      this.map.addLayer({
        id: 'track-evts-lbls',
        source: 'track',
        type: 'symbol',
        filter: ['==', 'type', 'point'],
        layout: {
          "text-field": "{event}",
          "text-offset": [0,-1],
          "text-size":12
        },
      });

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'vehicles',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'vehicles',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });
      this.map.addLayer({
        id: 'vehicles',
        source: 'vehicles',
        type: 'circle',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'battery'],
            0,
            '#000000',
            25,
            '#ff0000',
            50,
            '#ffff00',
            75,
            '#00ff00',
          ],
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 5, 3, 15, 12],
          'circle-stroke-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            5,
            0,
            15,
            1,
          ],
          'circle-stroke-color': '#fff',
        },
      });
      this.map.addLayer({
        id: 'vehicles-labels',
        source: 'vehicles',
        minzoom: 15,
        type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '{type} - {name}',
          'text-offset': [0, 1],
        },
      });

      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        this.map
          .getSource('vehicles')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      this.e.getAreas().subscribe((data) => {
        this.map.getSource('areas').setData(data);
      });

      this.update();

      this.map.on('mouseenter', 'vehicles', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'vehicles', () => {
        this.map.getCanvas().style.cursor = '';
      });

      this.map.on('click', 'vehicles', (e) => {
        // Set `bbox` as 5px reactangle area around clicked point.
        const bbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5],
        ];
        // Find features intersecting the bounding box.
        const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
          layers: ['vehicles'],
        });

        if (selectedFeatures.length == 2) {
          console.log(selectedFeatures);
          this.r.navigate(['vehicles', selectedFeatures[0].properties.ident], {
            relativeTo: this.ar.parent,
          });
        }
      });

      this.ar.params.subscribe((data) => {
        if (data.vehicle) {
          this.mapmode = "current"
          this.e.getVehicleCurrent(data.vehicle).subscribe((vd: any) => {
            const marker = new mapboxgl.Marker()
        .setLngLat(vd.features[0].geometry.coordinates)
        .addTo(this.map);
            this.map.easeTo({
              center: vd.features[0].geometry.coordinates,
              zoom: 16,
            });
          });
        }
        if (data.track) {
          this.mapmode = "track";
          const tracks = data.track.split('|');
          this.from_time = data.from;
          this.to_time = data.to;
          for (let track of tracks){
            this.e.getVehicleLogs(track, data.from, data.to).subscribe((vl: any[]) => {
              const randomColor = Math.floor(Math.random()*16777215).toString(16);
              const trck = {
                type: 'FeatureCollection',
                features: [],
              };
              const trck_l = {
                type: 'FeatureCollection',
                features: [],
              };
              const itms = [];
              for (let vli of vl) {
                if(vli.lng !== null && vli.lat !== null){
                  vli['type'] = 'point';
                  vli['event'] = vli.data['event.enum'];
                  vli['crash'] = vli.data['crash.event'];
                  vli['color'] = "#" + randomColor;
                  trck['features'].push({
                    type: 'Feature',
                    properties: vli,
                    geometry: {
                      type: 'Point',
                      coordinates: [vli.lng, vli.lat],
                    },
                  });
                  itms.push([vli.lng, vli.lat]);
                }
              }
              trck_l['features'].push({
                type: 'Feature',
                properties: { 
                  type: 'track', 
                  ident: track,
                  color: "#" + randomColor
                },
                geometry: {
                  type: 'LineString',
                  coordinates: itms,
                },
              });
  
              this.map.getSource('track').setData(trck);
              this.map.getSource('track_line').setData(trck_l);
            });
          }
        }
      });
    });
  }

  update() {
    this.e
      .getVehiclesMap(this.map.getBounds(), this.map.getZoom(), {
        filter: this.filter,
        fleet: this.fleet,
      })
      .subscribe((data:any) => {
        this.vehicles=data;
        this.map.getSource('vehicles').setData(data);
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.ti);
  }

  apply(filter){
    console.log(filter);
    const data = {type:'FeatureCollection', features: this.vehicles.features.filter(x=>filter.type.indexOf(x.properties.type) >= 0 && x.properties.battery < filter.maxBattery)};
    this.map.getSource('vehicles').setData(data);
  }

}
