import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements ICellRendererAngularComp {
    refresh(params: ICellRendererParams): boolean {
      throw new Error('Method not implemented.');
    }
  
    public params: any;
  
    agInit(params: any): void {
      this.params = params;
    }
  
    btnClickedHandler(button, event) {
      button.clicked(this.params.data);
      event.preventDefault();
      event.stopPropagation();
    }
}
