<h1 mat-dialog-title>{{ "common.package" | translate }}</h1>
<mat-dialog-content class="container">
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.name" | translate }}</mat-label>
    <input type="string" matInput [(ngModel)]="data.name" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.vehicle_type" | translate }}</mat-label>
    <mat-select type="number" matInput [(ngModel)]="data.vehicle_type">
      <mat-option value="scooter">{{
        "vehicletype.scooter" | translate
      }}</mat-option>
      <mat-option value="moped">{{
        "vehicletype.moped" | translate
      }}</mat-option>
      <mat-option value="ebike">{{
        "vehicletype.ebike" | translate
      }}</mat-option>
      <mat-option value="car">{{ "vehicletype.car" | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.vehicle_model" | translate }}</mat-label>
    <mat-select type="number" matInput [(ngModel)]="data.vehicle_model">
      <mat-option *ngFor="let f of models | async" [value]="f.ident">{{
        f.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.fleet" | translate }}</mat-label>
    <mat-select type="number" matInput [(ngModel)]="data.fleet">
      <mat-option *ngFor="let f of fleets | async" [value]="f.id">{{
        f.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="row">
    <mat-form-field appearance="fill">
      <mat-label>{{ "common.duration" | translate }}</mat-label>
      <input type="number" matInput [(ngModel)]="data.duration" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ "common.duration_unit" | translate }}</mat-label>
      <mat-select
        type="number"
        matInput
        [(ngModel)]="data.duration_unit"
        required
      >
        <mat-option value="s">{{ "time_unit.s" | translate }}</mat-option>
        <mat-option value="m">{{ "time_unit.m" | translate }}</mat-option>
        <mat-option value="h">{{ "time_unit.h" | translate }}</mat-option>
        <mat-option value="d">{{ "time_unit.d" | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="fill">
      <mat-label>{{ "common.timeframe" | translate }}</mat-label>
      <input type="number" matInput [(ngModel)]="data.timeframe" required />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ "common.timeframe_unit" | translate }}</mat-label>
      <mat-select
        type="number"
        matInput
        [(ngModel)]="data.timeframe_unit"
        required
      >
        <mat-option value="s">{{ "time_unit.s" | translate }}</mat-option>
        <mat-option value="m">{{ "time_unit.m" | translate }}</mat-option>
        <mat-option value="h">{{ "time_unit.h" | translate }}</mat-option>
        <mat-option value="d">{{ "time_unit.d" | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- <mat-form-field appearance="fill">
    <mat-label>{{ "common.times" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.times" />
  </mat-form-field> -->
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.currency" | translate }}</mat-label>
    <mat-select type="string" matInput [(ngModel)]="data.currency" disabled>
      <mat-option value="eur">EUR</mat-option>
      <mat-option value="usd">USD</mat-option>
      <mat-option value="chf">CHF</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.price" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.price" required />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.price_per_minute" | translate }}</mat-label>
    <input
      type="number"
      matInput
      [(ngModel)]="data.price_per_minute"
      disabled
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.unlock_price" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.unlock_price" disabled />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ "common.unlock_limit" | translate }}</mat-label>
    <input type="number" matInput [(ngModel)]="data.unlock_limit" />
  </mat-form-field>
  <mat-checkbox [(ngModel)]="data.from_activation">{{
    "common.from_activation" | translate
  }}</mat-checkbox>
  <!-- <mat-checkbox [(ngModel)]="data.active" [value]="true">{{
    "common.active" | translate
  }}</mat-checkbox> -->
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">{{ "ops.cancel" | translate }}</button>
  <button mat-button (click)="save()">{{ "ops.ok" | translate }}</button>
</mat-dialog-actions>
