export class CouponModel {
  public id: number;
  public name: string;
  public description?: string;
  public actions: Record<string, any>;
  public validation?: Record<string, any>;
  public hasExpirationDate: boolean;
  public hasMaxUses: boolean;
  public tenant?: string;
  public createdAt: Date;
  public updatedAt: Date;
}
