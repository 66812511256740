<mat-toolbar>
    <h1 translate>models.title</h1>
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [defaultColDef]="defaultColDef"
    [headerHeight]="36"
    [rowHeight]="40"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (rowClicked)="onRowClicked($event)"
    >
</ag-grid-angular>

<input id="files" type="file" style="display:none" (change)="handle($event)">