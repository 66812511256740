import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  EventEmitter,
} from '@angular/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss'],
})
export class PoiComponent implements OnInit {
  isNewRecord = true;
  @Input() tags_list: any;
  @Input() itineraries_list: any;
  @Input() eventEmitter: EventEmitter<number>;
  @Input() addClicked: EventEmitter<any>;
  @Input() addPoiToMap: EventEmitter<any>;
  @Input() poiAdded: EventEmitter<any>;
  item_id: any = null;

  exps = [];

  poi_schema = {
    name: '',
    location: '',
    point: '',
    radius: 0,
    description: '',
    gallery: [],
    tags: [],
    hours: 0,
    video: '',
    icon: '',
    audio_guide: false,
    openstreetmap_id: '',
  };

  poi: any = this.poi_schema;

  constructor(private e: EdwayService) {}

  ngOnInit(): void {
    this.subscribeToParentEmitter();
  }

  subscribeToParentEmitter(): void {
    this.eventEmitter.subscribe((data: string) => {
      this.item_id = data;

      this.edit();
    });

    this.addClicked.subscribe((data) => {
      this.new();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  save() {
    const post_data = {
      ...this.poi,
    };

    if (this.isNewRecord) {
      console.log('Salvataggio di un nuovo record:', this.poi);

      this.e.addPoi(post_data).subscribe((data) => {
        alert('Salvataggio completato');
        window.location.reload();
      });
    } else {
      console.log(
        'Salvataggio della modifica del record:',
        this.item_id,
        this.poi
      );

      // this.e.updatePoi(this.item_id, post_data).subscribe((data) => {
      //   alert('Salvataggio completato');
      //   // window.location.reload();
      // });
    }
  }

  new() {
    this.isNewRecord = true;
    this.poi = this.poi_schema;
  }

  edit() {
    this.isNewRecord = false;
    this.loadPoiData(this.item_id);
  }

  loadPoiData(id: string) {
    this.e.getSinglePoi(id, true).subscribe((data) => {
      console.log(data);
      this.poi = data;
    });

    // this.poi = {
    //   name: 'Museo',
    //   // owner: 'owner',
    //   location: '123 Strada',
    //   point: 'Lat, Lng',
    //   radius: 100,
    //   description: 'Desc',
    //   gallery: ['Immagine1.jpg', 'Immagine2.jpg', 'Immagine3.jpg'],
    //   tags: ['Arte', 'Cultura', 'Museo'],
    //   hours: 5,
    //   video: 'https://www.youtube.com/watch?v=example',
    //   icon: 'museo-icon.png',
    //   audio_guide: true,
    //   openstreetmap_id: id,
    // };
  }

  addExperience() {
    this.exps.push({ owner: 'system', pois: [this.poi.id] });
  }

  createLocation() {
    this.addPoiToMap.emit();
  }
}
