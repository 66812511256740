<mat-paginator></mat-paginator>
<ag-grid-angular
  *ngIf="show"
  class="fullpage ag-theme-alpine"
  [rowData]="rowData"
  [defaultColDef]="defaultColDef"
  [headerHeight]="36"
  [columnDefs]="columnDefs"
  (rowClicked)="onRowClicked($event)"
>
</ag-grid-angular>
