import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';
import { SettingsService } from 'src/app/settings.service';
import { AreaDrawComponent } from '../area-draw/area-draw.component';
import { centroid } from '@turf/turf';
import { AddAreaSettingComponent } from '../add-area-setting/add-area-setting.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';

declare const mapboxgl: any;
declare const MapboxDraw: any;
declare const MapboxGeocoder: any;
declare const L: any;

@Component({
  selector: 'edway-fleet-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss']
})
export class AreasComponent implements OnInit, OnChanges {
  @Input() fleet;
  @Input() pagemode ="fleets";
  areas;
  mapbox_key;
  map:any;
  mode = "viewing"
  atype = "";

  selected = null;
  
  adraw = new MapboxDraw({
    controls: {
      "draw_polygon":true,
      "point": false,
      "polygon": true,
      "line_string": false,
      "trash": false,
      "combine_features": false,
      "uncombine_features": false,
    }
  });
  pdraw = new MapboxDraw({
    controls: {
      "draw_polygon":false,
      "point": true,
      "polygon": false, 
      "line_string": false,
      "trash": false,
      "combine_features": false,
      "uncombine_features": false,
    }
  });

  @Output() triggerUpdate: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private e: EdwayService,
    private s: SettingsService,
    private d: MatDialog,
    private tr: TranslateService,
    public a: AuthService
  ) { }

  ngOnInit(): void {
    this.tr.get('simple').toPromise().then();
    this.mapbox_key = this.s.get('mapbox_key');
    mapboxgl.accessToken = 'pk.eyJ1Ijoic2lybW1vIiwiYSI6ImNsM3R2djh5ajBjdTgzam52NDBvZzVhcXEifQ.wSyHLLyQ7X5SmSXHLw5clA';
    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [11.360105, 41.754922], // starting position [lng, lat]
      zoom: 5 // starting zoom
    });

    this.map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
      })
    );
      
  }

  ngOnChanges(changes: SimpleChanges) {
    this.areas = {
      type: 'FeatureCollection',
      features : changes.fleet.currentValue.areas.map(x=>{
        const poly = x.polygon
        poly.properties = x
        return poly;
      })
    }

    console.log(this.areas);
    setTimeout(()=>{
      this.map.on('load', ()=>{
        this.map.addSource("areas", {
          type: "geojson",
          data: {type: 'FeatureCollection', features:[]}
        });
        this.map.addSource("active", {
          type: "geojson",
          data: {type: 'FeatureCollection', features:[]}
        });
        this.map.addLayer({
          id: "areas-all",
          source: "areas",
          type: 'fill',
          paint:{
            "fill-color": '#999999',
            "fill-opacity": 0.1
          }
        })
        this.map.addLayer({
          id: "areas-ok",
          source: "areas",
          type: 'fill',
          filter: ["==", ['get', 'can_park'], "True"],
          paint:{
            "fill-color": '#00ff00',
            "fill-opacity": 0.2
          }
        })
        this.map.addLayer({
          id: "areas-nogo",
          source: "areas",
          type: 'fill',
          filter: ["!=", ['get', 'shut_down'], null],
          paint:{
            "fill-color": '#000000',
            "fill-opacity": 0.2
          }
        })
        this.map.addLayer({
          id: "areas-nok",
          source: "areas",
          type: 'fill',
          filter: ["==", ['get', 'can_park'], "False"],
          paint:{
            "fill-color": '#ff0000',
            "fill-opacity": 0.2
          }
        })
        this.map.addLayer({
          id: "areas-speed",
          source: "areas",
          type: 'fill',
          filter: ["!=", ['get', 'max_speed'], null],
          paint:{
            "fill-color": '#0000ff',
            "fill-opacity": 0.2
          }
        })
        this.map.addLayer({
          id: "active",
          source: "active",
          type: 'line',
          paint:{
            "line-color": '#ff0000',
            "line-width": 2
          }
        })
        this.e.getAreas().subscribe(data=>{
          this.areas = data;
          this.map.getSource('areas').setData(this.areas);
        })
      });
    }, 500);
  }

  show(e){
    const area = e;
    console.log(area);
    this.map.getSource('active').setData({type:"FeatureCollection", features:[{type:"Feature", properties: {}, geometry: area.polygon.geometry}]});
    const ctr = centroid(area.polygon.geometry);
    
    this.map.panTo(ctr.geometry.coordinates);
    setTimeout(()=>{
      this.map.zoomTo(15);
    },500);
  }

  addArea(){
    this.atype = "area";
    const areaName = prompt(this.tr.instant("fleets.area.add.area.prompt"));
    if (areaName){
      this.e.addArea(this.fleet, areaName).subscribe((ret: {id, fleet}) =>{
        this.fleet.areas.push({name:areaName, settings: [], });
        this.e.savePolygon(this.fleet.id, ret.id, {
          type: "Feature", 
          geometry: {type: "Point", coordinates:[this.map.getCenter().lng, this.map.getCenter().lat]},
          properties: {}
        }).subscribe(data=>{
          this.triggerUpdate.emit();
        })
        //this.d.open(AreaDrawComponent, {data:{area: ret.id}, width:'1024px', height: '768px'});
      })
    }
  }

  addMarker(){
    this.atype = "point";
    const areaName = prompt(this.tr.instant("fleets.area.add.marker.prompt"));
    if (areaName){
      this.e.addArea(this.fleet, areaName).subscribe((ret: {id, fleet}) =>{
        this.fleet.areas.push({name:areaName, settings: [], });
        this.e.savePolygon(this.fleet.id, ret.id, {
          type: "Feature", 
          geometry: {type: "Point", coordinates:[this.map.getCenter().lng, this.map.getCenter().lat]},
          properties: {}
        }).subscribe(data=>{
          this.triggerUpdate.emit();
        })
        //this.d.open(AreaDrawComponent, {data:{area: ret.id}, width:'1024px', height: '768px'});
      })
    }
  }

  
  addBeacon(){
    this.atype = "point";
    const areaName = prompt(this.tr.instant("fleets.area.add.marker.prompt"));
    if (areaName){
      this.e.addArea(this.fleet, areaName).subscribe((ret: {id, fleet}) =>{
        this.fleet.areas.push({name:areaName, settings: [], });
        this.e.savePolygon(this.fleet.id, ret.id, {
          type: "Feature", 
          geometry: {type: "Point", coordinates:[this.map.getCenter().lng, this.map.getCenter().lat]},
          properties: {}
        }).subscribe(data=>{
          this.triggerUpdate.emit();
        })
        //this.d.open(AreaDrawComponent, {data:{area: ret.id}, width:'1024px', height: '768px'});
      })
    }
  }

  delete(fleet, area){
    if(confirm('Delete Area?')){
      this.e.deleteArea(fleet, area).subscribe(()=>{
        this.triggerUpdate.emit();
      })
    }
  }

  edit(area){
    this.mode = "editing";
    try{
      if (this.atype === 'area')
        this.map.removeControl(this.adraw);
      else 
        this.map.removeControl(this.pdraw);
    } catch (ex) {}
    
    if (this.atype === 'area')
      this.map.addControl(this.adraw, 'top-right');
    else 
      this.map.addControl(this.pdraw, 'top-right');
    const poly = area.polygon;
    delete poly.properties.polygon;
    this.adraw.add(poly);
    
    //this.d.open(AreaDrawComponent, {data:{area: area.id, polygon:area.polygon}, width:'1024px', height: '768px'});
  }

  save(area){
    let d = null;
    if (this.atype === 'area')
      d = this.adraw;
    else 
      d = this.pdraw;
    const fl = d.getAll().features.length
    const geoj = d.getAll().features[fl-1];
    console.log(geoj);
    this.e.savePolygon(this.fleet, area.id, geoj).subscribe(data=>{
      d.deleteAll();
      this.map.removeControl(d);
      setTimeout(() =>{
        this.e.getAreas().subscribe(data=>{
          this.areas = data;
          this.map.getSource('areas').setData(this.areas);
        })
        this.mode = "viewing";
        this.triggerUpdate.emit();
      }, 250);
    })
  }

  addSetting(area){
    let dr = this.d.open(AddAreaSettingComponent, {data: {area:area.id, fleet: this.fleet.id, pagemode: this.pagemode}});
    dr.afterClosed().subscribe(result=>{
      area.settings.push({key:result.key, value: result.value});
      this.triggerUpdate.emit();
    })
  }

  
  deleteAreaSetting(fleet, area, key){
    if(confirm('delete setting for fleet area?')){
      this.e.deleteAreaSetting(fleet, area, key).subscribe(data=>{
        this.selected.settings = this.selected.settings.filter(x=>x.key !== key);
        this.triggerUpdate.emit();
      })
    }
  }

  refreshCached(){
    this.e.refreshCachedArea().subscribe(x=>{
      alert('Area aggiornata');
    })
  }
}
