export class Coupon {
  public id: number;
  public code: string;
  public actions: Record<string, any>;
  public validation?: Record<string, any>;
  public expirationDate?: Date;
  public maxUses?: number;
  public usesCount: number;
  public tenant?: string;
  public disabled: boolean;
  public redirect?: string;
  public createdAt: Date;
}
