<h1 mat-dialog-title>{{'coupons.active.create.title'|translate}}</h1>
<div mat-dialog-content>
    <p>{{'coupons.active.create.activate'|translate}}</p>
    <div class="column">
        <mat-form-field appearance="fill">
            <mat-label>{{'coupons.active.model.label'|translate}}</mat-label>
            <mat-select [(ngModel)]="selected" name="models">
                <mat-option *ngFor="let model of data.models" [value]="model">
                    {{model.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="column" *ngIf="selected">
            <mat-form-field appearance="fill">
                <mat-label>Codice</mat-label>
                <input matInput [(ngModel)]="output.code" name="name">
                <button matSuffix mat-icon-button aria-label="Add" (click)="addCode()">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field *ngIf="selected.hasExpirationDate" appearance="fill">
                <mat-label>Scadenza</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="output.expirationDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="selected.hasMaxUses" appearance="fill">
                <mat-label>Massimo numero di usi</mat-label>
                <input type="number" matInput placeholder="0"  [(ngModel)]="output.maxUses">
            </mat-form-field>
            <div class="column" *ngIf="selected.actions.variable">
                <p>Valori variabili</p>
                <ng-container *ngFor="let variable of selected.actions.variable">
                    <mat-form-field appearence="fill" *ngIf="variable.type==='select'">
                        <mat-select [(ngModel)]="output.variables[variable.key]">
                            <mat-option [value]="k.id" *ngFor="let k of (getValues(variable.source)|async)">{{k.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="variable.type !== 'select'" >
                        <mat-label>{{variable.key}}</mat-label>
                       <input matInput [(ngModel)]="output.variables[variable.key]">
                    </mat-form-field>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button (click)="submit()" cdkFocusInitial>Ok</button>
</div>