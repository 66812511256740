import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

import { EdwayService } from '../../edway.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  models$: Observable<unknown>;
  fleets$: Observable<unknown>;

  data = {
    model: null,
    fleet: null,
    unlock: 0,
    booking: 0,
    max_cost: 0,
    image: null,
  };

  constructor(
    private readonly edwayService: EdwayService,
    private s: MatSnackBar,
    public dialogRef: MatDialogRef<AddComponent>,
  ) { }

  ngOnInit(): void {
    this.models$ = this.edwayService.getModels();
    this.fleets$ = this.edwayService.getFleets(); 
  }

  onModelSelected(modelId: string): void {
    this.data.model = modelId;
  }

  onFleetSelected(fleetId: string): void {
    this.data.fleet = fleetId;
  }

  save(): void {
    console.log('Saving Pricing...', this.data);
    this.edwayService.addPricing(this.data).subscribe(data=>{
      this.s.open('Pricing saved', '', {duration:500})
      this.dialogRef.close(true);
    });
  }
}
