import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDatasource, IGetRowsParams } from "ag-grid-community";
import { map, tap } from 'rxjs/operators';
import { EdwayService } from "./edway.service";
import { ThrowStmt } from "@angular/compiler";


@Injectable()
export class EdwayDatasource implements IDatasource {
    setRaw(arg0: any) {
      this.raw_params = {...this.raw_params, ...arg0};
    }
    e: EdwayService;
    inform(e: EdwayService) {
      this.e = e;
    }


    raw_params = {};

    url: string;

    post_dl_ops: any = (x) => x;

    ipp: number = 100;

    rows: number = 100;

    filter: any = {};

    constructor(
        private h: HttpClient,
    ){ }

    setPostOperartions(op){
        this.post_dl_ops = op;
    }

    setPaging(ipp:number, rows: number){
        this.ipp = ipp;
        this.rows = rows;
    }

    setFilter(filter){
        this.filter = filter;
    }

    setUrl(url:string){
        this.url = url;
    }

    get rowCount(): number{
        return null;
    }
    getRows(params: IGetRowsParams) {
        if(this.url){
            console.log(params);
            let get={
                "ipp":this.ipp.toString(), 
                "page": ""+(params.startRow/this.ipp),
            };
            get = {
                ...this.raw_params,
                ...get,
                ...this.filter
            };
            if(params.sortModel.length>0){
                let sorts = []
                for(let i=0;i<params.sortModel.length;i++){
                    sorts.push(params.sortModel[i].sort.replace('asc','').replace('desc','-')+params.sortModel[i].colId)
                }
                get["sort"] = sorts.join(",");
            }
            if(Object.keys(params.filterModel).length>0){
                get["filter"] = JSON.stringify(params.filterModel);
            }
            this.h.get(this.url, {params: get}).pipe(tap((x:any[])=>{this.rows = x.length})).pipe(map(this.post_dl_ops)).subscribe((data: any[]) =>{
                if(this.e){
                    this.e.notify(data);
                }
                params.successCallback(data);
            })
        }
    }
    destroy(): void {
        this.e = null;
    }
}
