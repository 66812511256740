import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JsonEditorOptions } from '@maaxgr/ang-jsoneditor';
import { CellEditRequestEvent, CsvExportParams, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { DeleteCellRendererComponent } from './delete-cell-renderer.component';
import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  public editorOptions: JsonEditorOptions;
  public settings: {name: unknown}[];
  public headers: any;
  public exportOptions: CsvExportParams;
  private gridAPI: GridApi;

  constructor(
    private service: SettingsService,
    public dialog: MatDialog
  ) {
    this.headers = [
      {headerName: 'Nome', field: 'name'},
      { headerName: 'Valore', field: 'value', editable: true, autoHeight: true },
      {
        headerName: 'Azioni', field: '',
        cellRenderer: DeleteCellRendererComponent,
        cellRendererParams: {
          clicked: this.deleteRow.bind(this)
        },
        autoHeight : true,
        width: 50
      }
    ];
    this.exportOptions = {
      fileName: 'settings.csv',
      columnKeys: ['name', 'value']
    };
  }

  public getRowId(params: GetRowIdParams): GetRowIdFunc {
    return params.data.name;
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridAPI = params.api;
    this.service.getSettings().subscribe(data => {
      this.settings = Object.keys(data).map(key => ({ name: key, value: data[key] }));
      params.api.sizeColumnsToFit();
    });
  }

  onCellEditRequest(event: CellEditRequestEvent): void{
    const oldData = event.data;
    const field = event.colDef.field;
    const newValue = event.newValue;
    const newData = { ...oldData };
    newData[field] = event.newValue;
    console.log('onCellEditRequest, updating ' + field + ' to ' + newValue);
    const tx = {
      update: [newData],
    };

    this.service.update(event.data.name, event.data.value).subscribe( _ => {
      event.api.applyTransaction(tx);
    });
  }

  addRow(): void{
    const ref = this.dialog.open(AddDialogComponent, { });
    ref.afterClosed().subscribe( data => {
      if (data) {
        this.service.update(data.name, data.value).subscribe( _ => {
          this.gridAPI.applyTransaction({add: [data]});
        });
      }
    });
  }

  deleteRow(params: any): void{
    if (confirm('delete setting?')){
      this.service.deleteKey(params.data.name).subscribe( _ => {
        params.api.applyTransaction({remove: [params.data]});
      });
    }
  }

  download(): void{
    if (this.gridAPI) {
      this.gridAPI.exportDataAsCsv();
    }
  }
}
