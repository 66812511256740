import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EdwayService } from 'src/app/edway.service';
import { ImageboxComponent } from 'src/app/imagebox/imagebox.component';
import { LogdataComponent } from 'src/app/logdata/logdata.component';
import { AddComponent } from 'src/app/payments/add/add.component';
import { SettingsService } from 'src/app/settings.service';

declare const mapboxgl: any;
declare const L: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit {
  rental_id: string;
  rental: any;
  map: any;
  mapbox_key: string;

  events: any[];


  areas = {
    type: 'FeatureCollection',
    features: [],
  };

  constructor(
    private e: EdwayService,
    private s: SettingsService,
    private ar: ActivatedRoute,
    private r: Router,
    private dialog: MatDialog
  ) {}



  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.ar.paramMap.subscribe((data: any) => {
      this.rental_id = data.params.id;
      console.log(data.params.id);

      //this.map = L.map('rental_map').setView([41.754922, 11.360105], 6);
      //L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //  maxZoom: 19,
      //  attribution: '© OpenStreetMap'
      //}).addTo(this.map);
      this.s.settings$.subscribe((settingsOK: boolean) => {
        if (settingsOK) {
          this.mapbox_key = this.s.get('mapbox_key');
          mapboxgl.accessToken =
            'pk.eyJ1Ijoic2lybW1vIiwiYSI6ImNsM3R2djh5ajBjdTgzam52NDBvZzVhcXEifQ.wSyHLLyQ7X5SmSXHLw5clA';
            this.initMap();
        }
      });
    });
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: 'rental_map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [11.360105, 41.754922], // starting position [lng, lat]
      zoom: 5, // starting zoom
    });

    this.map.addControl(new mapboxgl.FullscreenControl());
 
    this.map.on('load', () => {
      const trackjson = {
        type: 'FeatureCollection',
        features: [],
      };

      this.map.addSource('vehicles', {
        type: 'geojson',
        data: trackjson,
      });
      this.map.addSource('hover', {
        type: 'geojson',
        data: trackjson,
      });

      this.map.addSource('areas', {
        type: 'geojson',
        data: this.areas,
      });
      this.map.addLayer({
        id: 'areas-ok',
        source: 'areas',
        type: 'fill',
        filter: ['==', ['get', 'can_park'], 'True'],
        paint: {
          'fill-color': '#00ff00',
          'fill-opacity': 0.2,
        },
      });
      this.map.addLayer({
        id: 'areas-nok',
        source: 'areas',
        type: 'fill',
        filter: ['==', ['get', 'can_park'], 'False'],
        paint: {
          'fill-color': '#ff0000',
          'fill-opacity': 0.2,
        },
      });

      this.map.addLayer({
        id: 'vehicles-ok',
        source: 'vehicles',
        type: 'circle',
        filter: ['==', ['get', 'gtype'], 'point'],
        paint: {
          'circle-color': '#ee82ee',
          'circle-opacity': 0.6,
          'circle-radius': 4
        },
      });
      this.map.addLayer({
        id: 'vehicles-line',
        source: 'vehicles',
        type: 'line',
        filter: ['==', ['get', 'gtype'], 'line'],
        paint: {
          'line-color': '#ee82ee',
          'line-opacity': 0.6,
          'line-width': 2
        },
      });
      this.map.addLayer({
        id: 'vehicles-hover',
        source: 'hover',
        type: 'circle',
        paint: {
          'circle-color': '#ff0000',
          'circle-opacity': 0.8,
        },
      });
      this.refresh();
    });
  }

  refresh() {
    
    this.e.getAreas().subscribe((data) => {
      this.map.getSource('areas').setData(data);
    });
    
    this.e.getRentals({ id: this.rental_id }).subscribe((rdata: any) => {
      this.rental = rdata;
      let end = '';
      if (!this.rental.end) {
        end = new Date().toISOString();
      } else {
        end = this.rental.end;
      }
      this.e
        .getVehicleLogs(this.rental.vehicle, this.rental.start, end)
        .subscribe((log: any) => {
          this.events = log;
          console.log(this.events);
          this.events = this.events.sort((x: any, y: any) => {
            const a = +new Date(x.timestamp);
            const b = +new Date(y.timestamp);
            return a - b;
          });

          const trackjson = {
            type: 'FeatureCollection',
            features: [],
          };

          const coords = [];
          const trk = [];

          for (let ti of this.events.filter((x) => x.logtype === 'rawlog')) {
            if (ti.logtype === 'rawlog') {
              ti['gtype'] = 'point';
              if (ti.lng && ti.lat) {
                trk.push([ti.lng, ti.lat]);
                coords.push([ti.lng, ti.lat]);
                trackjson.features.push({
                  type: 'Feature',
                  properties: ti,
                  geometry: {
                    type: 'Point',
                    coordinates: [ti.lng, ti.lat],
                  },
                });
              }
            }
          }

          const bounds = new mapboxgl.LngLatBounds(trk[0], trk[1]);
          for (const coord of trk) {
            if (coord[0] && coord[1]) {
              bounds.extend(coord);
            }
          }

          trackjson.features.push({
            type: 'Feature',
            properties: {
              gtype: 'line',
            },
            geometry: {
              type: 'LineString',
              coordinates: trk,
            },
          });

          this.map.getSource('vehicles').setData(trackjson);

          this.map.fitBounds(bounds, {
            padding: 20,
          });
        });
    });
  }

  onRowClicked(e) {
    const dialogRef = this.dialog.open(LogdataComponent, {
      width: '400px',
      data: e,
    });
  }

  closeRental(r) {
    if (confirm('chiudere il noleggio?')) {
      return this.e.closeRental(this.rental).subscribe((data) => {
        const dialogRef = this.dialog.open(LogdataComponent, {
          width: '600px',
          data: { data },
        });

        this.refresh();
      });
    }
  }

  closeRentalForce(r) {
    if (confirm('chiudere il noleggio forzando?')) {
      return this.e.closeRentalForce(this.rental).subscribe((data) => {
        const dialogRef = this.dialog.open(LogdataComponent, {
          width: '600px',
          data: { data },
        });

        this.refresh();
      });
    }
  }

  onRowHover(log) {
    if (log) {
      this.map.getSource('hover').setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              gtype: 'point',
            },
            geometry: {
              type: 'Point',
              coordinates: [log.lng, log.lat],
            },
          },
        ],
      });
    } else {
      this.map.getSource('hover').setData({
        type: 'FeatureCollection',
        features: [],
      });
    }
  }

  openPenalties() {
    this.dialog.open(AddComponent, { data: { trip: this.rental_id } });
  }

  show(pic) {
    
    this.dialog.open(ImageboxComponent, { 
      width: "400px",
      data: { img: pic.url } });
  }
}
