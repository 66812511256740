<h1 mat-dialog-title>{{ "users.packages.add_to_wallet" | translate }}</h1>
<div mat-dialog-content>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Wallet</mat-label>
    <span matPrefix>€ &nbsp;</span>
    <input matInput type="number" [(ngModel)]="data.wallet" />
  </mat-form-field>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Bonus</mat-label>
    <span matPrefix>€ &nbsp;</span>
    <input matInput type="number" [(ngModel)]="data.bonus" value="0" />
  </mat-form-field>
  <!-- aggiungere notifica di rimborso -->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annulla</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
