<mat-toolbar>
    <h1 translate>dashboard.roles</h1>
    <span class="spacer"></span>
    <button mat-icon-button (click)="addRole()"><mat-icon>add</mat-icon></button>
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage reverse-column ag-theme-alpine"
    [rowData]="list"
    [rowHeight]="40"
    [headerHeight]="160"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    (rowClicked)="onRowClicked($event)"
    >
</ag-grid-angular>
