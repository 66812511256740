import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VehicleInfoAddComponent } from './vehicle-info-add/vehicle-info-add.component';
import { MatDialog } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'edway-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss'],
})
export class VehicleInfoComponent implements OnInit {
  @Input() vehicle_uuid: string;

  vehicle_metas: any[];

  displayedColumns: string[] = ['type', 'name', 'value', 'valid', 'actions'];

  constructor(
    private e: EdwayService,
    private tr: TranslateService,
    private d: MatDialog
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.e.getVehicleVMetas(this.vehicle_uuid).subscribe((data: any[]) => {
      this.vehicle_metas = data;
      console.log(data);
    });
  }

  addInfo() {
    this.d.open(VehicleInfoAddComponent, {
      data: {
        vehicle_uuid: this.vehicle_uuid,
      },
    });
  }

  deleteInfo(id) {
    if (confirm(this.tr.instant('ops.confirm_deletion', { itm: id }))) {
      // alert(id);

      this.e.deleteVehicleInfo(id).subscribe((data) => {
        window.location.reload();
      });
    }
  }
}
