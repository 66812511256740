<mat-toolbar>
    {{'dashboard.invoices'|translate}}
    <button mat-icon-button (click)="updateList()"><mat-icon>refresh</mat-icon></button>
    <span class="spacer"></span>
    
    
</mat-toolbar>

<mat-list>
    <mat-list-item *ngFor="let f of files">        
        <span class="ft">{{f.type}}</span>
        <a [href]="f.url" class="dl" mat-icon-button><mat-icon>download</mat-icon></a>
        <span class="fn">{{f.emitted}}</span>
        <span class="afn" *ngIf="f.paid !== null" [ngClass]="{'paid':f.paid !== null, 'unpaid': f.paid===null}">paid</span>
        <span class="afn" *ngIf="f.paid === null" [ngClass]="{'paid':f.paid !== null, 'unpaid': f.paid===null}">unpaid</span>
        <span class="spacer"></span>
    <span class="amount">{{f.amount|currency:f.currency:'symbol'}}</span>
    </mat-list-item>
</mat-list>