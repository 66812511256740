import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-amend',
  templateUrl: './amend.component.html',
  styleUrls: ['./amend.component.scss']
})
export class AmendComponent implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(button, event) {
    button.clicked(this.params.data);
  }


}
