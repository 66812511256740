import { Component, EventEmitter, OnInit } from '@angular/core';
import { EdwayService } from 'src/app/edway.service';
import { SettingsService } from 'src/app/settings.service';

declare const mapboxgl: any;
declare const maplibre: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  searchTerm = '';
  tags_list: any = [];
  itineraries_list: any = [];
  experiences_list: any = [];
  pois_list: any = [];

  drawer_open = false;

  mapbox_key;
  map: any;

  drawerMode = 'single';

  public selectedItinerary: any = null;
  public selectedExperience: any = null;

  public NewPoi: EventEmitter<any> = new EventEmitter<any>();
  public NewItinerary: EventEmitter<any> = new EventEmitter<any>();

  pois: any = { type: 'FeatureCollection', features: [] };
  itineraries: any = { type: 'FeatureCollection', features: [] };

  constructor(private e: EdwayService, private s: SettingsService) {}

  ngOnInit(): void {
    this.e.getExpTags().subscribe((data) => {
      this.tags_list = data;
    });

    this.e.getItineraries().subscribe((data) => {
      this.itineraries_list = data;
    });

    this.e.getExperiences().subscribe((data) => {
      this.experiences_list = data;
    });

    this.e.getPoisList().subscribe((data: any[]) => {
      this.pois_list = data;
    });

    this.s.settings$.subscribe((settingsOk: boolean) => {
      if (settingsOk) {
        this.mapbox_key = this.s.get('mapbox_key');
        mapboxgl.accessToken =
          'pk.eyJ1Ijoic2lybW1vIiwiYSI6ImNsM3R2djh5ajBjdTgzam52NDBvZzVhcXEifQ.wSyHLLyQ7X5SmSXHLw5clA';
        this.initMap();
      }
    });
  }

  changeMode(mode) {
    this.drawer_open = true;
    this.drawerMode = mode;
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [11.360105, 41.754922], // starting position [lng, lat]
      zoom: 5, // starting zoom
      hash: true,
    });

    // Add geolocate control to the map.
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );

    this.map.on('moveend', () => {
      const b = this.map.getBounds();
      const z = this.map.getZoom();
      if (z >= 16) {
        this.e
          .getPoisMap([b.getEast(), b.getSouth(), b.getWest(), b.getNorth()])
          .subscribe((data) => {
            this.pois = data;
            this.map.getSource('pois').setData(data);
          });
      }
      this.e
        .getItinerariesMap([
          b.getEast(),
          b.getSouth(),
          b.getWest(),
          b.getNorth(),
        ])
        .subscribe((data) => {
          this.itineraries = data;
          for (let c of this.itineraries.features) {
            if (!c.properties.color){
              const randomColor = Math.floor(Math.random() * 16777215).toString(
                16
              );
              c.properties.color = '#' + randomColor;
            }
          }
          this.map.getSource('itineraries').setData(data);
        });
    });

    this.map.on('load', () => {
      this.map.addSource('pois', {
        type: 'geojson',
        data: this.pois,
      });
      this.map.addSource('itineraries', {
        type: 'geojson',
        data: this.itineraries,
      });

      this.map.addLayer({
        id: 'exps',
        type: 'circle',
        source: 'pois',
        minzoom: 15,
        filter: ['>', ['get', 'experiences'], 0],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': '#00ff00',
          'circle-radius': 10,
        },
      });

      this.map.addLayer({
        id: 'itineraries-user-outline',
        type: 'line',
        source: 'itineraries',
        minzoom: 2,
        filter: ['!=', 'user', ''],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'line-color': '#000099',
          'line-opacity': 0.5,
          'line-width': 2,
        },
      });

      this.map.addLayer({
        id: 'itineraries-outline',
        type: 'line',
        source: 'itineraries',
        minzoom: 2,
        filter: ['==', 'user', ''],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'line-color': ['get', 'color'],
          'line-width': 3,
        },
      });

      this.map.addLayer({
        id: 'itineraries-user',
        type: 'line',
        layout: {
          visibility: 'none',
        },
        source: 'itineraries',
        minzoom: 2,
        filter: ['!=', 'user', ''],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'line-color': '#0000ff',
          'line-opacity': 0.5,
          'line-width': 1,
        },
      });

      this.map.addLayer({
        id: 'itineraries-main',
        type: 'line',
        source: 'itineraries',
        minzoom: 2,
        filter: ['==', 'user', ''],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'line-color': ['get', 'color'],
          'line-width': 2,
        },
      });

      this.map.addLayer({
        id: 'radii',
        type: 'circle',
        source: 'pois',
        minzoom: 16,
        filter: ['>', ['get', 'radius'], 0],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': '#0000ff',
          'circle-opacity': 0.3,
          'circle-radius': 19,
        },
      });

      this.map.addLayer({
        id: 'pois',
        type: 'circle',
        source: 'pois',
        minzoom: 16,
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': '#ff0000',
          'circle-radius': 5,
        },
      });

      this.map.on(
        'mouseenter',
        ['pois', 'itineraries-main', 'itineraries-user'],
        () => {
          this.map.getCanvas().style.cursor = 'pointer';
        }
      );

      // Change it back to a pointer when it leaves.
      this.map.on(
        'mouseleave',
        ['pois', 'itineraries-main', 'itineraries-user'],
        () => {
          this.map.getCanvas().style.cursor = '';
        }
      );

      this.defaultClick();
    });
  }

  defaultClick() {
    this.map.on('click', 'pois', (e) => {
      // Set `bbox` as 5px reactangle area around clicked point.
      const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      // Find features intersecting the bounding box.
      const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: ['pois'],
      });

      if (selectedFeatures.length == 1) {
        console.log(selectedFeatures);
        this.drawer_open = true;
        this.drawerMode = 'single-poi';
      }
    });
  }
  defaultClickOff() {
    this.map.off('click', 'pois');
  }

  itineraryClick() {
    this.map.on('click', 'pois', (e) => {
      // Set `bbox` as 5px reactangle area around clicked point.
      const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      // Find features intersecting the bounding box.
      const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: ['pois'],
      });

      if (selectedFeatures.length == 1) {
        this.defaultClickOff();
        this.defaultClick();
      }
    });
    this.map.on('click', 'itineraries-main', (e) => {
      // Set `bbox` as 5px reactangle area around clicked point.
      const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      // Find features intersecting the bounding box.
      const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: ['itineraries-main'],
      });

      if (selectedFeatures.length == 1) {
        this.defaultClickOff();
        this.defaultClick();
      }
    });
  }

  clearSearch() {
    this.searchTerm = '';
  }

  drawer_toggle() {
    this.drawer_open = !this.drawer_open;
  }

  editItinerary(item) {
    this.selectedItinerary = item;
  }

  editExperience(item) {
    this.selectedExperience = item;
  }
}
