import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edway-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss'],
})
export class VoteComponent implements OnInit {
  @Input() user: string = null;

  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  displayedColumns: string[] = [
    'feedback_count',
    'rating_min',
    'rating_max',
    'mean',
    'mode',
  ];

  rowData: any = [];
  show = false;
  constructor(
    private e: EdwayService, 
    private r: Router,
    private tr: TranslateService,
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    this.columnDefs = [
      { headerName: this.tr.instant('votes.table.user_id'), field: 'user_id', resizable: true },
      { headerName: this.tr.instant('votes.table.created_at'), field: 'created_at', resizable: true },
      { headerName: this.tr.instant('votes.table.trip_id'), field: 'trip_id', resizable: true },
      { headerName: this.tr.instant('votes.table.rating'), field: 'rating', resizable: true },
      { headerName: this.tr.instant('votes.table.description'), field: 'description', resizable: true },
      { headerName: this.tr.instant('votes.table.source'), field: 'source', resizable: true },
      //{ headerName: this.tr.instant('votes.table.uuid'), field: 'uuid', resizable: true },
    ];
    this.e.getUserFeedbacks(this.user, 'list').subscribe((data: any[]) => {
      this.rowData = data;
      this.show = true;
    });
  }

  onRowClicked(ev) {
    // this.r.navigate(['/xxx', ev.data.ident]);
  }
}
