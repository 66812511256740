import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EdwayService } from 'src/app/edway.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-rentals',
  templateUrl: './rentals.component.html',
  styleUrls: ['./rentals.component.scss'],
})
export class RentalsComponent implements OnInit {
  rentals: any[];

  constructor(
    private ar: ActivatedRoute,
    private e: EdwayService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.e
      .getVehicleRentals(this.ar.parent.snapshot.params.id)
      .subscribe((data: any[]) => {
        this.rentals = data;
      });
  }

  show(data) {
    console.log(data);
  }
}
