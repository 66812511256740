import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { IssuesModule } from '../issues/issues.module';
import { RouterModule } from '@angular/router';
import { ModelListComponent } from './model-list/model-list.component';
import { ModelComponent } from './model/model.component';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { ActionsCellRendererComponent } from './list/actions-cell-renderer.component';
import { RedirectDialogComponent } from './list/redirect-dialog/redirect-dialog.component';
import { ActivateDialogComponent } from './list/activate-dialog/activate-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { TranslateModule } from '@ngx-translate/core';
import { UserCouponListComponent } from './user-list/user-list.component';
import { ApplyDialogComponent } from './list/apply-dialog/apply-dialog.component';
@NgModule({
  declarations: [
    ModelListComponent,
    ModelComponent,
    ListComponent,
    UserCouponListComponent,
    ActionsCellRendererComponent,
    RedirectDialogComponent,
    ActivateDialogComponent,
    CreateComponent,
    ApplyDialogComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    AgGridModule,
    RouterModule,
    CodemirrorModule,
    TranslateModule
  ]
})
export class CouponsModule { }
