import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-delete-cell-renderer',
    template: `
    <button mat-icon-button (click)="btnClickedHandler()"><mat-icon>delete</mat-icon></button>
  `,
})
export class DeleteCellRendererComponent implements ICellRendererAngularComp {
    private params: any;

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    btnClickedHandler(): void {
        this.params.clicked(this.params);
    }
}
