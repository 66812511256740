<mat-toolbar>
    <h2>{{ 'vehicles.detail.tabs.pics' | translate }}</h2>
    <span class="spacer"></span>
    <input mat-input [(ngModel)]="filter.from_time" type="datetime">
    <input mat-input [(ngModel)]="filter.to_time" type="datetime">
    <button mat-button (click)="filterlogs()">Filter</button>
</mat-toolbar>
<ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="logs"
    [headerHeight]="36"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    (rowClicked)="onRowClicked($event)"
    >
</ag-grid-angular>
