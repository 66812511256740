<mat-toolbar>
  <h1>Settings</h1>
  <span class="spacer"></span>
  <button mat-icon-button (click)="download()">
    <mat-icon>download</mat-icon>
  </button>
  <button mat-icon-button (click)="addRow()"><mat-icon>add</mat-icon></button>
</mat-toolbar>

<ag-grid-angular
  class="fullpage ag-theme-alpine"
  [defaultCsvExportParams]="exportOptions"
  [readOnlyEdit]="true"
  [getRowId]="getRowId"
  [rowData]="settings"
  [columnDefs]="headers"
  [rowHeight]="40"
  (cellEditRequest)="onCellEditRequest($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
