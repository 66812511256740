<mat-toolbar>
  <h1>Experiences</h1>
</mat-toolbar>

<ng-container *ngFor="let e of experiences_list">
  <span class="list-item" [value]="e.id" (click)="edit(e)">
    {{ e.name }}
    <span class="list-item-tag tag-{{ t.family }}" *ngFor="let t of e.tags">
      {{ t.name }}
    </span>
  </span>
</ng-container>
