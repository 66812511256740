<h1 mat-dialog-title translate>payments.add.payment</h1>
<mat-dialog-content>
  <div class="column">
    <mat-form-field appearance="fill">
      <mat-label translate>payments.add.reason</mat-label>
      <mat-select type="text" matInput [(ngModel)]="ldata.reason">
        <mat-option *ngFor="let o of reasons" [value]="o">{{('payment.'+o)|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label translate>payments.add.amount</mat-label>
      <input type="number" matInput [(ngModel)]="ldata.amount">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label translate>payments.add.description</mat-label>
      <textarea matInput [(ngModel)]="ldata.description"></textarea>
    </mat-form-field>
    <edway-file-upload [(ngModel)]="data.file"></edway-file-upload>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="submit()">{{'ops.ok'|translate}}</button>
  <button mat-button (click)="cancel()">{{'ops.cancel'|translate}}</button>
</mat-dialog-actions>
