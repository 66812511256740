import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-fleet-editor',
  templateUrl: './fleet-editor.component.html',
  styleUrls: ['./fleet-editor.component.scss']
})
export class FleetEditorComponent implements OnInit {

  data: any = {op: "Add"};
  models: any[];
  fleets: any[];

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<FleetEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) { }

  ngOnInit(): void {
    console.log(this.idata);
    if(this.idata){
      this.data = this.idata;
    }
    this.e.getRealFleets().subscribe((data: any[]) =>{
      this.fleets = data;
    })
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
