import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { AccessDialogComponent } from '../access-dialog/access-dialog.component';
import { QrcodeComponent } from '../qrcode/qrcode.component';

@Component({
  selector: 'edway-fleet-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {
  columnDefs: ColDef[] = [
    { field: 'user' },
    { field: 'request' },
    { field: 'accepted' },
    { field: 'until' }
  ];

  @Input() fleet: string;

  permissions: any[];

  constructor(
    private e: EdwayService,
    public dialog: MatDialog
  ) { }

  refresh(){
    this.e.getFleetPermissions(this.fleet).subscribe((data: any[])=>{
      this.permissions = data;
    })
  }

  ngOnInit(): void {
    this.refresh()
  }
  openDialog(base?) {
    const dialogRef = this.dialog.open(AccessDialogComponent,{data: base});

    dialogRef.afterClosed().subscribe(result => {
      this.e.acceptUserFleet(result).subscribe(data=>{
        this.refresh()
      })
    });
  }

  showQR(){
    const dialogRef = this.dialog.open(QrcodeComponent, {data: {code:this.fleet}});

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    }); 
  }
}
