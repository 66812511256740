<mat-toolbar class="hide-mobile">
    <mat-toolbar-row>
        <h1 translate>vehicles.detail.commands</h1>
        <span class="spacer"></span>
        <button matTooltip="{{'commands.unlock'|translate}}" [disabled]="commands.indexOf('unlock') < 0" color="primary" (click)="run('unlock')" mat-icon-button><mat-icon><i class="fa-solid fa-lock-open"></i></mat-icon></button>
        <button matTooltip="{{'commands.lock'|translate}}" [disabled]="commands.indexOf('lock') < 0" color="" (click)="run('lock')" mat-icon-button><mat-icon><i class="fa-solid fa-lock"></i></mat-icon></button>
        <button matTooltip="{{'commands.unlock_battery'|translate}}" [disabled]="commands.indexOf('unlock_battery') < 0" color="" (click)="run('unlock_battery')" mat-icon-button><mat-icon><i class="fa-solid fa-battery"></i></mat-icon></button>
        <button matTooltip="{{'commands.start_horn'|translate}}" [disabled]="commands.indexOf('start_horn') < 0" color="primary" (click)="run('start_horn')" mat-icon-button><mat-icon><i class="fa-solid fa-bell"></i></mat-icon></button>
        <button matTooltip="{{'commands.stop_horn'|translate}}" [disabled]="commands.indexOf('stop_horn') < 0" color="" (click)="run('stop_horn')" mat-icon-button><mat-icon><i class="fa-solid fa-bell-slash"></i></mat-icon></button>
        <button matTooltip="{{'commands.open_box'|translate}}" [disabled]="commands.indexOf('open_box') < 0" color="primary" (click)="run('open_box')" mat-icon-button><mat-icon><i class="fa-solid fa-briefcase"></i></mat-icon></button>
        <button matTooltip="{{'commands.lock_box'|translate}}" [disabled]="commands.indexOf('lock_box') < 0" color="primary" (click)="run('lock_box')" mat-icon-button><mat-icon>
            
            <span class="fa-stack fa-1x">
                <i class="fa-solid fa-briefcase fa-stack-1x"></i>
                <i class="fa-solid fa-ban fa-stack-1x"></i>
            </span>
        </mat-icon></button>
        <button matTooltip="{{'commands.setmax'|translate}}" [disabled]="commands.indexOf('setmax') < 0" color="primary" (click)="run('setmax')" mat-icon-button><mat-icon><i class="fa-solid fa-gauge"></i></mat-icon></button>
        <button matTooltip="{{'commands.request_update'|translate}}" [disabled]="commands.indexOf('request_update') < 0" color="primary" (click)="run('request_update')" mat-icon-button><mat-icon><i class="fa-solid fa-refresh"></i></mat-icon></button>
        <button matTooltip="{{'commands.advanced'|translate}}" color="" mat-icon-button (click)="advanced = !advanced"><mat-icon>menu</mat-icon></button>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="advanced">
        <span class="spacer"></span>
        <button matTooltip="{{'commands.reboot'|translate}}" [disabled]="commands.indexOf('reboot') < 0" color="primary"  (click)="run('reboot')" mat-button>Soft reset</button>
        <button matTooltip="{{'commands.hard_reset'|translate}}" [disabled]="commands.indexOf('hard_reset') < 0" color="primary" (click)="run('hard_reset')" mat-button>Reset via SMS</button>
        <button mat-icon-button></button>
    </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="hide-desktop">
    <mat-toolbar-row class="scrollable">
        <button matTooltip="{{'commands.unlock'|translate}}" [disabled]="commands.indexOf('unlock') < 0" color="primary" (click)="run('unlock')" mat-icon-button><mat-icon><i class="fa-solid fa-lock-open"></i></mat-icon></button>
        <button matTooltip="{{'commands.lock'|translate}}" [disabled]="commands.indexOf('lock') < 0" color="" (click)="run('lock')" mat-icon-button><mat-icon><i class="fa-solid fa-lock"></i></mat-icon></button>
        <button matTooltip="{{'commands.unlock_battery'|translate}}" [disabled]="commands.indexOf('unlock_battery') < 0" color="" (click)="run('unlock_battery')" mat-icon-button><mat-icon><i class="fa-solid fa-battery"></i></mat-icon></button>
        <button matTooltip="{{'commands.start_horn'|translate}}" [disabled]="commands.indexOf('start_horn') < 0" color="primary" (click)="run('start_horn')" mat-icon-button><mat-icon><i class="fa-solid fa-bell"></i></mat-icon></button>
        <button matTooltip="{{'commands.stop_horn'|translate}}" [disabled]="commands.indexOf('stop_horn') < 0" color="" (click)="run('stop_horn')" mat-icon-button><mat-icon><i class="fa-solid fa-bell-slash"></i></mat-icon></button>
        <button matTooltip="{{'commands.open_box'|translate}}" [disabled]="commands.indexOf('open_box') < 0" color="primary" (click)="run('open_box')" mat-icon-button><mat-icon><i class="fa-solid fa-briefcase"></i></mat-icon></button>
        <button matTooltip="{{'commands.lock_box'|translate}}" [disabled]="commands.indexOf('lock_box') < 0" color="primary" (click)="run('lock_box')" mat-icon-button><mat-icon>
            
            <span class="fa-stack fa-1x">
                <i class="fa-solid fa-briefcase fa-stack-1x"></i>
                <i class="fa-solid fa-ban fa-stack-1x"></i>
            </span>
        </mat-icon></button>
        <button matTooltip="{{'commands.setmax'|translate}}" [disabled]="commands.indexOf('setmax') < 0" color="primary" (click)="run('setmax')" mat-icon-button><mat-icon><i class="fa-solid fa-gauge"></i></mat-icon></button>
        <button matTooltip="{{'commands.request_update'|translate}}" [disabled]="commands.indexOf('request_update') < 0" color="primary" (click)="run('request_update')" mat-icon-button><mat-icon><i class="fa-solid fa-refresh"></i></mat-icon></button>
    </mat-toolbar-row>
   </mat-toolbar>