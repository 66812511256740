<mat-toolbar>
    <h1>{{'cloud.title'|translate}}</h1>
    <button mat-icon-button (click)="getInfo()"><mat-icon>electrical_services</mat-icon></button>
    <span class="spacer"></span>
    <button mat-icon-button (click)="addKey()"><mat-icon>key</mat-icon></button>
    <button mat-icon-button (click)="addWH()"><mat-icon>webhook</mat-icon></button>
    <button mat-icon-button disabled (click)="addWH()"><mat-icon>lan</mat-icon></button>
    <button mat-icon-button disabled (click)="addWH()"><mat-icon>chat_bubble</mat-icon></button>
</mat-toolbar>
<mat-tab-group>
    <mat-tab label="Incoming">
        <mat-toolbar>
            <h1>Incoming</h1>
            <span class="spacer"></span>
            <button mat-icon-button (click)="addKey()"><mat-icon>key</mat-icon></button>
        </mat-toolbar>
        <table width="100%">
            <tr>
                <th>API KEY</th>
                <th>Permissions</th>
                <th>Actions</th>
            </tr>
            <tr *ngFor="let key of keys">
                <td>{{key.api_key}}</td>
                <td>{{key.permissions}}</td>
                <td>
                    <button mat-icon-button (click)="deleteKey(key.id)"><mat-icon>delete</mat-icon></button>
                    <button mat-icon-button (click)="toClip(key.api_key)"><mat-icon>content_copy</mat-icon></button>
                </td>
            </tr>
        </table>
    </mat-tab>
    <mat-tab label="Webhooks">
        <mat-toolbar>
            <h1>Webhooks</h1>
            <span class="spacer"></span>
            <button mat-icon-button (click)="addWH()"><mat-icon>webhook</mat-icon></button>
        </mat-toolbar>
        <table width="100%">
            <tr>
                <th width="20%">Sources</th>
                <th width="20%">Events</th>
                <th width="10%">Format</th>
                <th width="40%">Target</th>
                <th width="10%">Actions</th>
            </tr>
            <tr *ngFor="let wh of whs">
                <td><span><mat-icon>grain</mat-icon>{{wh.sources_list.fleet}}</span></td>
                <td *ngIf="wh.events === 'all'">{{wh.events}}</td>
                <td *ngIf="wh.events === 'selection'">{{wh.events}}</td>
                <td>{{'cloud.transformation.'+wh.transformation|translate}}</td>
                <td>{{'cloud.target.'+wh.target|translate}}
                    <span *ngIf="wh.target === 'custom'">: {{wh.target_details.url}}</span>
                </td>
                <td>
                    <button mat-icon-button [disabled]="!wh.can_delete" (click)="deleteWH(wh.id)"><mat-icon>delete</mat-icon></button>
                    <!--<button mat-icon-button><mat-icon>toc</mat-icon></button>-->
                    <button mat-icon-button [disabled]="!wh.sign"><mat-icon>key</mat-icon></button>
                    
                </td>
            </tr>
        </table>
    </mat-tab>
    <mat-tab label="MQTT" disabled="true"></mat-tab>
    <mat-tab label="Messaging">
        
        <mat-toolbar>
            <h1>Messaging</h1>
            <span class="spacer"></span>
            <button mat-icon-button disabled="true"><mat-icon>chat_bubble</mat-icon></button>
        </mat-toolbar>
        <table width="100%">
            <tr>
                <th>Channel</th>
                <th>Events</th>
                <th>Group</th>
                <th>Link</th>
            </tr>
            <tr *ngFor="let tg of groups">
                <td>{{tg.channel}}</td>
                <td>{{tg.events}}</td>
                <td>{{tg.group}}</td>
                <td>{{tg.link}}</td>
            </tr>
        </table>
    </mat-tab>
</mat-tab-group>