<h1 mat-dialog-title>{{'user.detail.activate_package.title'|translate}}</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{'user.detail.activate_package.label'|translate}}</mat-label>
      <mat-select [(ngModel)]="data.package">
        <mat-option [value]="p.id" *ngFor="let p of packages">{{p.name}} ({{p.fleet}})</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annulla</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
