import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { AmendComponent } from 'src/app/payments/amend/amend.component';

@Component({
  selector: 'edway-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['./wallet-list.component.scss'],
})
export class WalletListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  @Input() pricings: string;

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private tr: TranslateService
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    let ret = [];
    this.e.getWalletProducts().subscribe((data: any[]) => {
      this.list = data;
      this.show = true;
    });

    this.columnDefs = [
      {
        field: 'active',
        headerName: this.tr.instant('common.active'),
        valueFormatter: boolFormatter,
      },
      { field: 'name', headerName: this.tr.instant('common.name') },
      { field: 'times', headerName: this.tr.instant('common.times') },
      { field: 'image', headerName: this.tr.instant('common.image') },
      {
        field: 'auto_renew',
        headerName: this.tr.instant('common.auto_renew'),
        valueFormatter: boolFormatter,
      },
      {
        field: 'price',
        valueFormatter: currencyFormatter,
        headerName: this.tr.instant('common.price'),
      },
      {
        field: 'value',
        valueFormatter: currencyFormatter,
        headerName: this.tr.instant('common.value'),
      },
      {
        field: 'bonus',
        valueFormatter: currencyFormatter,
        headerName: this.tr.instant('common.bonus'),
      },
      {
        field: 'actions',
        filter: false,
        cellRenderer: AmendComponent,
        cellRendererParams: {
          buttons: [
            {
              label: 'op.delete',
              icon: 'delete',
              condition: (params: any) => {
                return true;
              },
              clicked: (params: any) => {
                const d = this.tr.instant('ops.confirm_deletion', {
                  itm: `'${params.name}'`,
                });
                if (confirm(d)) {
                  this.deleteWallet(params.id);
                }
              },
            },
          ],
        },
        headerName: this.tr.instant('fields.payments.actions.label'),
      },
    ];
  }

  deleteWallet(id): void {
    this.e.deleteWallet(id).subscribe((data) => {
      location.reload();
    });
  }
}

function currencyFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return (
      params.data.currency.replace('EUR', '€ ').replace('eur', '€ ') +
      params.value.toFixed(2)
    );
  } else {
    return null;
  }
}

function boolFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return '✅';
  } else {
    return '';
  }
}
