import { Directive } from '@angular/core';

@Directive({
  selector: '[edProtect]'
})
export class ProtectDirective {

  constructor() { }

}
