<div class="login-wrapper">
  <div class="auth-container">
    <mat-card class="auth-card">
      <mat-card-header>
        <mat-card-title>Log in</mat-card-title>
      </mat-card-header>
      <form>
        <mat-card-content>
          <mat-form-field class="auth-input-field">
            <input matInput #username placeholder="Username">
          </mat-form-field>
          <mat-form-field class="auth-input-field">
            <input matInput #password type="password" placeholder="Password">
          </mat-form-field>
        </mat-card-content>
        <button (click)="login(username.value, password.value)" mat-stroked-button color="primary"
          class="auth-button">Log in</button>
      </form>
      <div class="auth-secondary-actions">
        <angular-telegram-login-widget (login)="onLogin($event)" [botName]="botName"></angular-telegram-login-widget>
        <button routerLink="/reset" mat-button>Recover password</button>
      </div>
    </mat-card>
  </div>
</div>
