import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from './cache.service';

@Pipe({
  name: 'fleet'
})
export class FleetPipe implements PipeTransform {

  constructor(
    private c: CacheService
  ) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    return this.c.get('fleets', value);
  }

}
