import { from, Observable, of } from 'rxjs';

import { Component, OnInit, ViewChild } from '@angular/core';

import { CouponsService } from '../coupons.service';
import { Coupon } from '../models';
import { ColDef, CsvExportParams, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { ActionsCellRendererComponent } from './actions-cell-renderer.component';
import { BoolCellRendererComponent } from 'src/app/bool-cell-renderer/bool-cell-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import { RedirectDialogComponent } from './redirect-dialog/redirect-dialog.component';
import { ActivateDialogComponent } from './activate-dialog/activate-dialog.component';
import { ApplyDialogComponent } from './apply-dialog/apply-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserCouponListComponent } from '../user-list/user-list.component';

@Component({
  selector: 'app-coupons-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  coupons$: Observable<Coupon[]>;
  columnDefs$: Observable<ColDef[]> ;
  exportOptions: CsvExportParams;
  immutableData: boolean;
  gridAPI: GridApi;
  @ViewChild(UserCouponListComponent) userComponent: UserCouponListComponent;

  constructor(private readonly couponsService: CouponsService, public dialog: MatDialog, public snack: MatSnackBar) {
    this.exportOptions = {
      fileName: 'coupons.csv',
    };
    this.immutableData = true;
  }

  ngOnInit(): void {
    this.coupons$ = this.couponsService.getCoupons();
    this.columnDefs$ = of([
      { headerName: 'ID', field: 'id', width: 80 },
      { headerName: 'Codice', field: 'code' },
      { headerName: 'Usi', field: 'usesCount' },
      { headerName: 'Massimo numero di usi', field: 'maxUses' },
      { headerName: 'Scadenza', field: 'expirationDate', type: 'dateColumn' },
      { headerName: 'Spostato a', field: 'redirect' },
      { headerName: 'Abilitato', field: 'enabled', cellRenderer: BoolCellRendererComponent },
      {
        headerName: 'Azioni',
        field: '',
        cellRenderer: ActionsCellRendererComponent,
        cellRendererParams: {
          disable: this.couponDisabled.bind(this),
          redirect: this.redirectCoupon.bind(this),
          apply: this.applyCoupon.bind(this)
        },
        autoHeight: true,
        minWidth: 180
      },
    ]);
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridAPI = params.api;
    params.api.sizeColumnsToFit();
  }

  public getRowId(params: GetRowIdParams): GetRowIdFunc {
    return params.data.id;
  }

  public couponDisabled(rowData: ICellRendererParams): void {
    this.couponsService.disableCoupon(rowData.data.code).subscribe( () => {
      rowData.data.enabled = false;
      this.gridAPI.applyTransaction({ update: [rowData.data] });
      this.snack.open('Coupon disabilitato', 'OK', { duration: 1000 });
    });
  }

  public redirectCoupon(rowData: ICellRendererParams): void {
    const options = [];
    this.gridAPI.forEachNode(node => options.push(node.data.code));
    const ref = this.dialog.open(RedirectDialogComponent, { data: { from: rowData.data.code, options } });
    ref.afterClosed().subscribe(data => {
      if (data) {
        this.couponsService.redirectCoupon(data.from, data.to).subscribe( () => {
          this.couponsService.getCoupon(rowData.data.code).subscribe( (coupon: Coupon) => {
            this.gridAPI.applyTransaction({ update: [coupon] });
            this.snack.open(`Coupon ${data.to} verrà utilizzato al posto di ${data.from}`, 'OK', { duration: 1000 });
          });
         });
      }
    });
  }

  public applyCoupon(rowData: ICellRendererParams): void {
    const ref = this.dialog.open(ApplyDialogComponent, { data: { service: this.couponsService, couponCode: rowData.data.code } });
    ref.afterClosed().subscribe(() => {
      this.couponsService.getCoupon(rowData.data.code).subscribe( (coupon: Coupon) => {
        this.gridAPI.applyTransaction({ update: [coupon] });
        this.snack.open(`Coupon attivato`, 'OK', { duration: 1000 });
      });
    });
  }

  download(): void {
    if (this.gridAPI) {
      this.gridAPI.exportDataAsCsv();
    }
  }

  public createCoupon(): void {
    this.couponsService.getCouponModels().subscribe( (models) => {
      const ref = this.dialog.open(ActivateDialogComponent, { data: { models } });
      ref.afterClosed().subscribe(data => {
        if (data) {
          var codes = data.code.split('|');
          this.snack.open('Creating '+codes.length+' Coupons', 'ok', {duration:2000});
          for (let c of codes){
            data.code = c;
            this.couponsService.createCoupon(data).subscribe( (coupon: Coupon) => {
              this.gridAPI.applyTransaction({ add: [coupon] });
              this.snack.open(`Coupon creato`, 'OK', { duration: 500 });
             });
          }
        }
      });
    });
  }

  onTabChange(event: number): void {
    // Event handler logic
    switch (event) {
      case 0:
        if (event === 0) {
          this.gridAPI.redrawRows();
        }
        break;
      case 1:
        this.userComponent.redrawUI();
      default:
        break;
    }

  }
}
