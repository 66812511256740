<mat-toolbar>
  <h1>Utenti - {{ user.username }}</h1>
  <span *ngIf="user.blocked" class="blocked"
    >(Blacklisted: {{ "user.detail.block." + user.blocked | translate }})</span
  >
  <span class="spacer"></span>

  <button mat-icon-button *ngIf="!user.blocked" (click)="blacklist()">
    <mat-icon>stop_circle_outline</mat-icon>
  </button>
  <button mat-icon-button *ngIf="user.blocked" (click)="unblacklist()">
    <mat-icon>play_circle_outline</mat-icon>
  </button>
  <!--<button mat-icon-button><mat-icon>edit</mat-icon></button>-->
  <!--<button mat-icon-button ><mat-icon>delete</mat-icon></button>-->
</mat-toolbar>

<mat-menu #menuPhone="matMenu">
  <button [disabled]="user.phone_valid === 'True'" mat-menu-item (click)="setAtt('phone_valid', true)">
    <mat-icon>check</mat-icon>
    <span>{{'user.validation.manual_validation'|translate}}</span>
  </button>
  <button [disabled]="user.phone_valid !== 'True'"mat-menu-item (click)="revalidate('phone')">
    <mat-icon>repeat</mat-icon>
    <span>{{'user.validation.invalidate'|translate}}</span>
  </button>
</mat-menu>


<mat-menu #menuMail="matMenu">
  <button mat-menu-item (click)="setAtt('mail_valid', true)">
    <mat-icon>check</mat-icon>
    <span>{{'user.validation.manual_validation'|translate}}</span>
  </button>
  <button disabled mat-menu-item (click)="revalidate('mail')">
    <mat-icon>repeat</mat-icon>
    <span>{{'user.validation.invalidate'|translate}}</span>
  </button>
</mat-menu>

<div class="row">
  <div class="column info">
    <mat-toolbar> <mat-icon>info</mat-icon> Info </mat-toolbar>
    <table>
      <tr>
        <td>{{ "common.name" | translate }}</td>
        <td>{{ user.firstname }} {{ user.familyname }}</td>
        <td></td>
        <td></td>
        <td>
        </td>
      </tr>
      <tr>
        <td>{{ "common.birthdate" | translate }}</td>
        <td>{{ user.birthdate | date }}</td>
        <td></td>
        <td></td>
        <td>
          <button mat-icon-button (click)="edit('birthdate')" matTooltip="{{'user.edit_birthdate'|translate}}"><mat-icon>edit</mat-icon></button>
        </td>
      </tr>
      <tr>
        <td>{{ "common.phone" | translate }}</td>
        <td>
          <a href="tel:{{ user.phone }}">{{ user.phone }}</a>
        </td>
        <td title='{{ "common.latest_otp" | translate }}'><mat-icon matTooltip="{{ user.secondary_otp }}">pin</mat-icon></td>
        <td>
          <button mat-icon-button [matMenuTriggerFor]="menuPhone">
            <mat-icon *ngIf="user.phone_valid==='True'">check</mat-icon>
            <mat-icon *ngIf="user.phone_valid==='False'">clear</mat-icon>
            <mat-icon *ngIf="!user.phone_valid">question_mark</mat-icon>
          </button>
        </td>
        <td>
          <button mat-icon-button (click)="editPhone()" matTooltip="{{'user.edit_phone'|translate}}"><mat-icon>edit</mat-icon></button>
        </td>
      </tr>
      <tr>
        <td>{{ "common.email" | translate }}</td>
        <td>
          <a href="mailto:{{ user.username }}">{{ user.username }}</a>
        </td>
        <td title='{{ "common.latest_otp" | translate }}'><mat-icon matTooltip="{{ user.otp }}">pin</mat-icon></td>
        <td>
          <button mat-icon-button [matMenuTriggerFor]="menuMail">
            <mat-icon *ngIf="user.mail_valid==='True'">check</mat-icon>
            <mat-icon *ngIf="user.mail_valid==='False'">clear</mat-icon>
            <mat-icon *ngIf="!user.mail_valid">question_mark</mat-icon>
          </button>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
  <div class="column stats hide_narrow">
    <mat-toolbar> <mat-icon>bar_chart</mat-icon> Stats </mat-toolbar>
    <mat-tab-group>
      <mat-tab label="{{ 'users.details.behavior' | translate }}">
        <table>
          <tr>
            <td><div class="graph" id="graph_per_day"></div></td>
            <td><div class="graph_w" id="graph_per_hour"></div></td>
          </tr>
        </table>
      </mat-tab>
      <mat-tab label="{{ 'users.details.constance' | translate }}">
        <div id="constance"></div>
      </mat-tab>
      <mat-tab label="{{ 'dashboard.feedbacks' | translate }}">
        <edway-vote-stats></edway-vote-stats>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="column map">
    <mat-toolbar
      ><mat-icon>map-marker</mat-icon>
      {{ "dashboard.location" | translate }}</mat-toolbar
    >
    <div id="map"></div>
  </div>
</div>
<mat-tab-group *ngIf="user">
  <mat-tab
    *ngIf="a.canDo('list_rentals')"
    label="{{ 'dashboard.rentals' | translate }}"
  >
    <ng-template matTabContent>
      <edway-rentals-list [user]="uid"></edway-rentals-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'dashboard.feedbacks' | translate }}">
    <ng-template matTabContent>
      <mat-toolbar>
        <h3>{{ "dashboard.feedbacks" | translate }}</h3>
      </mat-toolbar>
      <edway-vote [user]="uid"></edway-vote>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'users.details.coupons' | translate }}">
    <ng-template matTabContent>
      <app-user-coupons-list [user]="uid"></app-user-coupons-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'dashboard.products' | translate }}">
    <ng-template matTabContent>
      <mat-toolbar>
        <h3>{{ "dashboard.products" | translate }}</h3>
        <span class="spacer"></span>
        <span class="wallet"
          >Wallet: {{ user_status.current_wallet }}€ {{ user.wallet }}</span
        >
        <button
          *ngIf="a.canDo('user_add_package')"
          mat-icon-button
          (click)="addPackage()"
          matTooltip="Aggiungi Pacchetto"
        >
          <mat-icon>inventory</mat-icon>
        </button>
        <button
          *ngIf="a.canDo('user_add_wallet')"
          mat-icon-button
          (click)="addToWallet()"
          matTooltip="Aggiungi a Wallet"
        >
          <mat-icon>wallet</mat-icon>
        </button>
      </mat-toolbar>
      <edway-packages-list [user]="uid"></edway-packages-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'dashboard.notifications' | translate }}">
    <ng-template matTabContent>
      <edway-news-list [user]="uid"></edway-news-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'dashboard.ian' | translate }}">
    <ng-template matTabContent>
      <app-ian-list [user]="uid"></app-ian-list>
    </ng-template>
  </mat-tab>
  <mat-tab
    *ngIf="a.canDo('list_transactions')"
    label="{{ 'dashboard.transactions' | translate }}"
  >
    <ng-template matTabContent>
      <edway-payments-list [user]="uid"></edway-payments-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'dashboard.issues' | translate }}">
    <ng-template matTabContent>
      <edway-issues-list [user]="uid"></edway-issues-list>
    </ng-template>
  </mat-tab>
  <mat-tab
    label="{{ 'users.details.payment_modes' | translate }}"
  >
    <ng-template matTabContent>
      <edway-payment-methods-list
        [user]="user.username"
      ></edway-payment-methods-list>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="a.canDo('user_list_files')" label="File">
    <mat-toolbar>
      <h3>File</h3>
      <button mat-icon-button>
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-icon-button>
        <mat-icon>upload</mat-icon>
      </button>
      <span class="spacer"></span>
    </mat-toolbar>
    <table width="100%" *ngIf="user.files">
      <tr>
        <td></td>
        <td>Fronte</td>
        <td>Retro</td>
        <td>Selfie</td>
        <td>Stato</td>
        <td>Azioni</td>
      </tr>
      <tr *ngIf="user.files.drivers_license">
        <td>Patente</td>
        <td><img (click)="open(user.files.drivers_license?.front)" height="200" src="{{ user.files.drivers_license?.front }}" /></td>
        <td><img (click)="open(user.files.drivers_license?.back)" height="200" src="{{ user.files.drivers_license?.back }}" /></td>
        <td><img (click)="open(user.files.drivers_license?.selfie)" height="200" src="{{ user.files.drivers_license?.selfie }}" /></td>
        <td>{{ user.files.drivers_license?.valid }}</td>
        <td>
          <button
            mat-raised-button
            class="accept"
            (click)="accept(user, 'drivers_license')"
          >
            <i class="fa-fw fa-solid fa-check"></i>Accetta
          </button>
          <button
            mat-raised-button
            class="refuse"
            (click)="refuse(user, 'drivers_license')"
          >
            <i class="fa-fw fa-solid fa-x"></i>Rifiuta
          </button>
        </td>
      </tr>
      <tr *ngIf="user.files.id">
        <td>Identità</td>
        <td><img (click)="open(user.files.id?.front)" height="200" src="{{ user.files.id?.front }}" /></td>
        <td><img (click)="open(user.files.id?.back)" height="200" src="{{ user.files.id?.back }}" /></td>
        <td><img (click)="open(user.files.id?.selfie)" height="200" src="{{ user.files.id?.selfie }}" /></td>
        <td>{{ user.files.id?.valid }}</td>
        <td>
          <button mat-raised-button class="accept" (click)="accept(user, 'id')">
            <i class="fa-fw fa-solid fa-check"></i>Accetta
          </button>
          <button mat-raised-button class="refuse" (click)="refuse(user, 'id')">
            <i class="fa-fw fa-solid fa-x"></i>Rifiuta
          </button>
        </td>
      </tr>
      <tr *ngIf="user.files.passport">
        <td>Passaporto</td>
        <td><img (click)="open(user.files.passport?.front)" height="200" src="{{ user.files.passport?.front }}" /></td>
        <td><img (click)="open(user.files.passport?.back)" height="200" src="{{ user.files.passport?.back }}" /></td>
        <td><img (click)="open(user.files.passport?.selfie)" height="200" src="{{ user.files.passport?.selfie }}" /></td>
        <td>{{ user.files.passport?.valid }}</td>
        <td>
          <button
            mat-raised-button
            class="accept"
            (click)="accept(user, 'passport')"
          >
            <i class="fa-fw fa-solid fa-check"></i>Accetta
          </button>
          <button
            mat-raised-button
            class="refuse"
            (click)="refuse(user, 'passport')"
          >
            <i class="fa-fw fa-solid fa-x"></i>Rifiuta
          </button>
        </td>
      </tr>
    </table>
  </mat-tab>
  <mat-tab *ngIf="a.canDo('dev')" label="{{ 'ops.raw_data' | translate }}">
    <div>
      {{ user | json }}
    </div>
  </mat-tab>
</mat-tab-group>
