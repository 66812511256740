import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CouponsService } from '../coupons.service';
import { CouponModel } from '../models';

@Component({
  selector: 'app-coupons-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss']
})
export class ModelListComponent implements OnInit {
  models$: Observable<CouponModel[]>;

  constructor(
    private readonly router: Router,
    private readonly couponsService: CouponsService
  ) {}

  ngOnInit(): void {
    this.models$ = this.couponsService.getCouponModels();
  }

  gotoDetail(id: number): void {
    this.router.navigate(['/coupons', id]);
  }

  createCouponModel(): void {
    this.router.navigate(['/coupons', 'create']);
  }

  async delete(id: number): Promise<void> {
    await this.couponsService.deleteCouponModel(id).toPromise();
    this.models$ = this.couponsService.getCouponModels();
  }
}
