import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { EditComponent } from './edit/edit.component';

import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { RentalsModule } from '../rentals/rentals.module';
import { AddWalletComponent } from './add-wallet/add-wallet.component';
import { PackagesListComponent } from './packages-list/packages-list.component';
import { BlacklistDialogComponent } from './blacklist-dialog/blacklist-dialog.component';
import { IssuesModule } from '../issues/issues.module';
import { PaymentMethodsListComponent } from './payment-methods-list/payment-methods-list.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { VoteComponent } from './vote/vote.component';
import { VoteStatsComponent } from './vote-stats/vote-stats.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewsModule } from '../news/news.module';
import { DocsComponent } from './docs/docs.component';
import { CouponsModule } from '../coupons/coupons.module';
import { IanListComponent } from './ian-list/ian-list.component';
import { IanAddComponent } from './ian-add/ian-add.component';
import { IanEditComponent } from './ian-edit/ian-edit.component';
import { AddPackageComponent } from './add-package/add-package.component';
import { BotpComponent } from './botp/botp.component';
import { PaymentsModule } from '../payments/payments.module';
import { UnlockDialogComponent } from './unlock-dialog/unlock-dialog.component';

@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    EditComponent,
    AddWalletComponent,
    PackagesListComponent,
    
    BlacklistDialogComponent,
    PaymentMethodsListComponent,
    AddRoleComponent,
    VoteComponent,
    VoteStatsComponent,
    DocsComponent,
    IanListComponent,
    IanAddComponent,
    IanEditComponent,
    AddPackageComponent,
    BotpComponent,
    UnlockDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    RentalsModule,

    PaymentsModule,
    IssuesModule,
    NewsModule,
    TranslateModule,
    CouponsModule,
  ],
})
export class UsersModule {}
