import { Component, OnInit } from '@angular/core';
import { EdwayService } from 'src/app/edway.service';
import { SettingsService } from 'src/app/settings.service';

declare const mapboxgl;
declare const MapboxDraw;

@Component({
  selector: 'app-area-draw',
  templateUrl: './area-draw.component.html',
  styleUrls: ['./area-draw.component.scss']
})
export class AreaDrawComponent implements OnInit {

  map;
  mapbox_key;
  draw;
  constructor(
    private e: EdwayService,
    private s: SettingsService
  ) { }

  ngOnInit(): void {
    
    this.mapbox_key = this.s.get('mapbox_key');
    mapboxgl.accessToken = 'pk.eyJ1Ijoic2lybW1vIiwiYSI6ImNsM3R2djh5ajBjdTgzam52NDBvZzVhcXEifQ.wSyHLLyQ7X5SmSXHLw5clA';
    this.map = new mapboxgl.Map({
      container: 'drawmap', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [11.360105, 41.754922], // starting position [lng, lat]
      zoom: 5 // starting zoom
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls:{
        polygon: true,
        trash: true
      },
      defaultMode: 'draw_polygon'
    });

    this.map.on('load', () => {
      this.map.addControl(this.draw);

    });
    this.map.on('draw.create', this.updateArea);
    this.map.on('draw.update', this.updateArea);

    
  }

  updateArea(e){
    console.log(e.features);
  }
  

}
