<mat-toolbar>
    <h1 translate>users.title</h1>
    <span class="spacer"></span>
    <button mat-icon-button matTooltip="{{'ops.dl'|translate}}" (click)="download('https://api.hoponmobility.com/admin/data/users.xlsx')"><mat-icon>download</mat-icon></button>
    <!--<button mat-icon-button matTooltip="{{'ops.add'|translate}}" (click)="addUser()"><mat-icon>add</mat-icon></button>-->
        
</mat-toolbar>
<ag-grid-angular  *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    rowModelType="infinite"
    [columnDefs]="columnDefs"
    [headerHeight]="36"
    [rowHeight]="40"
    (page)="goToPage($event)"
    (rowClicked)="onRowClicked($event)"
    (gridReady)="onGridReady($event)"

    >
</ag-grid-angular>