<mat-toolbar class="toolbar">
  <h2>POI</h2>
  <button mat-icon-button matTooltip="{{'experiences.poi.locate'|translate}}"  *ngIf="!isNewRecord"><mat-icon>map</mat-icon></button>
  <span class="fill-remaining-space"></span>
  <!-- <button
    mat-icon-button
    matTooltip="{{ 'fleets.area.add.area.button' | translate }}"
  >
    <mat-icon>map</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="{{ 'fleets.area.add.marker.button' | translate }}"
  >
    <mat-icon>location_on</mat-icon>
  </button> -->
  <!--<button mat-icon-button (click)="new()">
    <mat-icon>add</mat-icon>
  </button>-->
  <!-- <button mat-icon-button (click)="edit()">
    <mat-icon>edit</mat-icon>
  </button> -->
  <button  *ngIf="!isNewRecord"
    mat-icon-button
    matTooltip="{{ 'experiences.poi.add_to_itinerary' | translate }}"
  >
    <mat-icon>show_chart</mat-icon>
  </button>
</mat-toolbar>

<mat-tab-group>
  <mat-tab label="{{ 'experiences.tabs.general' | translate }}">
    <form (submit)="save()">
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input
          matInput
          placeholder="Nome del POI"
          [(ngModel)]="poi.name"
          name="name"
          required
        />
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>Proprietario</mat-label>
        <input
          matInput
          placeholder="Proprietario del POI"
          [(ngModel)]="poi.owner"
          name="owner"
          required
        />
      </mat-form-field> -->

      <mat-form-field>
        <mat-label>Posizione</mat-label>
        <input
          matInput
          placeholder="Posizione del POI"
          [(ngModel)]="poi.location"
          name="location"
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Locate"
          (click)="createLocation()"
        >
          <mat-icon>my_location</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Punto</mat-label>
        <input
          matInput
          placeholder="Latitudine, Longitudine"
          [(ngModel)]="poi.point"
          name="point"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Raggio</mat-label>
        <input
          matInput
          type="number"
          placeholder="Raggio del POI"
          [(ngModel)]="poi.radius"
          name="radius"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Descrizione</mat-label>
        <textarea
          matInput
          placeholder="Descrizione del POI"
          [(ngModel)]="poi.description"
          name="description"
          style="height: 250px"
        ></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Galleria</mat-label>
        <input
          matInput
          placeholder="Galleria del POI"
          [(ngModel)]="poi.gallery"
          name="gallery"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tag</mat-label>
        <mat-select [(ngModel)]="poi.tags" name="tags" multiple>
          <mat-option *ngFor="let t of tags_list" [value]="t.id">
            {{ t.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Ore</mat-label>
        <input
          matInput
          placeholder="Durata in Ore del POI"
          [(ngModel)]="poi.hours"
          name="hours"
          type="number"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Video</mat-label>
        <input
          matInput
          placeholder="Link al video del POI"
          [(ngModel)]="poi.video"
          name="video"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Icona</mat-label>
        <input
          matInput
          placeholder="Icona del POI"
          [(ngModel)]="poi.icon"
          name="icon"
        />
      </mat-form-field>

      <mat-label>Guida audio</mat-label>
      <mat-checkbox [(ngModel)]="poi.audio_guide" name="audio_guide"
        >Si</mat-checkbox
      >

      <mat-form-field>
        <mat-label>ID OpenStreetMap</mat-label>
        <input
          matInput
          placeholder="ID OpenStreetMap del POI"
          [(ngModel)]="poi.openstreetmap_id"
          name="openstreetmap_id"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Itinerari</mat-label>
        <mat-select [(ngModel)]="poi.itineraries" name="itineraries" multiple>
          <mat-option *ngFor="let i of itineraries_list" [value]="i.id">
            {{ i.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">
        {{ isNewRecord ? "Salva Nuovo" : "Salva Modifiche" }}
      </button>
    </form>
  </mat-tab>
  <mat-tab label="Experiences" *ngIf="!isNewRecord">
    <mat-toolbar
      ><h1>Esperienze</h1>
      <span class="spacer"></span
      ><button mat-icon-button (click)="addExperience()">
        <mat-icon>add</mat-icon>
      </button></mat-toolbar
    >
    <mat-accordion>
      <mat-expansion-panel *ngFor="let ex of exps">
        <mat-expansion-panel-header>
          {{ ex.name }}
        </mat-expansion-panel-header>
        <app-experience [experience]="ex"></app-experience>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>
</mat-tab-group>
