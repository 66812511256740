import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private r: Router,
    private a: AuthService
  ) { }

  ngOnInit(): void {
    this.a.logout();
    this.r.navigateByUrl('/');
  }

}
