import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { EdwayDatasource } from './edway-datasource';
import { Buffer } from 'buffer';
import { ICellRendererParams } from 'ag-grid-community';

class NinjaPaged{
  items: any[]
  count: number
}

@Injectable({
  providedIn: 'root',
})
export class EdwayService {
  refreshCachedArea() {
    const url = environment.api_endpoint + '/fleet/areas.geojson?force=true';
    let ret = this.h.get(url);
    return ret;
  }
  addFleetSetting(fleet: string, key: string, value: string) {
    return of('Method not implemented.');
  }
  getPoiNearby(coordinates: any, time: any, user:string, b:number) {
    return this.h.get(environment.api_endpoint+"/pois/nearby", {params:{time, lon:coordinates[0], lat:coordinates[1], battery:b.toString()}});
  }
  getPoiNavigation(id: any) {
    return this.h.get(environment.api_endpoint+"/pois/itineraries/"+id+".geojson")
  }
  deleteCloudWebhook(id: any) {
    return this.h.delete(environment.api_endpoint+"/admin/cloud/webhooks/"+id);
  }
  exhaustPackage(pdata) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/packages/' +
        pdata.package +
        '/exhaust',
        {}
    );
  }

  getCloudTargets(){
    return this.h.get<NinjaPaged>(environment.api_endpoint+"/admin/cloud/targets")
  }
  getCloudTransformations(){
    return this.h.get<NinjaPaged>(environment.api_endpoint+"/admin/cloud/transformations")
  }
  getCloudPlatformEvents(){
    return this.h.get<NinjaPaged>(environment.api_endpoint+"/admin/cloud/platformevents")
  }

  getCloudTelegramGroups(){
    return this.h.get<NinjaPaged>(environment.api_endpoint+"/admin/cloud/telegramgroups")
  } 
  getCloudWebhooks() {
    return this.h.get<NinjaPaged>(environment.api_endpoint+"/admin/cloud/webhooks")
  }
  getCloudApiKeys() {
    return this.h.get<NinjaPaged>(environment.api_endpoint+"/admin/cloud/apikeys")
  }

  createCloudWebhook(hook:any){
    return this.h.post(environment.api_endpoint+"/admin/cloud/webhooks", hook);
  }

  createCloudApiKey() {
    return this.h.post(environment.api_endpoint+"/admin/cloud/apikeys", {});
  }
  
  getTagIcons() {
    return this.h.get(environment.api_endpoint + '/admin/experiences/tagicons');
  }
  getPoisList() {
    return this.h.get(environment.api_endpoint + '/admin/experiences/pois');
  }

  getSimAccounts() {
    return this.h.get(
      environment.api_endpoint + '/admin/connectivity/providers'
    );
  }
  getSims() {
    return this.h.get(environment.api_endpoint + '/admin/connectivity/cards');
  }



  unlockPhone(phone: string) {
    return this.h.delete(
      environment.api_endpoint + '/admin/users/blacklist?phone=' + phone
    );
  }

  retryPayment(params: any) {
    return this.createPayment({
      reason: 'retry',
      amount: params.amount,
      description: 'payment retry',
      trip: params.id,
    });
  }
  getVehiclePics(id: string, start: string, end: string) {
    return this.h.get(
      environment.api_endpoint +
        '/admin/vehiclepics/' +
        id +
        '/logs?from_time=' +
        start +
        '&to_time=' +
        end
    );
  }
  getKVs(param: string) {
    console.log(param);
    return this.h.get(environment.api_endpoint + '/admin/getkvs?ks=' + param);
  }
  getBlocked() {
    return this.h.get(environment.api_endpoint + '/admin/blocked');
  }
  deleteFleet(id: string) {
    return this.h.delete(environment.api_endpoint + '/admin/fleets/' + id);
  }
  uploadFile(file: any, target?: any) {
    console.log(file, target);
    let fname = '';
    if (target) fname = target;
    else fname = file.name;
    const formData = new FormData();
    formData.append('f', file);
    return this.h
      .get(environment.api_endpoint + '/admin/utils/upload', {
        params: {
          fname: fname,
        },
      })
      .pipe(
        switchMap((x: any) => {
          const uri = encodeURIComponent(x.url);
          console.log(uri);
          return this.h.put(
            environment.api_endpoint + '/admin/utils/minio_fail?url=' + uri,
            formData,
            {
              headers: { 'content-type': 'image/x-png' },
            }
          );
        })
      );
  }
  getPackages(arg0?: { user: string }) {
    if (arg0?.user) {
      return this.getUserPackages(arg0.user);
    } else {
      return this.h.get(environment.api_endpoint + '/admin/sold_packages');
    }
  }
  getFields(area: any) {
    return this.h.get(environment.api_endpoint + '/admin/data/fields', {
      params: { area },
    });
  }
  deleteFile(file: any) {
    return this.h.delete(environment.api_endpoint + '/admin/files/' + file);
  }
  getFiles() {
    return this.h.get(environment.api_endpoint + '/admin/files/');
  }
  getCached() {
    return this.h.get(this.endpoints.utils + '/cached');
  }

  getBookings(filter: { user: string; vehicle: string }) {
    return this.h.get(this.endpoints.bookings, { params: filter });
  }
  toggleVehicleVisibility(ident: string, newvalue: string, oldvalue: string) {
    return this.h.post(
      this.endpoints.vehicles + '/' + ident + '/status?status=' + newvalue,
      {}
    );
  }
  createTicket(ticket: any) {
    return this.h.post(
      environment.api_endpoint + '/admin/support/tickets',
      ticket
    );
  }
  refreshPackages: EventEmitter<any> = new EventEmitter<any>();
  assignPackage(data: any) {
    return this.h
      .post(this.endpoints.users + '/' + data.user.username + '/packages', data)
      .pipe(tap((x) => this.refreshPackages.emit()));
  }
  getTickets(tid?: string) {
    if (tid)
      return this.h.get(
        environment.api_endpoint + '/admin/support/tickets/' + tid
      );
    else
      return this.h
        .get(environment.api_endpoint + '/admin/support/tickets/')
        .pipe(map((x: any) => x.tickets));
  }
  getContextualHelp(url: string) {
    return of([]);
  }
  ds: EdwayDatasource;
  private rentals = {};
  public changes: EventEmitter<any> = new EventEmitter<any>();
  public endpoints = {
    vehicles: environment.api_endpoint + '/admin/vehicles',
    users: environment.api_endpoint + '/admin/users',
    wallets: environment.api_endpoint + '/admin/wallets',
    products: environment.api_endpoint + '/admin/products',
    pricing: environment.api_endpoint + '/admin/pricing',
    rentals: environment.api_endpoint + '/admin/rentals',
    roles: environment.api_endpoint + '/admin/roles',
    bookings: environment.api_endpoint + '/admin/bookings',
    utils: environment.api_endpoint + '/admin/utils',
    issues: environment.api_endpoint + '/admin/issues',
  };
  resize: EventEmitter<any> = new EventEmitter<any>();

  putWallet(data) {
    console.log(data);
    return this.h.post(
      this.endpoints.users + '/' + data.user.username + '/wallet',
      data
    );
  }

  getLog(transaction_id: any) {
    return this.h.get(
      environment.api_endpoint +
        '/admin/paymentlogs?transaction=' +
        transaction_id
    );
  }

  acceptUserFleet(result: any) {
    return this.h.post(environment.api_endpoint + '/admin/userfleet', result);
  }

  notify(data) {
    for (let r in this.rentals) {
      this.rentals[r].emit(data);
    }
  }

  rentalsFor(uid: string) {
    this.rentals[uid] = new EventEmitter<any>();
    return this.rentals[uid];
  }

  createPayment(data: any) {
    return this.h.post(
      environment.api_endpoint + '/admin/create_payment',
      data
    );
  }

  getDocuments(status: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/docs?status=' + status
    );
  }
  reimburse(data: any) {
    return this.h.post(environment.api_endpoint + '/admin/reimburse', data);
  }

  getchanges() {
    return this.h.get(environment.api_endpoint + '/admin/dashstatus');
  }

  getStatistics(calculation: string, dims: { x: string; y: string }) {
    return this.h.get(
      environment.api_endpoint +
        '/admin/statistics?calc=' +
        calculation +
        '&x=' +
        dims.x +
        '&y=' +
        dims.y
    );
  }

  resetPassword(user: any) {
    return this.h.get(
      environment.api_endpoint + '/admin/users/' + user.username + '/resetpw'
    );
  }

  getStats() {
    return this.h.get(environment.api_endpoint + '/admin/stats');
  }

  addAreaSetting(fleet: string, area: string, key: string, value: string) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/fleets/' +
        fleet +
        '/areas/' +
        area +
        '/settings?key=' +
        key +
        '&value=' +
        value,
      {}
    );
  }

  // download(url: string, options: any, area?:string): Observable<any> {
  //   if (area){
  //     let f = this.d.open(DlfilterComponent, {data:{area: area}});
  //     return f.afterClosed().pipe(
  //       switchMap(data=>{
  //         if (data) {
  //           options.params = data;
  //           this._sb.open('Preparing the export...', null, {duration: 500});
  //           return this.h.get(url, options).pipe(tap(x=> {
  //             this._sb.open('download available in area and on your telegram channel', 'ok', {duration: 1000})
  //           }));
  //         } else {
  //           return of(false);
  //         }
  //       })
  //     );
  //   } else{
  //     this._sb.open('Preparing the export...', null, {duration: 500});
  //     return this.h.get(url, options).pipe(tap(x=> {
  //       this._sb.open('download available in area and on your telegram channel', 'ok', {duration: 1000})
  //     }));
  //   }
  // }

  deleteArea(fleet: any, area: any) {
    return this.h.delete(
      environment.api_endpoint + '/admin/fleets/' + fleet.id + '/areas/' + area
    );
  }

  addArea(fleet: any, areaName: string) {
    return this.h.post(
      environment.api_endpoint + '/admin/fleets/' + fleet.id + '/areas',
      {},
      { params: { name: areaName } }
    );
  }

  savePolygon(fleet: any, area: any, geoj: any) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/fleets/' +
        fleet.id +
        '/areas/' +
        area +
        '/area',
      geoj
    );
  }

  listRoles() {
    return this.h.get(environment.api_endpoint + '/admin/roles/');
  }

  getRoles() {
    return this.h.get(environment.api_endpoint + '/admin/roles/users');
  }

  toggleRole(user: any, role: any, status: string) {
    return this.h.post(environment.api_endpoint + '/admin/roles/users', {
      user: user,
      role: role,
      status: status,
    });
  }

  addRole(user: any, org?: string) {
    return this.h.post(environment.api_endpoint + '/admin/roles/users', {
      user: user,
      fleet: org,
    });
  }

  deleteRole(user: string, org: string) {
    return this.h.delete(
      environment.api_endpoint + '/admin/users/' + user + '/roles',
      { params: { org: org } }
    );
  }

  constructor(private h: HttpClient) {
    setInterval(() => {
      this.getchanges().subscribe((data) => {
        this.changes.emit(data);
      });
    }, 10000);
  }

  getVehiclesMap(
    bbox: any,
    zoom: any,
    options?: { fleet?: string; filter?: string[] }
  ) {
    const url = environment.api_endpoint + '/admin/vehicles.geojson';
    const bounds = [
      -180,//bbox.getWest(),
      -90,//bbox.getSouth(),
      180,//bbox.getEast(),
      90//bbox.getNorth(),
    ];

    const parameters: any = { count: 2000, zoom: Math.round(zoom) };
    if (options && options.fleet) parameters.fleet = options.fleet;
    if (options && options.filter)
      parameters.filter = JSON.stringify(options.filter);
    return this.h.get(url, { params: parameters });
  }

  getAreas(fleet?: string) {
    const url = environment.api_endpoint + '/fleet/areas.geojson';
    let ret = this.h.get(url);
    if (fleet) {
      ret = ret.pipe(
        map((areas: any) => {
          return areas.features.filter(
            (area) => area.properties.fleet_id[0] === fleet
          );
        })
      );
    }
    return ret;
  }

  getVehicles(options?: { fleet?: string }) {
    const url = this.endpoints.vehicles;
    const bounds = [-180, -90, 180, 90];
    const params = { bbox: bounds.join(',') };
    if (options?.fleet) params['fleet'] = options.fleet;
    return this.h.get(url, { params });
  }

  getVehicleCurrent(id) {
    const url = environment.api_endpoint + '/admin/vehicles/' + id;
    return this.h.get(url);
  }
  getVehicleStatic(id) {
    const url = environment.api_endpoint + '/admin/vehicles/' + id + '/data';
    return this.h.get(url);
  }
  getAvailableCommands(protocol) {
    return this.h.get(
      environment.api_endpoint + '/admin/vehicles/' + protocol + '/operations'
    );
  }

  getTranslation(mode, lang: string) {
    return this.h.get('https://i18n.hoponmobility.com/api/strings/' + lang, {
      params: { collection: mode },
    });
  }

  getVehicleLogs(id: string, start?: string, end?: string) {
    return this.h.get(
      environment.api_endpoint +
        '/admin/vehicles/' +
        id +
        '/logs?from_time=' +
        start +
        '&to_time=' +
        end
    );
  }

  getVehicleRentals(id: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/vehicles/' + id + '/rentals'
    );
  }

  getRentals(opts?: {
    id?: string;
    fleet?: string;
    vehicle?: string;
    user?: string;
    page?: string;
  }) {
    if (opts.id) {
      return this.h.get(environment.api_endpoint + '/admin/rentals/' + opts.id);
    } else {
      return this.h.get(environment.api_endpoint + '/admin/rentals', {
        params: opts,
      });
    }
  }

  getFleets(options?: { id?: string; vehicle?: string }) {
    if (options && options.id) {
      return this.h.get(
        environment.api_endpoint + '/admin/fleets/' + options.id
      );
    } else {
      return this.h.get(environment.api_endpoint + '/admin/fleets', {
        params: options,
      });
    }
  }

  getRealFleets() {
    return this.h.get(
      environment.api_endpoint + '/admin/fleets?mode=assignable'
    );
  }

  doCommand(cmd: string, imei: string, params: {}) {
    return this.h.post(
      environment.api_endpoint + '/admin/vehicles/' + imei + '/command/' + cmd,
      { params: params }
    );
  }

  getLoggedin(user: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/users/' + user + '?mode=full'
    );
  }

  getUsers(user?: string, ipp: number = 100, page: number = 1) {
    if (user) {
      return this.h.get(environment.api_endpoint + '/admin/users/' + user);
    } else {
      return this.h.get(environment.api_endpoint + '/admin/users', {
        params: { ipp: ipp.toString(), page: page.toString() },
      });
    }
  }

  getUserPayments(user?: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/users/' + user + '/payments'
    );
  }
  getUserPackages(user?: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/users/' + user + '/packages'
    );
  }

  getProfile() {
    return this.h.get(environment.api_endpoint + '/user/profile');
  }

  getUserFeedbacks(user_id?: string, mode = 'list') {
    return this.h.get(
      `${environment.api_endpoint}/admin/polls/stats/user/${user_id}/${mode}`
    );
  }

  getUserStatus(user?: string) {
    return this.h.get(`${environment.api_endpoint}/admin/users/${user}/status`);
  }

  getSettings() {
    return this.h.get(environment.api_endpoint + '/admin/settings');
  }

  getFleetPermissions(fleet: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/fleets/' + fleet + '/permissions'
    );
  }

  getFleetVehicles(fleet: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/fleets/' + fleet + '/vehicles'
    );
  }

  deleteFleetSetting(fleet: string, key: string) {
    return this.h.delete(
      environment.api_endpoint + '/admin/fleets/' + fleet + '/settings/' + key
    );
  }
  deleteAreaSetting(fleet: string, area: string, key: string) {
    return this.h.delete(
      environment.api_endpoint +
        '/admin/fleets/' +
        fleet +
        '/areas/' +
        area +
        '/settings/' +
        key
    );
  }

  addPricing(pricing: unknown) {
    // TODO: @sirmmo
    return this.h.post(environment.api_endpoint + '/admin/pricing', pricing);
  }

  addPricingRange(pricing: string, range: unknown) {
    return this.h.post(
      environment.api_endpoint + '/admin/pricing/' + pricing + '/min',
      range
    );
  }

  getPricings() {
    return this.h.get(environment.api_endpoint + '/admin/pricing');
  }

  getModels(filter?: any, model?: string) {
    return this.h.get(environment.api_endpoint + '/admin/models');
  }

  addFleet(name: string, parent: string) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/fleets?name=' +
        name +
        '&parent=' +
        parent,
      {}
    );
  }

  toggleFleetSetting(fleet: string, setting: string, value: boolean) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/fleets/' +
        fleet +
        '/setting?key=' +
        setting +
        '&value=' +
        value,
      {}
    );
  }

  addToFleet(vehicle: string, fleet: string) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/fleets/' +
        fleet +
        '/vehicles?vehicle=' +
        vehicle,
      {}
    );
  }

  delFromFleet(vehicle: string, fleet: string) {
    return this.h.delete(
      environment.api_endpoint +
        '/admin/fleets/' +
        fleet +
        '/vehicles?vehicle=' +
        vehicle,
      {}
    );
  }

  addCode(vehicle: string, context: string, code: string) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/vehicles/' +
        vehicle +
        '/codes?ctx=' +
        context +
        '&code=' +
        code,
      {}
    );
  }

  deleteCode(vehicle: string, context: string, code: string) {
    return this.h.delete(
      environment.api_endpoint +
        '/admin/vehicles/' +
        vehicle +
        '/codes?ctx=' +
        context +
        '&code=' +
        code
    );
  }

  deleteVehicle(vehicle: string) {
    return this.h.delete(
      environment.api_endpoint + '/admin/vehicles/' + vehicle
    );
  }

  getVMetas() {
    return this.h.get(`${environment.api_endpoint}/admin/vehicles_meta/vmetas`);
  }

  getVehicleVMetas(vehicle_uuid) {
    return this.h.get(
      `${environment.api_endpoint}/admin/vehicles_meta/filter/by_vehicle?vehicle_uuid=${vehicle_uuid}`
    );
  }

  addVehicleInfo(data) {
    const qs = new URLSearchParams(data).toString();

    return this.h.post(
      `${environment.api_endpoint}/admin/vehicles_meta/vmetadescriptors/?${qs}`,
      {}
    );
  }

  deleteVehicleInfo(info_id) {
    return this.h.delete(
      `${environment.api_endpoint}/admin/vehicles_meta/vmetadescriptors/${info_id}`,
      {}
    );
  }

  getFaqResources() {
    return this.h.get(`${environment.api_endpoint}/admin/faq/user/resources`);
  }

  addFaqResource(data) {
    const qs = new URLSearchParams(data).toString();

    return this.h.post(
      `${environment.api_endpoint}/admin/faq/resources/?${qs}`,
      {}
    );
  }
  deleteFaqResource(id) {
    return this.h.delete(
      `${environment.api_endpoint}/admin/faq/resources/${id}`
    );
  }

  closeRental(rental: any) {
    return this.h.post(
      environment.api_endpoint + '/admin/rentals/' + rental.id + '/close',
      {}
    );
  }

  
  cancelRental(rental: any) {
    return this.h.post(
      environment.api_endpoint + '/admin/rentals/' + rental.id + '/cancel',
      {}
    );  }

  closeRentalForce(rental: any) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/rentals/' +
        rental.id +
        '/close?force=True',
      {}
    );
  }

  // get list of prices
  getPricing(id?: string) {
    if (id) {
      return this.h.get(environment.api_endpoint + '/admin/pricing/' + id);
    } else {
      return this.h.get(environment.api_endpoint + '/admin/pricing');
    }
  }

  // get products (wallet and packages)
  getProducts(id?: string) {
    if (id) {
      return this.h.get(environment.api_endpoint + '/admin/products/' + id);
    } else {
      return this.h.get(environment.api_endpoint + '/admin/products');
    }
  }

  acceptDoc(user, doc) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/users/' +
        user +
        '/files/' +
        doc +
        '?mode=accept',
      {}
    );
  }

  refuseDoc(user, doc) {
    return this.h.post(
      environment.api_endpoint +
        '/admin/users/' +
        user +
        '/files/' +
        doc +
        '?mode=refuse',
      {}
    );
  }

  getIssues(options: { user?; vehicle? } = {}) {
    console.log('getIssues', options);
    return this.h.get(environment.api_endpoint + '/admin/issues', {
      params: options,
    });
  }

  // get products (wallet and packages)
  getWalletProducts(id?: string) {
    if (id) {
      return this.h.get(environment.api_endpoint + '/admin/wallets/' + id);
    } else {
      return this.h.get(environment.api_endpoint + '/admin/wallets');
    }
  }

  saveWallet(data: unknown) {
    return this.h.post(environment.api_endpoint + '/admin/wallets', data);
  }

  deleteWallet(id: string) {
    return this.h.delete(environment.api_endpoint + '/admin/wallets/' + id);
  }

  saveProduct(data: unknown) {
    return this.h.post(environment.api_endpoint + '/admin/products', data);
  }

  deleteProduct(id: string) {
    return this.h.delete(environment.api_endpoint + '/admin/products/' + id);
  }

  updateProduct(id, data) {
    return this.h.put(`${environment.api_endpoint}/admin/products/${id}`, data);
  }

  getPaymentMethods(user: string) {
    return this.h.get(
      environment.api_endpoint + '/admin/users/' + user + '/stripe'
    );
  }

  addToBlacklist(username: string, phone: string, reason: string) {
    return this.h.post(environment.api_endpoint + '/admin/users/blacklist', {
      username: username,
      phone: username,
      reason: reason,
    });
  }
  removeFromBlacklist(username: string, phone: string) {
    return this.h.delete(
      environment.api_endpoint +
        '/admin/users/blacklist?username=' +
        username +
        '&phone=' +
        phone
    );
  }

  getPayments(opts?: {
    id?: string;
    fleet?: string;
    vehicle?: string;
    trip?: string;
    user?: string;
    page?: string;
    ipp?: string;
  }) {
    if (opts.id) {
      return this.h.get(
        environment.api_endpoint + '/admin/payments/' + opts.id
      );
    } else {
      return this.h.get(environment.api_endpoint + '/admin/payments', {
        params: opts,
      });
    }
  }

  addVehicles(vehicles) {
    return this.h.post(environment.api_endpoint + '/admin/vehicles', vehicles);
  }

  getRoleNames() {
    return this.h.get(environment.api_endpoint + '/admin/roles');
  }

  addManager(data) {
    return this.h.post(environment.api_endpoint + '/admin/roles', data);
  }

  getNews() {
    return this.h.get(`${environment.api_endpoint}/admin/notifications/news`);
  }

  getIan(user = null) {
    if (user) {
      return this.h.get(
        `${environment.api_endpoint}/admin/notifications/ian?user=${user}`
      );
    } else {
      return this.h.get(`${environment.api_endpoint}/admin/notifications/ian`);
    }
  }

  addNews(data) {
    return this.h.post(
      `${environment.api_endpoint}/admin/notifications/news/`,
      data
    );
  }

  addIan(data) {
    return this.h.post(
      `${environment.api_endpoint}/admin/notifications/ian/`,
      data
    );
  }

  deleteNews(id) {
    return this.h.delete(
      `${environment.api_endpoint}/admin/notifications/news/${id}`
    );
  }

  deleteIan(id) {
    return this.h.delete(
      `${environment.api_endpoint}/admin/notifications/ian/${id}`
    );
  }

  updateNews(id, data) {
    return this.h.put(
      `${environment.api_endpoint}/admin/notifications/news/${id}`,
      data
    );
  }

  updateIan(id, data) {
    return this.h.put(
      `${environment.api_endpoint}/admin/notifications/ian/${id}`,
      data
    );
  }

  getDistributionModes() {
    return this.h.get(
      `${environment.api_endpoint}/admin/notifications/distribution_modes`
    );
  }

  addDistribution(data) {
    return this.h.post(
      `${environment.api_endpoint}/admin/notifications/distributions/`,
      data
    );
  }

  updateDistribution(id, data) {
    return this.h.put(
      `${environment.api_endpoint}/admin/notifications/distributions/${id}`,
      data
    );
  }

  deleteDistribution(id) {
    return this.h.delete(
      `${environment.api_endpoint}/admin/notifications/distributions/${id}`
    );
  }

  sendDistribution(id, speed) {
    return this.h.post(
      `${environment.api_endpoint}/admin/notifications/distributions/${id}/send/?speed=${speed}`,
      {}
    );
  }

  createMessage(details) {
    return of([]);
  }

  createDistribution(details) {
    return of([]);
  }

  // EXP

  getExpTags() {
    return this.h.get(`${environment.api_endpoint}/admin/experiences/tags`);
  }

  //POI

  getPois() {
    return this.h.get(`${environment.api_endpoint}/admin/experiences/pois/`);
  }

  getPoisMap(bbox: [number, number, number, number]) {
    return this.h.get(
      `${environment.api_endpoint}/admin/experiences/pois.geojson?bbox=${bbox}`
    );
  }

  getItinerariesMap(bbox: [number, number, number, number]) {
    return this.h.get(
      `${environment.api_endpoint}/admin/experiences/itineraries.geojson?bbox=${bbox}`
    );
  }

  getSinglePoi(id, full = false) {
    let ep = `${environment.api_endpoint}/admin/experiences/pois/${id}`;

    if (full) {
      let ep = `${environment.api_endpoint}/admin/experiences/pois/${id}/full`;
    }

    return this.h.get(ep);
  }

  addPoi(data) {
    return this.h.post(
      `${environment.api_endpoint}/admin/experiences/pois/`,
      data
    );
  }

  updatePoi(id, data) {
    return this.h.put(
      `${environment.api_endpoint}/admin/experiences/pois/${id}`,
      data
    );
  }

  //Experience

  getExperiences() {
    return this.h.get(
      `${environment.api_endpoint}/admin/experiences/experiences`
    );
  }

  getSingleExperience(id, full = false) {
    let item = `${environment.api_endpoint}/admin/experiences/experiences/${id}`;

    if (full) {
      let item = `${environment.api_endpoint}/admin/experiences/experiences/${id}/full`;
    }

    return this.h.get(item);
  }

  addExperience(data) {
    return this.h.post(
      `${environment.api_endpoint}/admin/experiences/experiences/`,
      data
    );
  }

  //Itinerary

  getItineraries() {
    return this.h.get(
      `${environment.api_endpoint}/admin/experiences/itineraries`
    );
  }

  getSingleItinerary(id, full = false) {
    let item = `${environment.api_endpoint}/admin/experiences/itineraries/${id}`;

    if (full) {
      let item = `${environment.api_endpoint}/admin/experiences/itineraries/${id}/full`;
    }

    return this.h.get(item);
  }

  addItinerary(data) {
    return this.h.post(
      `${environment.api_endpoint}/admin/experiences/itineraries/`,
      data
    );
  }

  updateItinerary(id, data) {
    return this.h.put(
      `${environment.api_endpoint}/admin/experiences/itineraries/${id}`,
      data
    );
  }

  /////////////////////////////////////////

  saveTranslation(mode, trans, lang) {
    return this.h.post(
      environment.api_endpoint + '/admin/strings/' + lang,
      { trans: trans },
      { params: { mode: mode } }
    );
  }

  createUser(user) {
    return this.h.post(
      environment.api_endpoint + '/auth/register?mode=admin',
      user
    );
  }

  deletePricing(id) {
    return this.h.delete(environment.api_endpoint + '/admin/pricing/' + id);
  }

  deleteMinuteUsageCost(pricing_id, id) {
    return this.h.delete(
      `${environment.api_endpoint}/admin/pricing/${pricing_id}/min/${id}`
    );
  }

  deactivate(id) {
    return this.h.post(
      environment.api_endpoint + '/pricing/' + id + '/deactivate',
      {}
    );
  }

  activate(id) {
    return this.h.post(
      environment.api_endpoint + '/pricing/' + id + '/activate',
      {}
    );
  }

  setAttribute(user, attribute, value){
    return this.h.post(
      `${environment.api_endpoint}/admin/users/${user}/att/${attribute}?value=${value}`,{}
    )
  }

  validatePhone(user){
    return this.h.post(
      `${environment.api_endpoint}/admin/users/${user}/validate/phone`, {}
    )
  }
}
