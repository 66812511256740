import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';



@NgModule({
  declarations: [
  
    ListComponent,
       DetailComponent
  ],
  imports: [
    SharedModule,
    CommonModule
  ], exports: [
    ListComponent,
    DetailComponent
  ]
})
export class TracksModule { }
