import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent implements OnInit {
  form_data = {
    subject: '',
    content: '',
  };

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddComponent>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  save() {
    const post_data = {
      ...this.form_data,
      created: new Date().toISOString(),
      template: '',
      content_html: this.form_data.content,
      meta: {},
    };

    if (!this.form_data.subject || !this.form_data.content) {
      alert('Please fill all the required fields');
      return;
    }

    this.e.addNews(post_data).subscribe((data) => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
