import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { EdwayService } from '../../edway.service';
import {saveAs} from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { AddComponent } from '../add/add.component';
import { AddRoleComponent } from 'src/app/users/add-role/add-role.component';
import { AmendComponent } from 'src/app/payments/amend/amend.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/download.service';
import { CacheService } from 'src/app/cache.service';

@Component({
  selector: 'edway-roles-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    minWidth: 20,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [
    { field: 'action', headerName: '',filter: false, pinned: 'left', width: 60,
    cellRenderer: AmendComponent,
    cellRendererParams: {
      buttons:[{
        icon: "delete",
        label: "delete",
        condition: () => true,
        clicked: (params: any) => {
          console.log(params)
          if(confirm('Delete role?')){
            this.e.deleteRole(params.username, '').subscribe(data=>{
              this.s.open('Role deleted', 'ok', {duration: 1000})
            })
          }
        }
      },{
        icon: "person",
        label: "impersonate",
        condition: () => true,
        clicked: (params: any) => {
          console.log(params)
          if(confirm('Impersonate user?')){
            console.log(params);
            const usr = localStorage.getItem('username');
            const cid = localStorage.getItem('client-id');
            localStorage.setItem('impersonator-username', usr);
            localStorage.setItem('impersonator-client-id', cid);
            localStorage.setItem('username', params.username);
            localStorage.setItem('client-id', cid+":"+params.fleet);
          }
        }
      },]
  }},
    { field: 'username', pinned: 'left'  },
    { field: 'fleet', pinned: 'left', valueFormatter: params=>{
      return this.c.get('fleets', params.value);
    } },
  ];
  pageEvent: PageEvent  ;

  roles;

  list:any ={};
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private d: MatDialog,
    private s: MatSnackBar,
    private tr: TranslateService,
    private downloadService: DownloadService,
    private c: CacheService
  ) { }
// MatPaginator Output

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    let ret = [];

    this.e.getRoleNames().subscribe(data=>{
      this.roles = data;
        
      for(let fn of this.roles){
        this.columnDefs.push(
          { field: "roles."+fn.key, 
          headerName: this.tr.instant(fn.label), 
          filter: false,
          width: 60,
          minWidth: 60,
          cellStyle: {
          },
          cellRenderer: AmendComponent,
          cellRendererParams: {
            buttons:[{
              icon: "close",
              condition: (params: any) =>{
                return !params.data.roles[fn.key];
              },
              clicked: (params: any) => {
                this.e.toggleRole(params.username, fn.key, "enabled").subscribe(data=>{});
                this.toPage();
              },
              style:{
                "color": "red",
              },
              tooltip: this.tr.instant(fn.label)
            }, {
              icon: "check",
              condition: (params: any) =>{
                return params.data.roles[fn.key];
              },
              clicked: (params: any) => {
                this.e.toggleRole(params.username, fn.key, "disabled").subscribe(data=>{});
                this.toPage();
              },
              style:{
                "color": "green"
              },
              tooltip: this.tr.instant(fn.label)
          }]
        }});

      }
      this.toPage();
    })




  }

  onRowClicked(ev){
  }


  toPage(page:number=1){
    this.e.getRoles().subscribe(data=>{
      console.log(data);
      this.list = data;
      this.show = true;
    });

  }

  addRole(){
    const dd = this.d.open(AddComponent);
    dd.afterClosed().subscribe(data =>{
      this.toPage();
    });
  }

  editRole(row){
    this.d.open(AddComponent, {data:row});
  }
  
  download(url){
    this.downloadService.download(url, {responseType: "blob", headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}})
    .subscribe((blob:any) => {
      saveAs(blob, 'rentals.xlsx');
    });
  }
}
