<mat-toolbar>
  <h1>Aree</h1>
  <button mat-icon-button (click)="refreshCached()" matTooltip="Rigenera"><mat-icon>refresh</mat-icon></button>
  <span class="spacer"></span>
  <button mat-icon-button matTooltip="{{'fleets.area.add.area.button'|translate}}" (click)="addArea()">
    <mat-icon>map</mat-icon>
  </button>
  <button mat-icon-button matTooltip="{{'fleets.area.add.marker.button'|translate}}" (click)="addMarker()">
    <mat-icon>location_on</mat-icon>
  </button>
  <button disabled mat-icon-button matTooltip="{{'fleets.area.add.beacon.button'|translate}}" (click)="addBeacon()">
    <mat-icon>wifi</mat-icon>
  </button>

</mat-toolbar>
<mat-drawer-container class="example-container" autosize>
  <div class="row"></div>
  <mat-drawer-content>
    <div id="map"></div>
  </mat-drawer-content>



  <mat-drawer #drawer class="example-sidenav" position="end" mode="side" opened>
    <mat-list class="fill"  *ngIf="selected===null">
      <mat-list-item (click)="selected = area; atype='area'; show(area);" *ngFor="let area of fleet.areas; let index = index" [label]="area.name">
        <mat-icon>map</mat-icon> {{area.name}}
      </mat-list-item>
    </mat-list>

    <div *ngIf="selected !== null">
      <mat-toolbar>
        <button mat-icon-button (click)="selected = null">
          <mat-icon>chevron_left</mat-icon>
        </button>
        
        {{selected.name}}
        <span class="spacer"></span>
        <button mat-icon-button (click)="delete(fleet, selected.id)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button *ngIf="mode==='viewing'" (click)="edit(selected)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button *ngIf="mode==='editing'" (click)="save(selected)">
          <mat-icon>save</mat-icon>
        </button>
        <span style="width: 25px"></span>
        <button mat-icon-button (click)="addSetting(selected)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>
      <mat-divider></mat-divider>
      <table>
        <tr *ngFor="let kv of selected.settings">
          <td><button mat-icon-button (click)="deleteAreaSetting(fleet.id, selected.id, kv.key)"><i
                class="fa-solid fa-trash"></i></button></td>
          <td class="title">{{kv.key|translate}}</td>
          <td *ngIf="['True', 'False'].indexOf(kv.value)>=0">{{'kv.'+kv.key+'.'+kv.value|translate}}</td>
          <td *ngIf="['True', 'False'].indexOf(kv.value)<0">{{kv.value}}</td>
          <td *ngIf="a.canDo('dev')"><span title="{{kv|json}}">🛠</span></td>
        </tr>
      </table>
    </div>
  </mat-drawer>
</mat-drawer-container>
