<mat-toolbar>
    <h1 translate>transactions.title</h1>
    <button mat-icon-button title="{{'ops.refresh'|translate}}" (click)="updatePayments()"><mat-icon>refresh</mat-icon></button>
    <span class="spacer"></span>
    <div class="stats" *ngIf="stats?.payments">
        <span class="label">today</span>
        <span class="value">{{stats.payments?.today}}</span>
        <span class="label">month</span>
        <span class="value">{{stats.payments?.month}}</span>
        <span class="label">all</span>
        <span class="value">{{stats.payments?.all}}</span>
    </div>
    <button mat-icon-button title="{{'ops.dl'|translate}}" (click)="download('https://api.hoponmobility.com/admin/data/transactions.xlsx')"><mat-icon>download</mat-icon></button>
</mat-toolbar>
<mat-paginator length=15000
               pageSize="100"
               (page)="toPage($event)"
               aria-label="Select page">
</mat-paginator><ag-grid-angular *ngIf="show"
    class="fullpage ag-theme-alpine"
    [rowData]="list"
    [headerHeight]="36"
    [rowHeight]="40"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [getRowStyle]="getRowStyle"
    (rowClicked)="onRowClicked($event)"
    >
</ag-grid-angular>