import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BleService {
  private ble;

  constructor() {
    this.ble = (navigator as any).bluetooth;
    if (!this.ble) {
      throw new Error('Your browser does not support Smart Bluetooth. See http://caniuse.com/#search=Bluetooth for more details.');
    }
  }

  requestDevice(options: any): Promise<any> {
    return this.ble.requestDevice(options);
  }
}
