<mat-progress-bar
  *ngIf="s.showLoading | async"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    mode="side"
    #side
    opened
    class="sidenav"
    [ngClass]="{ desktop: isDesktop, mobile: isMobile, wide: large_side, narrow: !large_side }"
  >
    <div class="operator-title">
      <span [routerLink]="['/']">{{ settings?.name }}</span>
      <mat-menu #clientsMenu>
        <menu
          mat-menu-item
          (click)="setClient(c.ident)"
          *ngFor="let c of clients"
        >
          {{ c.name }}
        </menu>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="clientsMenu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <button mat-icon-button class="hide-mobile" (click)="toggle_side()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>

    <!-- DESKTOP -->
    <mat-nav-list *ngIf="canDo('discovery')">
      <h3 mat-subheader>{{'dashboard.areas.api'|translate}}</h3>
      
      <mat-list-item>
        <mat-icon 
          mat-list-icon *ngIf="canDo('discovery')"
          [routerLink]="['/', 'webhooks']" matTooltip="{{'dashboard.webhooks'|translate}}"
          >cloud</mat-icon>
        <a 
          [routerLink]="['/', 'webhooks']" matTooltip="{{'dashboard.webhooks'|translate}}" 
          mat-line *ngIf="canDo('discovery')">
          <span translate>dashboard.webhooks</span>
        </a>
        <a href="https://docs.hoponmobility.com" target="_blank"  *ngIf="canDo('discovery')"
          matTooltip="{{ 'dashboard.apidocs' | translate }}">
        <mat-icon>summarize</mat-icon
        ></a>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon *ngIf="canDo('discovery')"
          [routerLink]="['/','connectivity']" matTooltip="{{'dashboard.connectivity'|translate}}">
          sim_card
        </mat-icon>
        
        <a
          [routerLink]="['/', 'connectivity']" matTooltip="{{'dashboard.connectivity'|translate}}" 
          mat-line *ngIf="canDo('discovery')">
          <span translate>dashboard.connectivity</span>
        </a> 
      </mat-list-item>

      <mat-divider></mat-divider>
      <h3 mat-subheader  *ngIf="canDo('discovery')" >{{'dashboard.areas.discovery'|translate}}</h3>
      <mat-list-item >
        <mat-icon [routerLink]="['/', 'discovery']"
          mat-list-icon  *ngIf="canDo('discovery')"
          matTooltip="{{ 'dashboard.iot' | translate }}"
          >memory</mat-icon
        >
        <a
          mat-line
          matTooltip="{{ 'dashboard.iot' | translate }}"
          *ngIf="canDo('discovery')"
          [routerLink]="['/', 'discovery']">
          <span translate>dashboard.iot</span>
        </a>
        <mat-icon  *ngIf="canDo('discovery')"
          matTooltip="{{ 'dashboard.map' | translate }}"
          [routerLink]="['/', 'map', {'mode': 'discovery'}]"
          >map</mat-icon
        >
      </mat-list-item>

      
      <mat-list-item>
        
        
        <mat-icon mat-list-icon [routerLink]="['/', 'fleets', {mode:'geofences'}]"
        matTooltip="{{ 'dashboard.geofence' | translate }}"  *ngIf="canDo('discovery')"
        >fence</mat-icon>
        <a mat-line *ngIf="canDo('discovery')" [routerLink]="['/', 'fleets', {mode:'geofences'}]"
        matTooltip="{{ 'dashboard.geofence' | translate }}"
      >
          <span translate>dashboard.geofence</span>
        </a>
      </mat-list-item>

      

    </mat-nav-list>

    
    <mat-nav-list class="hidden-mobile"  *ngIf="canDo('admin')">
      <mat-divider></mat-divider>
      <h3 mat-subheader  translate>dashboard.areas.operator</h3>

      <mat-list-item >
        <mat-icon [routerLink]="['/', 'vehicles']"
          mat-list-icon
          matTooltip="{{ 'dashboard.vehicles' | translate }}"
          >electric_scooter</mat-icon
        >
        <a
          mat-line
          matTooltip="{{ 'dashboard.vehicles' | translate }}"
          *ngIf="canDo('list_vehicles')"
          [routerLink]="['/', 'vehicles']"
        >
          <span translate>dashboard.vehicles</span>
        </a>
        <mat-icon
          matTooltip="{{ 'dashboard.map' | translate }}"
          [routerLink]="['/', 'map']"
          >map</mat-icon
        >
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'fleets', {mode:'fleets'}]"
        matTooltip="{{ 'dashboard.fleets' | translate }}"
      >
        <mat-icon mat-list-icon>grain</mat-icon>
        <a mat-line *ngIf="canDo('list_fleets')">
          <span translate>dashboard.fleets</span>
        </a>
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon matTooltip="{{ 'dashboard.users' | translate }}"
          >group</mat-icon
        >
        <a
          mat-line
          matTooltip="{{ 'dashboard.users' | translate }}"
          *ngIf="canDo('list_users')"
          [routerLink]="['/', 'users']"
        >
          <span translate>dashboard.users</span>
        </a>
        <mat-icon
          [matBadge]="docs_todo"
          [matBadgeHidden]="docs_todo < 1"
          matBadgeColor="accent"
          matTooltip="{{ 'dashboard.documents' | translate }}"
          *ngIf="canDo('accept_docs')"
          [routerLink]="['/', 'docs']"
          >description</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon mat-list-icon [routerLink]="['/', 'rentals']"
        matTooltip="{{ 'dashboard.rentals' | translate }}"
        >car_rental</mat-icon>
        <a mat-line 
        matTooltip="{{ 'dashboard.rentals' | translate }}"
        *ngIf="canDo('list_rentals')"
        [routerLink]="['/', 'rentals']">
          <span translate>dashboard.rentals</span>
        </a>
        <!--<mat-icon [matBadge]="strange_rentals" [matBadgeHidden]="strange_rentals < 1" matBadgeColor="accent"
          *ngIf="canDo('accept_docs')" [routerLink]="['/', 'rentals', 'problems']">taxi_alert</mat-icon>-->
          <mat-icon
          [matBadge]="running_rentals"
          [matBadgeHidden]="running_rentals < 1"
          matBadgeColor="accent"
          matTooltip="{{ 'dashboard.running_rentals' | translate }}"
          *ngIf="canDo('running_rentals')"
          [routerLink]="['/', 'rentals', {'filter.status':'running'}]"
          >run_circle</mat-icon
        >
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'spackages']"
        matTooltip="{{ 'dashboard.sold_packages' | translate }}"
      >
        <mat-icon mat-list-icon>inventory</mat-icon>
        <a mat-line *ngIf="canDo('list_packages')">
          <span translate>dashboard.sold_packages</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'transactions']"
        matTooltip="{{ 'dashboard.transactions' | translate }}"
      >
        <mat-icon mat-list-icon>local_atm</mat-icon>
        <a mat-line *ngIf="canDo('list_transactions')">
          <span translate>dashboard.transactions</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'activated_coupons']"
        matTooltip="{{ 'dashboard.vouchers_activated' | translate }}"
      >
        <mat-icon mat-list-icon>payments</mat-icon>
        <a mat-line *ngIf="canDo('list_activated_vouchers')">
          <span translate>dashboard.vouchers_activated</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'news']"
        matTooltip="{{ 'dashboard.news' | translate }}"
      >
        <mat-icon mat-list-icon>notifications</mat-icon>
        <a mat-line *ngIf="canDo('list_news')">
          <span translate>dashboard.news</span>
        </a>
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          [routerLink]="['/', 'issues']"
          matTooltip="{{ 'dashboard.issues' | translate }}"
          mat-list-icon
          >bug_report</mat-icon
        >
        <a
          mat-line
          [routerLink]="['/', 'issues']"
          matTooltip="{{ 'dashboard.issues' | translate }}"
          *ngIf="canDo('list_issues')"
        >
          <span translate>dashboard.issues</span>
        </a>
        <mat-icon [matBadge]="users_botp" [matBadgeHidden]="users_botp < 1" matBadgeColor="accent" matTooltip="{{'dashboard.blockeds'|translate}}"
          *ngIf="canDo('unblock_phones')" [routerLink]="['/', 'users','botp']">phone_disabled</mat-icon>
      </mat-list-item>
    </mat-nav-list>
    <mat-nav-list class="hidden-mobile"  *ngIf="canDo('admin')">
      <mat-divider></mat-divider>
      <!--
      <h3 mat-subheader translate>dashboard.areas.statistics</h3>

      <mat-list-item>
        <mat-icon mat-list-icon>analytics</mat-icon>
        <a mat-line *ngIf="canDo('view_stats')" [routerLink]="['/', 'statistics', 'general']">
          <span translate>dashboard.stats_general</span>
        </a>
      </mat-list-item>

      <mat-divider></mat-divider>
    -->

      <div mat-subheader translate>dashboard.areas.manager</div>

      <mat-list-item
        [routerLink]="['/', 'pricing']"
        matTooltip="{{ 'dashboard.pricing' | translate }}"
      >
        <mat-icon mat-list-icon>request_quote</mat-icon>
        <a mat-line *ngIf="canDo('edit_pricing')">
          <span translate>dashboard.pricing</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'packages']"
        matTooltip="{{ 'dashboard.packages' | translate }}"
      >
        <mat-icon mat-list-icon>store</mat-icon>
        <a mat-line *ngIf="canDo('edit_packages')">
          <span translate>dashboard.packages</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'models']"
        matTooltip="{{ 'dashboard.models' | translate }}"
      >
        <mat-icon mat-list-icon>garage</mat-icon>
        <a mat-line *ngIf="canDo('edit_models')">
          <span translate>dashboard.models</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'screens']"
        matTooltip="{{ 'dashboard.screens' | translate }}"
      >
        <mat-icon mat-list-icon>cast</mat-icon>
        <a mat-line *ngIf="canDo('edit_infoboxes')">
          <span translate>dashboard.screens</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'experiences']"
        matTooltip="{{ 'dashboard.screens' | translate }}"
        [disabled] = "false"
      >
        <mat-icon mat-list-icon>tips_and_updates</mat-icon>
        <a mat-line *ngIf="canDo('edit_experiences')">
          <span translate>dashboard.experiences</span>
        </a>
      </mat-list-item>

    </mat-nav-list>
    <mat-nav-list class="hidden-mobile"  *ngIf="canDo('admin')">
      <mat-divider></mat-divider>

      <h3 mat-subheader translate>dashboard.areas.account</h3>

      <mat-list-item
        [routerLink]="['/', 'roles']"
        matTooltip="{{ 'dashboard.roles' | translate }}"
      >
        <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
        <a mat-line *ngIf="canDo('edit_roles')">
          <span translate>dashboard.roles</span>
        </a>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/', 'platform-status']"
        matTooltip="{{ 'dashboard.info' | translate }}"
      >
        <mat-icon mat-list-icon>info</mat-icon>
        <a mat-line>
          <span translate>dashboard.info</span>
        </a>
      </mat-list-item>
      
      <!--
      <mat-list-item>
        <mat-icon mat-list-icon>translate</mat-icon>
        <a mat-line *ngIf="canDo('edit_translations')" [routerLink]="['/', 'translations']">
          <span translate>dashboard.translations</span>
        </a>
      </mat-list-item>
    -->
      <mat-list-item
        [routerLink]="['/', 'settings']"
        matTooltip="{{ 'dashboard.settings' | translate }}"
      >
        <mat-icon mat-list-icon>settings</mat-icon>
        <a mat-line *ngIf="canDo('edit_settings')">
          <span translate>dashboard.settings</span>
        </a>
      </mat-list-item>
      
    </mat-nav-list>
    <mat-nav-list class="hidden-mobile"  *ngIf="canDo('discovery')">
      <mat-divider *ngIf="canDo('discovery')"></mat-divider>
      <h3 mat-subheader translate  *ngIf="canDo('discovery')">dashboard.areas.billing</h3>

      <mat-list-item [routerLink]="['/', 'billing']" matTooltip="{{'dashboard.invoices'|translate}}">
        <mat-icon mat-list-icon>receipt_long</mat-icon>
        <a mat-line *ngIf="canDo('discovery')">
          <span translate>dashboard.invoices</span>
        </a>
      </mat-list-item>
    </mat-nav-list>
    <mat-nav-list class="hidden-mobile"  *ngIf="canDo('superuser')">
      <mat-divider></mat-divider>
      <h3 mat-subheader translate>dashboard.areas.superuser</h3>

      <mat-list-item [routerLink]="['/', 'coupons']" matTooltip="{{'dashboard.vouchers'|translate}}">
        <mat-icon mat-list-icon>payments</mat-icon>
        <a mat-line *ngIf="canDo('is_dev')">
          <span translate>dashboard.vouchers</span>
        </a>
      </mat-list-item>
    </mat-nav-list>

    <!-- MOBILE -->
    <mat-nav-list class="hidden-desktop">
      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('list_vehicles')"
          [routerLink]="['/', 'vehicles']"
        >
          electric_scooter</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('map_vehicles')"
          [routerLink]="['/', 'map']"
          >map</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('list_users')"
          [routerLink]="['/', 'users']"
          >group</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          [matBadge]="docs_todo"
          [matBadgeHidden]="docs_todo < 1"
          matBadgeColor="accent"
          *ngIf="canDo('accept_docs')"
          [routerLink]="['/', 'docs']"
          >description</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('list_rentals')"
          [routerLink]="['/', 'rentals']"
          >car_rental</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('edit_packages')"
          [routerLink]="['/', 'spackages']"
          >inventory</mat-icon
        >
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('list_transactions')"
          [routerLink]="['/', 'transactions']"
          >local_atm
        </mat-icon>
      </mat-list-item>

      <mat-list-item>
        <mat-icon
          mat-list-icon
          *ngIf="canDo('list_issues')"
          [routerLink]="['/', 'issues']"
          >bug_report</mat-icon
        >
      </mat-list-item>

      <!--<mat-list-item>
        <mat-icon [matBadge]="users_blocked" [matBadgeHidden]="users_blocked < 1" matBadgeColor="accent"
          *ngIf="canDo('unblock_users')" [routerLink]="['/', 'users/blocked']">person_off</mat-icon>
      </mat-list-item>-->
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content [ngClass]="{ desktop: isDesktop, mobile: isMobile }">
    <mat-toolbar class="navbar">
      <div class="search"></div>

      <div class="actions-container">
        <mat-form-field class="lang-container">
          <mat-select
            [(value)]="selectedLanguage"
            (selectionChange)="changeLanguage($event.value)"
          >
            <mat-option *ngFor="let lang of languages" [value]="lang">
              {{ lang.toUpperCase() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="vertical-divider navbar-divider"></div>
      <div class="files-container">
        <button mat-icon-button [routerLink]="['/', 'files']">
          <mat-icon
            [matBadge]="dls_todo"
            [matBadgeHidden]="dls_todo < 1"
            matBadgeColor="accent"
            >cloud</mat-icon
          >
        </button>
      </div>

      <div class="vertical-divider navbar-divider"></div>
      <div class="user-container">
        <div class="text">
          <span class="fullname hide-mobile">{{ profile?.username }}</span>
          <!--<span class="email hide-mobile"></span>-->
        </div>
        <img ngxGravatar [email]="profile?.username" size="42" />
        <button mat-icon-button [matMenuTriggerFor]="userMenu">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #userMenu>
          <menu
            mat-menu-item
            translate
            *ngIf="canDo('edit_profile')"
            [routerLink]="['/', 'profile']"
          >
            dashboard.profile
          </menu>
          <menu mat-menu-item translate [routerLink]="['/', 'logout']">
            dashboard.logout
          </menu>
        </mat-menu>
      </div>
    </mat-toolbar>

    <mat-divider></mat-divider>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<button id="help" (click)="toggleHelp()" mat-fab aria-label="help">
  <mat-icon>help</mat-icon>
</button>
