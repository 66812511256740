import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ListComponent, DetailComponent],
  imports: [CommonModule, SharedModule, AgGridModule, TranslateModule],
  exports: [ListComponent, DetailComponent],
})
export class IssuesModule {}
