import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

roles = [
  "map_vehicles"
]

  data = {user: null, roles: [], fleet: null};
  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddComponent>,

  ) { }

  fleets: any;

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.e.getFleets().subscribe(data=>{
      this.fleets = data;
    })
  } 

  addRole(data) {
    this.e.addManager(data).subscribe(data=>{
      this.onNoClick();
    })
  }

}
